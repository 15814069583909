/* eslint-disable no-console */
import { actions as enrollmentWizardActions } from '../enrollment-wizard/slice'
import { actions as userActions } from '../user/slice'

import { actions } from '../error-slice'

/**
 * This middleware is a generic error handler to show a snackbar notification if an error occurs
 * @param store - redux store
 * @param next - next middleware in list. if none exists, it dispatches the next store update/action
 * @param action - action that was dispatched
 */
export const errorMiddleware =
	(store) =>
	(next: CallableFunction) =>
	(action): void => {
		const { saveEnrollmentError, savePetError } = enrollmentWizardActions
		const { updateUserInfoError } = userActions
		// As thunk types are added, add their types here
		const errorList = [saveEnrollmentError.type, savePetError.type, updateUserInfoError.type]

		const defaultEnrollmentError = `An error occurred while processing your selection, please try again.
	If this error continues, please contact Customer Care.`
		let errorMessage = 'An error occurred making your request.'
		let shouldAutoHide = true

		const getEnrollmentErrorMessage = () => {
			if (Array.isArray(action.payload) && typeof action.payload?.[0] === 'string') {
				return action.payload?.join(', ')
			}

			if (typeof action?.payload === 'string') {
				return action?.payload
			}

			return defaultEnrollmentError
		}

		if (action.type === saveEnrollmentError.type) {
			shouldAutoHide = false
			errorMessage = getEnrollmentErrorMessage()
		}

		if (errorList.includes(action.type))
			store.dispatch({
				payload: { errorMessage, shouldAutoHide, showError: true },
				type: actions.setShowErrorMessage,
			})

		next(action)
	}
