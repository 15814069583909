import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/benefit-elections'
import { CorestreamProductsService } from 'api/benefit-elections/services/corestream-products-service'
import { CorestreamProduct } from 'api/benefit-elections/types/corestream-product'

export const useGetCorestreamProducts = (enabled = true) => {
	return useQuery({
		enabled: enabled,
		queryFn: () => CorestreamProductsService.getCorestreamProducts(),
		queryKey: queryKeys.corestreamProducts.all(),
		select: (data): CorestreamProduct[] => {
			return data.map((item) => transformCorestreamProduct(item))
		},
	})
}

export const transformCorestreamProduct = (item: CorestreamProduct): CorestreamProduct => {
	return {
		...item,
		// ProductLabel is nullable, so if it's null, use the product name for display
		productLabel: item.productLabel ?? item.productName,
	}
}
