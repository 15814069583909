import { useEffect, useRef } from 'react'

export const useEffectOnceWithRef = (condition: boolean, callback: () => any) => {
	const init = useRef(false)

	useEffect(() => {
		if (!condition || init.current) return

		init.current = true
		callback()
	}, [callback, condition])
}
