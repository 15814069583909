import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/enrollments/keys'
import { BenefitElectionsService } from 'api/enrollments/services'
import { UserNewHireInfo } from 'api/enrollments/types'
import { NewHireService } from 'api/tenants/services/new-hire-service'
import { useFlags } from 'launchdarkly-react-client-sdk'

const getCorestreamUserFakeResponse = (): UserNewHireInfo => {
	const response = {
		isNewHire: false,
		newHireQualificationEndDate: '2000-01-01T00:00:00',
		newHireQualificationStartDate: '2000-01-01T00:00:00',
	}

	return response
}

const getQueryFunction = (isEnabled: boolean, und3484: boolean, und14236_use_tenants_new_hire_eligibility: boolean) => {
	if (!isEnabled) {
		return getCorestreamUserFakeResponse
	}
	/*
	 und3484: benefit elections flag

	 If und3484 is off and und14236_use_tenants_new_hire_eligibility is on the api
	 will use ppds data to determine eligibility
	 Conversely, if und3484 is on and und14236_use_tenants_new_hire_eligibility
	 is off the api will use constituent data instead
	*/
	if (und3484 || und14236_use_tenants_new_hire_eligibility) {
		return NewHireService.UserNewHireInfo
	}

	return BenefitElectionsService.userNewHireInfo
}

export const useNewHire = (isEnabled: boolean) => {
	const { und3484, und14236_use_tenants_new_hire_eligibility } = useFlags()

	return useQuery<UserNewHireInfo>({
		queryFn: getQueryFunction(isEnabled, und3484, und14236_use_tenants_new_hire_eligibility),
		queryKey: queryKeys.benefitElections.userNewHireInfo(),
	})
}
