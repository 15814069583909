import { AdditionalDriverDTO, DriverBaseDTO } from 'pages/autoquotes/model/DriverTypes'
import { AuthenticatedEmployee, EnumItemDTO } from 'pages/autoquotes/model/CommonTypes'
import {
	QuotingSessionDTO,
	QuotingSessionInfoDTO,
	ResponseInfoDTO,
	SubmitResultDTO,
	ValidationResultDTO,
} from 'pages/autoquotes/model/QuoteSessionTypes'
import { VehicleDTO, VinInfoDTO } from 'pages/autoquotes/model/VehicleTypes'

import { AxiosRequestConfig } from 'axios'
import { FieldBaseDTO } from 'pages/autoquotes/model/FieldTypes'
import { IncidentBaseDTO } from 'pages/autoquotes/model/IncidentTypes'
import UrlBuilder from './util/url-builder'
import { axiosInstance as axios } from 'config/axios'

const autoquotingSubdomain: string = 'autoquoting'
const urlBuilder: UrlBuilder = new UrlBuilder(autoquotingSubdomain)
const defaultConfig: AxiosRequestConfig = { timeout: 1 * 60 * 1000 }

const apiHost = (): string => {
	const url = urlBuilder.url()

	return url
}

export class AutoquotesService {
	static enumCategories: Record<string, EnumItemDTO[]> = {}

	static async getMe(): Promise<AuthenticatedEmployee> {
		const endpoint: string = `${apiHost()}/api/Common/me`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as AuthenticatedEmployee

			return result
		})
	}
	static async isQuotingAvailable(zip?: string): Promise<boolean> {
		const endpoint: string = `${apiHost()}/api/Common/${zip ? `isavailable/${zip}` : 'isappavailable'}`

		return axios.get(endpoint, defaultConfig).then((res) => {
			if (res) {
				const result = res as unknown as boolean

				return result
			} else return false
		})
	}
	static async hasPreviousData(): Promise<boolean> {
		const endpoint: string = `${apiHost()}/api/Common/haspreviousdata`

		return axios.get(endpoint, defaultConfig).then((res) => {
			if (res) {
				const result = res as unknown as boolean

				return result
			} else return false
		})
	}

	static async getEnumItems(category: string): Promise<EnumItemDTO[]> {
		const endpoint: string = `${apiHost()}/api/Enums/${category}`

		if (AutoquotesService.enumCategories[category]) return AutoquotesService.enumCategories[category]

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as EnumItemDTO[]
			AutoquotesService.enumCategories[category] = result

			return result
		})
	}
	static async createQuote(zip?: string): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Quote/${zip ? `newbyzip/${zip}` : 'new'}`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async getQuote(id: string): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Quote/${id}`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async getQuoteStatus(id: string): Promise<string> {
		const endpoint: string = `${apiHost()}/api/Quote/status/${id}`

		return axios.get(endpoint, defaultConfig).then((res: any) => {
			return res.status
		})
	}

	static async checkFields(quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Field/check`

		return axios.post(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async updateField(field: FieldBaseDTO): Promise<FieldBaseDTO> {
		const endpoint: string = `${apiHost()}/api/Field`

		return axios.patch(endpoint, field, defaultConfig).then((res) => {
			const result = res as unknown as FieldBaseDTO

			return result
		})
	}

	static async createAdditionalDriver(quote: QuotingSessionDTO): Promise<AdditionalDriverDTO> {
		const endpoint: string = `${apiHost()}/api/Driver/new`

		return axios.post(endpoint, quote, defaultConfig).then((res) => {
			if (res['fields'] && res['fields']['$values']) res['fields'] = res['fields']['$values']

			return res as unknown as AdditionalDriverDTO
		})
	}
	static async updateDriver(driverId: string, quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Driver/${driverId}`

		return axios.patch(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async updateCoverage(quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Coverage`

		return axios.patch(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async deleteDriver(driverId: string, quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Driver/${driverId}`

		return axios.delete(endpoint, { data: quote, timeout: defaultConfig.timeout }).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}

	static async createVehicle(quote: QuotingSessionDTO): Promise<VehicleDTO> {
		const endpoint: string = `${apiHost()}/api/Vehicle/new`

		return axios.post(endpoint, quote, defaultConfig).then((res) => {
			if (res['fields'] && res['fields']['$values']) res['fields'] = res['fields']['$values']
			if (res['vehicleUsages'] && res['vehicleUsages']['$values'])
				res['vehicleUsages'] = res['vehicleUsages']['$values']
			const result = res as unknown as VehicleDTO

			return result
		})
	}
	static async updateVehicle(vehicleId: string, quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Vehicle/${vehicleId}`

		return axios.patch<QuotingSessionDTO>(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}
	static async deleteVehicle(vehicleId: string, quote: QuotingSessionDTO): Promise<QuotingSessionDTO> {
		const endpoint: string = `${apiHost()}/api/Vehicle/${vehicleId}`

		return axios.delete(endpoint, { data: quote, timeout: defaultConfig.timeout }).then((res) => {
			const result = res as unknown as QuotingSessionDTO

			return result
		})
	}

	static async createIncident(
		driverId: string,
		incidentType: string,
		quote: QuotingSessionDTO,
	): Promise<IncidentBaseDTO> {
		const endpoint: string = `${apiHost()}/api/Incident/new/${driverId}/${incidentType}`

		return axios.post(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as IncidentBaseDTO

			return result
		})
	}
	static async updateIncident(
		driverId: string,
		incidentId: string,
		quote: QuotingSessionDTO,
	): Promise<IncidentBaseDTO> {
		const endpoint: string = `${apiHost()}/api/Incident/${driverId}/${incidentId}`

		return axios.patch(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as IncidentBaseDTO

			return result
		})
	}
	static async updateIncidents(driverId: string, quote: QuotingSessionDTO): Promise<IncidentBaseDTO[]> {
		const endpoint: string = `${apiHost()}/api/Incident/${driverId}`

		return axios.patch(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as IncidentBaseDTO[]

			return result
		})
	}
	static async deleteIncident(driverId: string, incidentId: string, quote: QuotingSessionDTO): Promise<DriverBaseDTO> {
		const endpoint: string = `${apiHost()}/api/Incident/${driverId}/${incidentId}`

		return axios.delete(endpoint, { data: quote, timeout: defaultConfig.timeout }).then((res) => {
			const result = res as unknown as DriverBaseDTO

			return result
		})
	}
	static async getVinInfo(vin: string): Promise<VinInfoDTO> {
		const endpoint: string = `${apiHost()}/api/VinInfo/${vin}`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as VinInfoDTO

			return result
		})
	}

	static async validateQuote(quote: QuotingSessionDTO): Promise<ValidationResultDTO[]> {
		const endpoint: string = `${apiHost()}/api/Quote/validate`

		return axios.post<ValidationResultDTO[]>(endpoint, quote, defaultConfig).then((res) => {
			const result = res as unknown as ValidationResultDTO[]

			return result
		})
	}
	static async submitQuote(quote: QuotingSessionDTO): Promise<SubmitResultDTO> {
		const endpoint: string = `${apiHost()}/api/Quote/submitasync`

		return axios.post(endpoint, quote, { timeout: 20 * 60 * 1000 }).then((res) => {
			const result = res as unknown as SubmitResultDTO

			return result
		})
	}
	static async getQuoteResponseInfos(id: string): Promise<ResponseInfoDTO[]> {
		const endpoint: string = `${apiHost()}/api/Quote/responses/${id}`

		return axios.get(endpoint, defaultConfig).then((res: any) => {
			const result = res as ResponseInfoDTO[]

			return result
		})
	}
	static async getQuoteResponseInfosBySetId(id: string, setId: string): Promise<ResponseInfoDTO[]> {
		const endpoint: string = `${apiHost()}/api/Quote/responsesbyset/${id}/${setId}`

		return axios.get(endpoint, defaultConfig).then((res: any) => {
			const result = res as ResponseInfoDTO[]

			return result
		})
	}
	static async getMyResponseInfos(): Promise<ResponseInfoDTO[]> {
		const endpoint: string = `${apiHost()}/api/Quote/myquotes`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as ResponseInfoDTO[]

			return result
		})
	}
	static async getMySessionInfos(): Promise<QuotingSessionInfoDTO[]> {
		const endpoint: string = `${apiHost()}/api/Quote/mysessions`

		return axios.get(endpoint, defaultConfig).then((res) => {
			const result = res as unknown as QuotingSessionInfoDTO[]

			return result
		})
	}
	static isAutoCallbackProperty(entityType: string, propertyName): boolean {
		const autoCallbackFields = {
			Address: {
				state: true,
			},
			Coverage: {
				currentInsuranceStatus: true,
				hadAutoInsuranceForPast6Months: true,
			},
			Driver: {
				ageFistLicence: true,
				dateOfBirth: true,
				driverLicenseState: true,
				education: true,
				maritalStatus: true,
				occupation: true,
				relationshipToPrimary: true,
			},
			Vehicle: {
				milesDrivenAnnually: true,
				primaryUse: true,
			},
		}
		if (autoCallbackFields[entityType]) return Boolean(autoCallbackFields[entityType][propertyName])

		return false
	}
}
