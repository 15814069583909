import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'
import { RecentElection } from 'types/recent-election'
import { API_SUBDOMAIN, endpoints } from '../constants'
import {
	Dependent,
	PetDependentResponseDTO,
	SubmitBenefitElectionRequestDTO,
	SubmitBenefitElectionResponseDTO,
} from '../types'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class BenefitElectionsService {
	static clearEnrollments(): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint(endpoints.BenefitElections.clearEnrollments()).url())
	}

	static GetDependents(): Promise<Dependent[]> {
		return axios.get(urlBuilder.setEndpoint(endpoints.BenefitElections.dependents()).url())
	}

	static GetPets(): Promise<PetDependentResponseDTO> {
		return axios.get(urlBuilder.setEndpoint(endpoints.BenefitElections.pets()).url())
	}

	static GetRecentElections(): Promise<RecentElection[]> {
		return axios.get(urlBuilder.setEndpoint(endpoints.BenefitElections.getRecentElections()).url())
	}

	static submitBenefitElections(props: SubmitBenefitElectionRequestDTO): Promise<SubmitBenefitElectionResponseDTO> {
		const url = urlBuilder.setEndpoint(endpoints.BenefitElections.submitBenefitElections()).url()

		return axios.post(url, props)
	}
}
