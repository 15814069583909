import { BenefitPlanV2 } from 'store/benefit-plans/types'

/**
 * Sort benefit plan group first if it contains the active plan
 */
export const sortBenefitPlanGroup =
	(activePlan: BenefitPlanV2 | undefined) => (planA: BenefitPlanV2, planB: BenefitPlanV2) => {
		if (!activePlan) return 0

		// Prioritize any parents of active plan first
		if (isPrimaryOfActivePlan(activePlan)(planA) && !isPrimaryOfActivePlan(activePlan)(planB)) return -1
		if (isPrimaryOfActivePlan(activePlan)(planB) && !isPrimaryOfActivePlan(activePlan)(planA)) return 1

		// Then the active plan and its siblings
		if (isActivePlanOrSiblingOfActivePlan(activePlan)(planA) && !isActivePlanOrSiblingOfActivePlan(activePlan)(planB))
			return -1
		if (isActivePlanOrSiblingOfActivePlan(activePlan)(planB) && !isActivePlanOrSiblingOfActivePlan(activePlan)(planA))
			return 1

		// Place children directly after active plan
		if (isAccessoryOfActivePlan(activePlan)(planA) && !isAccessoryOfActivePlan(activePlan)(planB)) return -1
		if (isAccessoryOfActivePlan(activePlan)(planB) && !isAccessoryOfActivePlan(activePlan)(planA)) return 1

		return 0
	}

const isPrimaryOfActivePlan = (activePlan: BenefitPlanV2) => (benefitPlan: BenefitPlanV2) => {
	return benefitPlan.benefitPlanId === activePlan.benefitPlanGroupPrimaryId
}

const isActivePlanOrSiblingOfActivePlan = (activePlan: BenefitPlanV2) => (benefitPlan: BenefitPlanV2) => {
	return (
		benefitPlan.benefitPlanId === activePlan.benefitPlanId ||
		(activePlan.benefitPlanGroupPrimaryId &&
			benefitPlan.benefitPlanGroupPrimaryId === activePlan.benefitPlanGroupPrimaryId)
	)
}

const isAccessoryOfActivePlan = (activePlan: BenefitPlanV2) => (benefitPlan: BenefitPlanV2) => {
	return benefitPlan.benefitPlanGroupPrimaryId === activePlan.benefitPlanId
}

/**
 * Sort accessory plans by benefitPlanRank directly after their parent,
 * maintaining existing order for non-accessory plans
 */
export const rankAccessoryPlans = (benefitPlans: BenefitPlanV2[]) => {
	const accessoryPlans = benefitPlans
		.filter((plan) => plan.benefitPlanGroupPrimaryId)
		.toSorted((x, y) => y.benefitPlanRank - x.benefitPlanRank)
	const plans = benefitPlans.filter((plan) => !plan.benefitPlanGroupPrimaryId)

	for (let i = 0; i < accessoryPlans.length; i++) {
		const accessoryPlan = accessoryPlans[i]

		plans.splice(
			plans.findIndex((plan) => plan.benefitPlanId === accessoryPlan.benefitPlanGroupPrimaryId) + 1,
			0,
			accessoryPlan,
		)
	}

	return plans
}
