import { UserManager } from 'oidc-client'
import { createSelector } from 'reselect'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { siteSelector } from './site/selectors'
import { SiteState } from './site/types'
import { localUserSelector } from './user/selectors'
import { UserState } from './user/types'

export type SilentRenewSelector = {
	siteInfo: SiteInfoResponse
	userManager: UserManager
}

const silentRenewInfo = (site: SiteState, user: UserState): SilentRenewSelector => ({
	siteInfo: site.siteInfo,
	userManager: user.userManager,
})

export const silentRenewSelector = createSelector(siteSelector, localUserSelector, silentRenewInfo)
