import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function skeleton(): CssVarsThemeOptions['components'] {
	return {
		MuiSkeleton: {
			styleOverrides: {
				root: () => ({
					// backgroundColor: theme.vars.palette.,
				}),
				rounded: ({ theme }) => ({
					borderRadius: theme.vars.shape.borderRadius * 2,
				}),
			},
		},
	}
}
