import { UserEnrollmentJourneyContextProvider } from 'contexts/user-enrollment-journey-context'
import { useEnrollmentJourneyState } from 'feature/home/hooks'
import { useAppSelector } from 'hooks/redux'
import { ReactNode } from 'react'

export const GlobalContextProviders = (props: { children: ReactNode; isLoggedIn: boolean }) => {
	return <UserEnrollmentJourneyContext isLoggedIn={props.isLoggedIn}>{props.children}</UserEnrollmentJourneyContext>
}

const UserEnrollmentJourneyContext = (props: { children: ReactNode; isLoggedIn: boolean }) => {
	const tenantHasEnrollments = useAppSelector((s) => s.site.siteInfo.properties.hasVoluntaryBenefits) ?? false

	// don't render this context if the user is not logged in or the tenant does not have enrollments
	if (!props.isLoggedIn || !tenantHasEnrollments) return <>{props.children}</>

	return (
		<AuthenticatedEnrollmentJourneyContext isLoggedIn={props.isLoggedIn}>
			{props.children}
		</AuthenticatedEnrollmentJourneyContext>
	)
}

// only render this when we have an axiosToken and the user is logged in
const AuthenticatedEnrollmentJourneyContext = (props: { children: ReactNode; isLoggedIn: boolean }) => {
	const state = useEnrollmentJourneyState()

	return (
		<UserEnrollmentJourneyContextProvider
			values={{
				state,
			}}
		>
			{props.children}
		</UserEnrollmentJourneyContextProvider>
	)
}
