import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function appBar(): CssVarsThemeOptions['components'] {
	return {
		MuiAppBar: {
			styleOverrides: {
				root: ({ theme }) => ({
					boxShadow: '0 0.188rem 0.375rem rgba(0, 0, 0, 0.08)',
					backgroundColor: theme.vars.palette.background.default,
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					height: 'var(--header-height)',
					maxHeight: 'var(--header-height)',
					flexWrap: 'wrap',
					flexDirection: 'row',
					flex: 1,
					position: 'sticky',
					alignItems: 'center',
				}),
			},
		},
	}
}
