/**
 * @param text the text to copy
 * @returns the copy function
 */
export const useCopyToClipboard = () => {
	return (text: string) => {
		const el = document.createElement('textarea')
		el.value = text
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}
}
