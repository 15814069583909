// Converts a hex color into an rgb color array
function hexToRgb(hex) {
	// Remove "#" if it's included
	hex = hex.replace(/^#/, '')

	// Convert 3-digit hex to 6-digit hex
	if (hex.length === 3) {
		hex = hex
			.split('')
			.map((h) => h + h)
			.join('')
	}

	// Parse r, g, b values from hex
	const bigint = parseInt(hex, 16)

	return [
		(bigint >> 16) & 255, // Red
		(bigint >> 8) & 255, // Green
		bigint & 255, // Blue
	]
}

// Calculates the luminance from the given rgb array
function getLuminanceFromRGB(rgb) {
	// Do some Normalization and Linearization
	const [r, g, b] = rgb.map((c) => {
		c /= 255

		return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
	})

	// Calculate the Luminance via the magical formula
	return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

function getLuminance(color) {
	let rgb

	// Check if the input is in hex format
	if (color.startsWith('#')) {
		rgb = hexToRgb(color) // Convert hex to RGB
	} else if (color.startsWith('rgb')) {
		rgb = color.match(/\d+/g).map(Number) // Extract RGB values
	} else {
		throw new Error('Unsupported color format. Please provide a hex or rgb color.')
	}

	return getLuminanceFromRGB(rgb)
}

/**
 * Determines the best text color based on the background color
 * @param bgColor The color of the background (hex or rgb)
 * @returns The best text color (black or white)
 */
export function getTextColor(bgColor) {
	const luminance = getLuminance(bgColor)

	/* Contrast threshold (0.36 very closely predicts the best text color
	as determined by Accessible Colour Contrast Checker) */
	return luminance > 0.36 ? 'black' : 'white'
}
