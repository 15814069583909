import { User } from 'oidc-client'
import { useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_KEYS } from 'utils/storage/local-storage'
import { LocalStore } from 'utils/utils'

export const useReturnUrl = () => {
	const navigate = useNavigate()

	const handleReturnUrl = (user: User): User | undefined => {
		const prevRoute = LocalStore.get('prevRoute')

		const returnUrl: string | null = LocalStore.get('returnUrl')
		const isImpersonation: string | null = LocalStore.get('isImpersonation')
		const query: string | null = LocalStore.get('query')

		document.cookie = `loggedIn=true;expires=${user.expires_at};max=${user.expires_in}`
		let navigateUrl = '/home'
		if (returnUrl) {
			localStorage.setItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic, 'true')
			const updatedReturnUrl: string = returnUrl ?? '/home'

			if (isImpersonation === 'true') {
				navigateUrl = '/' + updatedReturnUrl
			} else if (updatedReturnUrl?.includes('http')) {
				window.location.href = updatedReturnUrl

				return user
			} else {
				navigateUrl = updatedReturnUrl
			}
		}

		if (prevRoute && !returnUrl) {
			navigateUrl = prevRoute as string
			localStorage.setItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic, 'true')
		}
		if (query) {
			return user
		}

		const separator = navigateUrl.includes('?') ? '&' : '?'
		navigate(navigateUrl + separator)
	}

	return {
		handleReturnUrl,
	}
}
