import { Button, Typography } from '@mui/material'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { routesMap } from 'routes/routes-map'
import { ThemePage } from '../../types'
import styles from './travel-section.module.css'

type TravelSectionProps = ThemePage['travel']

export function TravelSection({ imageLink, cta, subtitle, title }: TravelSectionProps) {
	const track = useTrackDetailedPageEvent()

	const onTravelCtaClick = () => {
		track({
			actionType: 'click',
			elementName: 'access travel cta',
			elementType: 'button',
		})
	}

	return (
		<div className={styles.travel}>
			<div className={styles['travel-content']}>
				<img className={styles['travel-image']} src={imageLink} alt='img' />
				<div className={styles['travel-body']}>
					<div className={styles['travel-copy']}>
						<Typography variant='h2' className={styles['travel-title']}>
							{title}
						</Typography>
						<Typography variant='h5-body' className={styles['travel-subtitle']}>
							{subtitle}
						</Typography>
					</div>
					{cta.visible && (
						<Button
							className={styles['travel-cta']}
							variant='outlined'
							color='inherit'
							href={cta.link ? cta.link : routesMap.deals.travel.path}
							onClick={onTravelCtaClick}
						>
							{cta.title}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}
