import { CssVarsThemeOptions, alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function backdrop(): CssVarsThemeOptions['components'] {
	return {
		MuiBackdrop: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: alpha(theme.palette.grey[900], 0.8),
				}),
				invisible: {
					background: 'transparent',
				},
			},
		},
	}
}
