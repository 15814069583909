import { SiteEnvironment } from 'app/types'
import { Environments } from 'utils/env'

/**
 * wawa.int.corestream.com (int)
 * wawa.corestream.com (prod)
 * wawa.test.corestream.com (test)
 * wawa.dev.corestream.com (dev)
 *
 * special case is when we have a preview environment where we will let the user select the environemnt + tenant
 * example: <hash>.preview.corestream.com (preview)
 *
 * @param hostStr
 */
export function parseEnvAndDomainFromHost(hostStr: string): SiteEnvironment {
	const [subdomain, env] = hostStr.split('.')
	const envMapping = {
		dev: Environments.DEV,
		int: Environments.INT,
		preview: 'preview',
		test: Environments.UAT,
	}

	return {
		env: envMapping[env] || Environments.PROD,
		subdomain,
	}
}
