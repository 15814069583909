/**
 * Retries a promise (default) twice with a default interval of 1s between attempts
 * @param fn
 * @param retriesLeft
 * @param interval
 */
export function retry(fn: () => Promise<any>, retriesLeft = 2, interval = 1000): Promise<any> {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error: any) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error)

						return
					}

					// Passing on "reject" is the important part
					retry(fn, interval, retriesLeft - 1).then(resolve, reject)
				}, interval)
			})
	})
}
