export const MQ_DEFAULT = 0
export const MQ_XS = MQ_DEFAULT
export const MQ_MD = 600
export const MQ_MDLG = 768
export const MQ_LG = 960
export const MQ_2LG = 1024
export const MQ_XL = 1280
export const MQ_2XL = 1920
export const MQ_3XL = 2560

// These values (in pixels) match the padding on the sides of the containers in CSS
export const MQ_PADDING_DEFAULT = 30
export const MQ_PADDING_LG = 73.5
export const MQ_PADDING_XL = 147
export const MQ_PADDING_2XL = 168
