import { isAfter, isBefore, parse } from 'date-fns'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { User, UserManager } from 'oidc-client'
import { UserState } from 'redux-oidc-js'
import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'
import { formatDate } from 'utils/util-dates'
import { DeductionsToFrequencyString } from 'utils/utils'
import { UserState as LocalUserState } from './types'

export type IMyProfileUser = {
	birthDate?: string
	canContact: boolean | string
	countryCode?: string
	deductions_per_year?: string
	email: string
	employeeId?: string
	firstName: string
	isDiscountsOnly: boolean
	lastName: string
	phoneNumber?: string
	locationCode?: string
	constituentId?: string
	workerId?: string
	zipCode: string
	employmentType?: string
	employmentGroup?: string
	benefitDeductionGroupCode?: string
	userId?: string
}

export type UserClaim = {
	birthdate?: string
	can_contact?: string
	country_code?: string
	deductions_per_year?: string
	employee_id?: string
	family_name?: string
	given_name?: string
	hire_date?: string
	is_discounts_only?: string
	location_code?: string
	phone_number?: string
	preferred_username?: string
	constituent_id?: string
	worker_id?: string
	zip_code?: string
	employment_type?: string
	employment_group?: string
	benefitdeductiongroupcode?: string
	TenantId?: string
	sub?: string
	state_province?: string
}

/**
 * Catches bad dates from claims data and replaces them with ''
 * Also uses local storage to populate user slice with the latest
 * input for birthdate until user logs out since we do not get updated
 * values from the db until login. Local storage data takes priority
 * as it has already been validated and should only be present if
 * the user previously inputted a birthdate in the wizard and has
 * not logged out
 * @param bd string Date string from user claims
 * @returns string Date string formatted for use with date picker
 */
function memoizeBirthDate(): (bd: string) => string {
	const memoBd = {}

	return (bd) => {
		if (bd in memoBd) {
			return memoBd[bd]
		} else {
			const storedBirthDate = localStorage.getItem('profileBirthDate')
			if (storedBirthDate) {
				const formattedLocalBirthDate = formatDate(storedBirthDate)
				memoBd[bd] = formattedLocalBirthDate

				return formattedLocalBirthDate
			}

			const parsedBd = parse(bd, 'MM/dd/yyyy', new Date())
			// check if data from db is valid
			const formattedClaimBirthDate =
				isAfter(parsedBd, new Date('1/1/1900')) && isBefore(parsedBd, new Date()) ? formatDate(bd) : ''
			memoBd[bd] = formattedClaimBirthDate

			return formattedClaimBirthDate
		}
	}
}

const parseBirthDate = memoizeBirthDate()

export class MyProfileUser implements IMyProfileUser {
	benefitDeductionGroupCode = ''
	birthDate = ''
	canContact = true
	countryCode = ''
	deductions_per_year = ''
	email = ''
	employeeId = ''
	employmentType?: string
	employmentGroup?: string
	firstName = 'User'
	hireDate = ''
	isDiscountsOnly
	isCorestreamUser: boolean = false
	lastName = ''
	phoneNumber = ''
	locationCode: string = ''
	userId: string = ''
	constituentId = ''
	workerId = ''
	zipCode = ''
	stateProvince = ''

	constructor(data?: UserClaim) {
		if (data) {
			this.benefitDeductionGroupCode = data?.benefitdeductiongroupcode ?? ''
			this.birthDate = parseBirthDate(data?.birthdate ?? '')
			this.deductions_per_year = data?.deductions_per_year ?? ''
			this.email = data?.preferred_username ?? ''
			this.employeeId = data?.employee_id ?? ''
			this.canContact = data?.can_contact?.toLowerCase() === 'true'
			this.countryCode = data?.country_code ?? ''
			this.firstName = data?.given_name ?? ''
			this.hireDate = data?.hire_date ?? ''
			this.isDiscountsOnly = data?.is_discounts_only?.toLowerCase() === 'true'
			this.lastName = data?.family_name ?? ''
			this.phoneNumber = data?.phone_number ?? ''
			this.locationCode = data?.location_code ?? ''
			this.constituentId = data?.constituent_id ?? ''
			this.workerId = data?.worker_id ?? ''
			this.zipCode = data?.zip_code ?? ''
			this.employmentType = data?.employment_type ?? ''
			this.employmentGroup = data?.employment_group ?? ''
			this.isCorestreamUser = data?.TenantId?.toLowerCase() === 'corestream'
			this.stateProvince = data?.state_province ?? ''
			this.userId = data?.sub ?? ''
		}
	}
}

export const getNewProfileUser = (currentProfile: MyProfileUser, data: any): MyProfileUser => {
	return {
		...currentProfile,
		canContact: data.can_contact?.toLowerCase() === 'true',
		firstName: data?.given_name ?? '',
		lastName: data?.family_name ?? '',
		phoneNumber: data?.phone_number ?? '',
		zipCode: data?.zip_code ?? '',
	}
}

export const oidcSelector = (state: RootState): UserState => state.oidc
export const localUserSelector = (state: RootState): LocalUserState => state.user
export const isLoggedInSelector = createSelector(localUserSelector, ({ isLoggedIn }) => isLoggedIn)
export const userSelector = createSelector(oidcSelector, (oidc: UserState): User | undefined => oidc.user)
export const myProfileSelector = createSelector(localUserSelector, (user): MyProfileUser => user.userProfile)
export const getUserManagerSelector = createSelector(localUserSelector, (user): UserManager => user.userManager)
export const isLoadingUserSelector = createSelector(localUserSelector, (user): boolean => user.isLoadingUser)
export const userRecommendedOffersSelector = createSelector(
	localUserSelector,
	(user): Array<string> => user.recommendedOfferIds,
)
export const userRecommendedOffersLoadingSelector = createSelector(
	localUserSelector,
	(user): StateMachineStatus => user.isRecommendedOffersLoading,
)
export const isUserProfileLoadedSelector = createSelector(
	localUserSelector,
	(user): boolean => user.isUserProfileLoaded,
)

export const updatingProfileStatusSelector = createSelector(
	localUserSelector,
	(user): StateMachineStatus => user.updatingProfileStatus,
)

export const selectUserDeductionPeriod = createSelector(localUserSelector, (user) =>
	DeductionsToFrequencyString(Number(user.userProfile.deductions_per_year)),
)
