import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function svgIcon(): CssVarsThemeOptions['components'] {
	return {
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeLarge: {
					width: 32,
					height: 32,
					fontSize: 'inherit',
				},
			},
		},
	}
}
