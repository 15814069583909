import debounce from 'lodash-es/debounce'
import { useEffect, useState } from 'react'

/**
 * when user stops resizing the window for 100ms, return the window size
 */
const useSize = (): number => {
	const [size, setSize] = useState<number>(() => window.innerWidth)

	useEffect(() => {
		const setInnerWidth = (): void => setSize(window.innerWidth)
		// once user stops resizing, set the size
		const resizeListener = debounce(setInnerWidth, 100)
		window.addEventListener('resize', resizeListener)

		return (): void => window.removeEventListener('resize', resizeListener)
	}, [])

	return size
}

export default useSize
