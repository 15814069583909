import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import debounce from 'lodash-es/debounce'
import { AuthenticatedEmployee } from 'pages/autoquotes/model/CommonTypes'
import { AdditionalDriverDTO, DriverBaseDTO } from 'pages/autoquotes/model/DriverTypes'
import { FieldBaseDTO } from 'pages/autoquotes/model/FieldTypes'
import { IncidentBaseDTO } from 'pages/autoquotes/model/IncidentTypes'
import {
	QuotingSessionDTO,
	QuotingSessionInfoDTO,
	ResponseInfoDTO,
	SubmitResultDTO,
	ValidationResultDTO,
} from 'pages/autoquotes/model/QuoteSessionTypes'
import { VehicleDTO, VinInfoDTO } from 'pages/autoquotes/model/VehicleTypes'
import { AutoquotesService } from 'services/autoquote.service'
import { AppThunkAction } from 'store/util-types/types'
import { QuoteState, initialState } from './types'

export const autoquotesSlice = createSlice({
	initialState,
	name: 'quote',
	reducers: {
		getQuoteError: (state: QuoteState, action: PayloadAction<Error>): void => {
			state.quoteError = action.payload
			state.isQuoteLoading = false
			state.isLoading = false
		},
		getQuoteSuccess: (state: QuoteState, action: PayloadAction<QuotingSessionDTO>): void => {
			state.quoteError = null
			state.isQuoteLoading = false
			state.isLoading = false
			state.quote = { ...action.payload }
		},
		setHasPreviousData: (state: QuoteState, action: PayloadAction<boolean>): void => {
			state.hasPreviousData = action.payload
		},
		setIsLoading: (state: QuoteState, action: PayloadAction<boolean>): void => {
			state.isLoading = action.payload
		},
		setQuotingAvailable: (state: QuoteState, action: PayloadAction<boolean>): void => {
			state.isQuotingAvailable = action.payload
		},
		updateCoverageProperty: (state: QuoteState, action: PayloadAction<{ name: string; value: undefined }>): void => {
			state.quote.coverage[action.payload.name] = action.payload.value
		},
		updateDriver: (state: QuoteState, action: PayloadAction<DriverBaseDTO>): void => {
			const d = state.quote.drivers.filter((d) => {
				return d.id !== action.payload.id
			})
			state.quote.drivers = [...d, action.payload]
		},
		updateDriverProperty: (
			state: QuoteState,
			action: PayloadAction<{ id: string; name: string; value: undefined }>,
		): void => {
			const driver = state.quote.drivers.find((v) => v.id === action.payload.id)
			if (driver) driver[action.payload.name] = action.payload.value
		},
		updateDriverVehicleUsage: (
			state: QuoteState,
			action: PayloadAction<{ id: string; vehicleId: string; value: string }>,
		): void => {
			const vehicle = state.quote.vehicles.find((v) => v.id === action.payload.vehicleId)

			if (vehicle) {
				const vu = vehicle.vehicleUsages.find((v) => v.id === action.payload.id)
				if (vu) vu.usage = action.payload.value
			} //else console.warn(`Vehicle ${action.payload.vehicleId} not found`)
		},
		updateFieldValue: (
			state: QuoteState,
			action: PayloadAction<{ id: string; entityId: string; value: string }>,
		): void => {
			const driver = state.quote.drivers.find((i) => i.id === action.payload.entityId)
			if (driver) {
				const field = driver.fields.find((i) => i.id === action.payload.id)
				if (field) field.value = action.payload.value
				//(`Updated value to ${action.payload.value} on driver's field ${action.payload.id} `)
			}
			const coverage = state.quote.coverage
			if (coverage) {
				const field = coverage.fields.find((i) => i.id === action.payload.id)
				if (field) field.value = action.payload.value
			}
			const vehicle = state.quote.vehicles.find((i) => i.id === action.payload.entityId)
			if (vehicle) {
				const field = vehicle.fields.find((i) => i.id === action.payload.id)
				if (field) field.value = action.payload.value
			}
		},
		updateIncidentProperty: (
			state: QuoteState,
			action: PayloadAction<{ id: string; driverId: string; name: string; value: undefined }>,
		): void => {
			const driver = state.quote.drivers.find((i) => i.id === action.payload.driverId)
			if (driver) {
				const incident = driver.incidents.find((v) => v.id === action.payload.id)
				if (incident) incident[action.payload.name] = action.payload.value
			} //else console.warn(`Driver ${action.payload.driverId} not found`)
		},
		updateVehicleByVinInfo: (
			state: QuoteState,
			action: PayloadAction<{ id: string; vinInfo: VinInfoDTO | null }>,
		): void => {
			const vehicle = state.quote.vehicles.find((v) => v.id === action.payload.id)
			if (vehicle) {
				vehicle.vinAvailable = true
				if (action.payload.vinInfo) {
					vehicle.type = action.payload.vinInfo.type
					vehicle.year = action.payload.vinInfo.year
					vehicle.make = action.payload.vinInfo.make
					vehicle.model = action.payload.vinInfo.model
					vehicle.bodyStyle = action.payload.vinInfo.bodyStyle
					vehicle.driversAirBag = action.payload.vinInfo.hasDriversAirBag
					vehicle.passengersAirBag = action.payload.vinInfo.hasPassangerAirBag
					vehicle.sideImpactSafetyShields = action.payload.vinInfo.hasSideImpactShield
					vehicle.disablingDevice = action.payload.vinInfo.disablingDevice
					vehicle.abs = action.payload.vinInfo.hasABS
					vehicle.vinValid = true
					vehicle.costOfVehicle = action.payload.vinInfo.costOfVehicle
					vehicle.vinInfo = action.payload.vinInfo
				} else {
					vehicle.type = null
					vehicle.year = null
					vehicle.make = null
					vehicle.model = null
					vehicle.bodyStyle = null
					vehicle.driversAirBag = false
					vehicle.passengersAirBag = false
					vehicle.sideImpactSafetyShields = false
					vehicle.disablingDevice = null
					vehicle.abs = null
					vehicle.vinValid = false
					vehicle.costOfVehicle = null
					vehicle.vinInfo = null
				}
			}
		},
		updateVehicleProperty: (
			state: QuoteState,
			action: PayloadAction<{ id: string; name: string; value: undefined }>,
		): void => {
			const vehicle = state.quote.vehicles.find((v) => v.id === action.payload.id)
			if (vehicle) vehicle[action.payload.name] = action.payload.value

			//const q = deepCopy(state.quote) as QuotingSessionDTO
		},
	},
})
const delay = (ms: number): Promise<void> => new Promise<void>((res) => setTimeout(res, ms))
// Extract the action creators object and the reducer
export const {
	updateDriverProperty,
	updateCoverageProperty,
	updateVehicleProperty,
	updateDriverVehicleUsage,
	updateFieldValue,
	updateIncidentProperty,
} = autoquotesSlice.actions
const { actions, reducer } = autoquotesSlice
// Export the reducer and actions
export { actions, reducer as autoquotes }

export const loadQuoteSessionThunkAction =
	(sessionId: string): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const quote: QuotingSessionDTO = await AutoquotesService.getQuote(sessionId)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const updateDriverPropertyThunkAction =
	(id: string, name: string, value: undefined): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		dispatch(actions.updateDriverProperty({ id, name, value }))
		if (AutoquotesService.isAutoCallbackProperty('Driver', name)) {
			dispatch(debounce(checkFieldsThunkAction()))
		}
	}
export const updateCoveragePropertyThunkAction =
	(name: string, value: undefined): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		dispatch(actions.updateCoverageProperty({ name, value }))
		if (AutoquotesService.isAutoCallbackProperty('Coverage', name)) {
			dispatch(debounce(checkFieldsThunkAction()))
		}
	}
export const updateVehiclePropertyThunkAction =
	(id: string, name: string, value: undefined): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		dispatch(actions.updateVehicleProperty({ id, name, value }))
		if (AutoquotesService.isAutoCallbackProperty('Vehicle', name)) {
			dispatch(debounce(checkFieldsThunkAction()))
		}
	}
export const updateFieldValueThunkAction =
	(field: FieldBaseDTO, value: string): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		dispatch(
			actions.updateFieldValue({
				entityId: field.entity_Id,
				id: field.id,
				value,
			}),
		)
		if (field.autoCallback) {
			dispatch(debounce(checkFieldsThunkAction()))
		}
	}
export const checkFieldsThunkAction =
	(): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.checkFields(q)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const createNewQuoteThunkAction =
	(zip?: string): AppThunkAction =>
	async (dispatch): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const quote: QuotingSessionDTO = await AutoquotesService.createQuote(zip)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const saveDriverThunkAction =
	(driverId: string): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.updateDriver(driverId, q)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const addDriverThunkAction =
	(): AppThunkAction =>
	async (dispatch, getState): Promise<AdditionalDriverDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const driver: AdditionalDriverDTO = await AutoquotesService.createAdditionalDriver(q)
			const quoteRefreshed: QuotingSessionDTO = await AutoquotesService.getQuote(q.id)
			dispatch(actions.getQuoteSuccess(quoteRefreshed))

			return quoteRefreshed.drivers.find((i) => i.id === driver.id) as AdditionalDriverDTO
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const deleteDriverThunkAction =
	(driverId: string): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.deleteDriver(driverId, q)
			if (quote.drivers.length > 0) quote.drivers[quote.drivers.length - 1].hasAdditionalDriver = false
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const saveCoverageThunkAction =
	(): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.updateCoverage(q)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}

export const saveVehicleThunkAction =
	(vehicledId: string): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.updateVehicle(vehicledId, q)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const deleteVehicleThunkAction =
	(vehicledId: string): AppThunkAction =>
	async (dispatch, getState): Promise<QuotingSessionDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const quote: QuotingSessionDTO = await AutoquotesService.deleteVehicle(vehicledId, q)
			dispatch(actions.getQuoteSuccess(quote))

			return quote
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const addVehicleThunkAction =
	(): AppThunkAction =>
	async (dispatch, getState): Promise<VehicleDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const vehicle: VehicleDTO = await AutoquotesService.createVehicle(q)
			const newQuote = { ...q } as QuotingSessionDTO
			newQuote.vehicles = [...q.vehicles, vehicle].sort((i1, i2) => {
				return i1.numberId - i2.numberId
			})
			dispatch(actions.getQuoteSuccess(newQuote))

			return vehicle
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const addIncidentThunkAction =
	(driverId: string, type: string): AppThunkAction =>
	async (dispatch, getState): Promise<IncidentBaseDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const incident: IncidentBaseDTO = await AutoquotesService.createIncident(driverId, type, q)
			const quote = deepCopy(getState().autoquotes.quote) as QuotingSessionDTO
			const driver = quote.drivers.find((i) => i.id === driverId)
			if (driver) {
				driver.incidents.push(incident)
				dispatch(actions.getQuoteSuccess(quote))
			}

			return incident
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}

export const saveIncidentsThunkAction =
	(driverId: string): AppThunkAction =>
	async (dispatch, getState): Promise<IncidentBaseDTO[] | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			const incidents: IncidentBaseDTO[] = await AutoquotesService.updateIncidents(driverId, q)

			const quote = getState().autoquotes.quote
			const driver = quote.drivers.find((i) => i.id === driverId)
			if (driver) {
				driver.incidents = [...incidents].sort((i1, i2) => {
					return i1.numberId - i2.numberId
				})
			}
			dispatch(actions.getQuoteSuccess(quote))

			return incidents
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}

export const deleteIncidentThunkAction =
	(driverId: string, incidentId: string): AppThunkAction =>
	async (dispatch, getState): Promise<IncidentBaseDTO | void> => {
		try {
			dispatch(actions.setIsLoading(true))
			const q = getState().autoquotes.quote
			await AutoquotesService.deleteIncident(driverId, incidentId, q)

			const quote = getState().autoquotes.quote
			const driver = quote.drivers.find((i) => i.id === driverId)
			if (driver) {
				const unchangedItems = driver.incidents.filter((d) => {
					return d.id !== incidentId
				})
				const d = { ...driver } as DriverBaseDTO
				d.incidents = [...unchangedItems].sort((i1, i2) => {
					return i1.numberId - i2.numberId
				})
				dispatch(actions.updateDriver(d))
			}
			dispatch(actions.setIsLoading(false))
		} catch (err) {
			dispatch(actions.getQuoteError(err as Error))
		}
	}
export const getVinInfoThunkAction =
	(vehicleId: string, vin: string): AppThunkAction =>
	async (dispatch): Promise<VinInfoDTO | void> => {
		if (!vin) {
			dispatch(actions.updateVehicleByVinInfo({ id: vehicleId, vinInfo: null }))

			return
		}
		try {
			dispatch(actions.setIsLoading(true))
			const vinInfo: VinInfoDTO = await AutoquotesService.getVinInfo(vin)
			dispatch(actions.updateVehicleByVinInfo({ id: vehicleId, vinInfo: vinInfo }))
			dispatch(actions.setIsLoading(false))

			return vinInfo
		} catch (err) {
			//dispatch(actions.getQuoteError(err as Error))
		}
	}
export const validateQuoteThunkAction =
	(quote: QuotingSessionDTO): AppThunkAction =>
	async (): Promise<ValidationResultDTO[] | void> => {
		try {
			const result: ValidationResultDTO[] = await AutoquotesService.validateQuote(quote)

			return result
		} catch (err) {
			// do nothing
		}
	}
export const submitQuoteThunkAction =
	(quote: QuotingSessionDTO): AppThunkAction =>
	async (): Promise<SubmitResultDTO | void> => {
		try {
			const result: SubmitResultDTO = await AutoquotesService.submitQuote(quote)
			let quoteStatus: string = result.status

			while (quoteStatus === 'RequestSubmitted') {
				await delay(15000)
				quoteStatus = await AutoquotesService.getQuoteStatus(quote.id)
			}
			if (result.setId) {
				const responses: ResponseInfoDTO[] = await AutoquotesService.getQuoteResponseInfosBySetId(
					quote.id,
					result.setId,
				)
				result.responseInfos = responses
			} else {
				const responses: ResponseInfoDTO[] = await AutoquotesService.getQuoteResponseInfos(quote.id)
				result.responseInfos = responses
			}

			return result
		} catch (err) {
			// do nothing
		}
	}
export const getMeThunkAction = (): AppThunkAction => async (): Promise<AuthenticatedEmployee | void> => {
	const me: AuthenticatedEmployee = await AutoquotesService.getMe()

	return me
}
export const getIsQuotingAvailableAction =
	(zip?: string): AppThunkAction =>
	async (dispatch): Promise<boolean | void> => {
		const result: boolean = await AutoquotesService.isQuotingAvailable(zip)
		dispatch(actions.setQuotingAvailable(result))

		return result
	}
export const hasPreviousDataAction =
	(): AppThunkAction =>
	async (dispatch): Promise<boolean | void> => {
		const result: boolean = await AutoquotesService.hasPreviousData()
		dispatch(actions.setHasPreviousData(result))

		return result
	}
export const getQuoteResponseInfosThunkAction =
	(id: string): AppThunkAction =>
	async (): Promise<ResponseInfoDTO[] | void> => {
		const result: ResponseInfoDTO[] = await AutoquotesService.getQuoteResponseInfos(id)

		return result
	}
export const getQuoteResponseInfosBySetIdThunkAction =
	(id: string, setId: string): AppThunkAction =>
	async (): Promise<ResponseInfoDTO[] | void> => {
		const result: ResponseInfoDTO[] = await AutoquotesService.getQuoteResponseInfosBySetId(id, setId)

		return result
	}
export const getMyResponseInfosThunkAction = (): AppThunkAction => async (): Promise<ResponseInfoDTO[] | void> => {
	const result: ResponseInfoDTO[] = await AutoquotesService.getMyResponseInfos()

	return result
}

export const getMySessionInfosThunkAction = (): AppThunkAction => async (): Promise<QuotingSessionInfoDTO[] | void> => {
	const result: QuotingSessionInfoDTO[] = await AutoquotesService.getMySessionInfos()

	return result
}

const deepCopy = (obj): any => {
	let copy

	// Handle the 3 simple types, and null or undefined
	if (null === obj || 'object' !== typeof obj) return obj

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date()
		copy.setTime(obj.getTime())

		return copy
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = []
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = deepCopy(obj[i])
		}

		return copy
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {}
		for (const attr in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr])
		}

		return copy
	}

	throw new Error("Unable to copy obj! Its type isn't supported.")
}
