import { ContinuationsService } from 'services/continuations.service'
import { EnrollmentWizardState } from 'store/enrollment-wizard/types'
export interface IExternalStoreSync<T> {
	readonly eventType: string
	saveState: (state: T) => void
	loadState: () => Promise<T | null>
	removeState: () => void
}

export class RedisStorageStoreSync implements IExternalStoreSync<EnrollmentWizardState> {
	eventType: string

	constructor(eventType: string) {
		this.eventType = eventType
	}

	saveState(state: EnrollmentWizardState) {
		return ContinuationsService.saveContinuation(state, this.eventType)
	}

	async loadState() {
		return ContinuationsService.getContinuation<EnrollmentWizardState>(this.eventType)
	}

	removeState() {
		return ContinuationsService.deleteContinuation(this.eventType)
	}
}

export enum ExternalStoreLoadingStatus {
	Found,
	NotFound,
	Pending,
}
