import { PageNameSpecMap } from './PageNameSpecMap'
import { useRouteSpec } from './useRouteSpec'

export const useEventPageName = () => {
	const routeSpec = useRouteSpec()

	if (!routeSpec) return null

	/*
	 * Find pageName in PageNameSpecMap
	 * It will loop through each route in the current path
	 * starting from the right and return the first match
	 * Ex: '/themes/holiday' will return 'themes' (after attempting to find 'holiday')
	 */
	const routes = routeSpec.split('/')
	while (routes.length) {
		const testPath = routes.join('/')
		if (PageNameSpecMap[testPath]) return PageNameSpecMap[testPath]
		routes.pop()
	}

	return null
}
