import { axiosInstance } from 'config/axios'
import { UserNewHireInfo } from 'services/enrollments.service'
import UrlBuilder from 'services/util/url-builder'
import { API_SUBDOMAIN, endpoints } from '../constants'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class NewHireService {
	static UserNewHireInfo(): Promise<UserNewHireInfo> {
		const url = urlBuilder.setEndpoint(endpoints.NewHire.getEligibilityWindowInformation()).url()

		return axiosInstance.get(url)
	}
}
