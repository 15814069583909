export const NONE = 'None'
export const TRENDING = 'Trending'
export const FEATURED = 'Featured'
export const HERO = 'Hero'
export const EXCLUSIVE_PARTNERSHIPS = 'ExclusivePartnership'
export type FeatureLevel =
	| typeof NONE
	| typeof TRENDING
	| typeof FEATURED
	| typeof HERO
	| typeof EXCLUSIVE_PARTNERSHIPS
	| ''
