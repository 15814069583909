import { axiosInstance } from 'config/axios'
import { format } from 'date-fns'
import { match } from 'ts-pattern'
import { BenefitPlanQuestion, BenefitQuestionListV2 } from 'types/benefit-questions'
import { CategoryPageResponse } from 'types/category-page-response'
import { NewRate, RateRes, SavingsCalculatorRatesRequest, SavingsCalculatorRatesResponse } from 'types/rates'
import { TierOptionsAndLowestRate } from 'types/tier-options-and-lowest-rate'
import { convertBoolToNumber } from 'utils/utils'
import {
	EffectiveDatesResponse,
	OEWindowsResponse,
	TemplateToken,
	UserOEWindowResponse,
} from '../store/benefit-plans/types'
import UrlBuilder from './util/url-builder'

const urlBuilder: UrlBuilder = new UrlBuilder('benefitelections')
// ---------------------------------------------------------------------
const RATES: string = 'Rates'
const QUESTIONS: string = 'Questions'

export interface RateRequestBoolVals {
	benefitPlanId: number
	employeeDoB?: string
	employeeIsSmoker?: boolean
	keyDate: string
	spouseDoB?: string
	spouseIsSmoker?: boolean
	selectedPrimaryPlanCoverageId?: string
}
export default class BenefitPlansService {
	static fetchEffectiveDates(orgId: string): Promise<EffectiveDatesResponse> {
		const today = format(new Date(), 'yyyy-MM-dd')
		const urlPath = `BenefitPlans/Organizations/${orgId}/EffectiveDates`

		return axiosInstance.get(urlBuilder.setEndpoint(urlPath).url(), {
			params: { date: today },
		})
	}

	static fetchUserOEWindow(orgId: string): Promise<UserOEWindowResponse> {
		const urlPath = `OpenEnrollmentWindows/Organizations/${orgId}/User`

		return axiosInstance.get(urlBuilder.setEndpoint(urlPath).url())
	}

	static fetchUserOEWindows(orgId: string): Promise<OEWindowsResponse> {
		const urlPath = `Organizations/${orgId}/UserOpenEnrollmentWindows`

		return axiosInstance.get(urlBuilder.setEndpoint(urlPath).url())
	}

	static fetchTemplateTokens(): Promise<TemplateToken[]> {
		const builder: UrlBuilder = new UrlBuilder()

		return axiosInstance.get(builder.setEndpoint(`Metadata/Tokens`).url())
	}

	static async getRates(reqInfo: RateRequestBoolVals, signal?: AbortSignal): Promise<NewRate[]> {
		const buildRequestObj = (req: RateRequestBoolVals): Record<string, string> => ({
			benefitPlanId: req.benefitPlanId.toString(),
			keyDate: req.keyDate,
			...(req.employeeDoB != null ? { employeeDoB: req.employeeDoB } : {}),
			...(req.employeeIsSmoker != null
				? { employeeIsSmoker: convertBoolToNumber(req.employeeIsSmoker).toString() }
				: {}),
			...(req.spouseDoB != null ? { spouseDoB: req.spouseDoB } : {}),
			...(req.spouseIsSmoker != null ? { spouseIsSmoker: convertBoolToNumber(req.spouseIsSmoker).toString() } : {}),
		})

		try {
			const rates: RateRes = await axiosInstance.get(
				urlBuilder
					.setEndpoint(RATES)
					.setQueryParams(new URLSearchParams(buildRequestObj(reqInfo)))
					.url(),
				{ signal },
			)

			return match<RateRes, NewRate[]>(rates)
				.with({ message: 'Success' }, ({ coverages, frequency }: RateRes): NewRate[] =>
					coverages != null ? coverages.map((coverage) => ({ ...coverage, frequency })) : [],
				)
				.otherwise(() => [])
		} catch (error) {
			throw new Error(error as string)
		}
	}

	static async getQuestions(benefitPlanId: number, signal?: AbortSignal): Promise<BenefitQuestionListV2> {
		if (!benefitPlanId || benefitPlanId < 0 || benefitPlanId == null || isNaN(benefitPlanId)) {
			return Promise.resolve([])
		}

		try {
			const rawQuestions: BenefitQuestionListV2 = await axiosInstance.get(
				urlBuilder.setEndpoint(`BenefitPlans/${benefitPlanId}/${QUESTIONS}`).url(),
				{ signal },
			)

			return rawQuestions
				.filter(
					(question) =>
						question?.role?.toLowerCase() !== 'relative' &&
						!question?.name?.toLowerCase()?.includes('ssn') &&
						!question.name.includes('active-at-work') &&
						!question.name.includes('premium'),
				)
				.map((question) =>
					question.fieldDefinitionId === 78 ? { ...question, name: 'tobacco-use', type: 'check-value' } : question,
				)
		} catch (error) {
			throw new Error(error as string)
		}
	}

	static async getBenefitQuestions(benefitPlanId: number, signal?: AbortSignal): Promise<Array<BenefitPlanQuestion>> {
		if (!benefitPlanId || benefitPlanId < 0 || benefitPlanId == null || isNaN(benefitPlanId)) {
			return Promise.resolve([])
		}
		try {
			const res: Array<BenefitPlanQuestion> = await axiosInstance.get(
				urlBuilder.setEndpoint(`BenefitPlans/${benefitPlanId}/${QUESTIONS}/v2`).url(),
				{ signal },
			)

			return res
		} catch (error) {
			throw new Error(error as string)
		}
	}

	static async getTierOptionsAndLowestRate(
		benefitPlanId: number,
		keyDate: Date | string,
	): Promise<TierOptionsAndLowestRate> {
		const keyDateString = typeof keyDate === 'string' ? keyDate : keyDate.toDateString()
		const endpoint = 'Rates/CoverageOptionsAndLowestRates'
		const url = urlBuilder
			.setEndpoint(endpoint)
			.setQueryParams(new URLSearchParams({ benefitPlanId: benefitPlanId.toString(), keyDate: keyDateString }))
			.url()

		return axiosInstance.get(url)
	}

	static async getCalculatorRates(request: SavingsCalculatorRatesRequest): Promise<SavingsCalculatorRatesResponse> {
		const endpoint = 'Rates/SavingsCalculatorRates'
		const url = urlBuilder.setEndpoint(endpoint).url()

		return axiosInstance.post(url, request)
	}

	static async getCategoryContent(categoryName: string): Promise<CategoryPageResponse> {
		const endpoint = 'Category/CategoryPage'
		const url = urlBuilder.setEndpoint(endpoint, categoryName).url()

		return axiosInstance.get(url)
	}
}
