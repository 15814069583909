type Endpoint = {
	id?: string
	endpoint: string
}

/**
 * A class to build urls for the app
 *
 * utilizes sessionStorage to globally configure a host override for the app
 *
 * @emits sessionStorage 'hostOverride'
 */
export default class UrlBuilder {
	private _baseSubdomain = 'tenants'
	private _endpoint: Endpoint = { endpoint: '' }
	private _queryParams: URLSearchParams | string = ''

	constructor(subdomain?: string, endpoint?: string, id?: string, host?: string) {
		if (subdomain) {
			this._baseSubdomain = subdomain
		}

		if (endpoint) {
			this._endpoint = { endpoint, id }
		}

		if (host) {
			sessionStorage.setItem('hostOverride', host)
		}
	}

	setBaseSubdomain(subdomain: string): UrlBuilder {
		this._baseSubdomain = subdomain

		return this
	}

	setEndpoint(endpoint: string, id?: number | string): UrlBuilder {
		this._endpoint = { endpoint, id: id?.toString() ?? '' }

		return this
	}

	setQueryParams(queryParams: URLSearchParams | string): UrlBuilder {
		this._queryParams = queryParams

		return this
	}

	setHost(host: string): UrlBuilder {
		sessionStorage.setItem('hostOverride', host)

		return this
	}

	url(): string {
		const queryParams = this._queryParams
		this.setQueryParams('')
		let url = `https://${this._baseSubdomain}.${this._getHost()}`

		// switch (this._baseSubdomain) {
		// 	// case 'billing':
		// 	// 	url = `http://localhost:5000`
		// 	// 	break
		// 	// case 'tenants':
		// 	// 	url = `https://localhost:5000`
		// 	// 	break
		// 	// case 'enrollments':
		// 	// 	url = 'https://localhost:5002'
		// 	// 	break
		// 	// case 'benefitelections':
		// 	// 	url = 'https://localhost:7217'
		// 	// 	break
		// 	default:
		// 		url = `https://${this._baseSubdomain}.${this._getHost()}`
		// }

		if (this._getEndpointString()) {
			url = `${url}/${this._getEndpointString()}`
		}

		return queryParams ? `${url}?${queryParams}` : url
	}

	private _getEndpointString(): string {
		const { endpoint, id } = this._endpoint

		return id ? `${endpoint}/${id}` : `${endpoint}`
	}

	private _getHost(): string {
		const host = window.location.hostname.split('.')

		const hostMap = {
			dev: 'dev.corestream.com',
			dr: 'dr.corestream.com',
			int: 'int.corestream.com',
			local: 'dev.corestream.com',
			prod: 'corestream.com',
			test: 'test.corestream.com',
		}

		const hostOverride = sessionStorage.getItem('hostOverride') || ''

		if (hostOverride !== '') {
			return hostMap[hostOverride]
		}

		switch (true) {
			case host.some((x, _, arr) => x === 'dr' && arr.length === 4):
				return hostMap.dr
			case host.some((x, _, arr) => (x === 'test' && arr.length === 4) || (x.includes('test') && arr.length === 2)):
				return hostMap.test
			case host.some((x, _, arr) => x === 'int' && arr.length >= 4):
				return hostMap.int
			case host.some((x, _, arr) => (x.includes('dev') || x.includes('local')) && arr.length >= 4):
				return hostMap.dev
			default:
				return hostMap.prod
		}
	}
}
