import { CaptureEventDetails, RequiredTypes, _useCaptureEvent } from './useCaptureEvent'

export interface EventOptions extends RequiredTypes {
	benefitPlanId?: number
	discountOfferId?: string
	offerBrand?: string
	offerName?: string
	offerType?: 'discounts' | 'benefits'
	elementDetails?: string
	enrollmentType?: CaptureEventDetails['enrollmentType']
	navigationMethod?: 'new tab' | 'refresh' | 'redirect'
}
/**
 * Tracking the events user takes on the wizard pages
 */
export const useTrackDetailedPageEvent = () => {
	const capture = _useCaptureEvent()

	return (eventData: EventOptions) => capture(eventData)
}
