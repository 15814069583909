import { FeatureLevel } from './feature-level'

import { offersIndex as searchIndex } from 'config/search-config'
import { CategoryLevel, Offer } from './offer'

export interface IAnalyticsOffer {
	brand: string
	category?: string
	productCategory: string
	color: string
	coupon: string | null
	cta: string
	description: string | null
	endDate: string
	image_url: string
	index: string
	keywords?: string
	level: FeatureLevel
	link: string
	name: string
	objectID: string
	product_id: string
	startDate: string
	tenantIDs: Array<string>
	terms: string
	title: string
	label: string
	primaryCategoryLevel: CategoryLevel

	paychexLink?: string
	paychexCouponCode?: string
}

export class AnalyticsOffer implements IAnalyticsOffer {
	brand
	category
	productCategory
	color
	coupon
	cta
	description
	endDate
	image_url
	index = searchIndex
	keywords
	level
	link
	name
	objectID
	product_id
	startDate
	tenantIDs
	terms
	title
	label
	primaryCategoryLevel
	paychexLink
	paychexCouponCode

	constructor(data: Offer) {
		this.brand = data.vendor.name
		this.productCategory = data.category
		this.color = data.vendor.color
		this.coupon = data.code
		this.cta = data.cta
		this.description = data.description
		this.endDate = data.endDate
		this.image_url = data.image
		this.keywords = data.vendor.keywords
		this.level = data.level
		this.link = data.link
		this.name = data.title
		this.objectID = data.objectID
		this.product_id = data.objectID
		this.startDate = data.startDate
		this.tenantIDs = data.tenantIDs
		this.terms = data.terms
		this.title = data.title
		this.label = `${data.vendor.name} - ${data.title}`
		this.primaryCategoryLevel = data.primaryCategoryLevel
		this.paychexLink = data.paychexLink
		this.paychexCouponCode = data.paychexCouponCode

		if (data.parentCategory) {
			this.category = data.parentCategory
		}
	}
}
