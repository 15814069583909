import { CssVarsThemeOptions } from '@mui/material/styles'
import { menuItem } from 'theme/css'

// ----------------------------------------------------------------------

export function menu(): CssVarsThemeOptions['components'] {
	return {
		MuiMenuItem: {
			styleOverrides: {
				root: ({ theme }) => ({
					...menuItem(theme),
				}),
			},
		},
	}
}
