import { differenceInDays } from 'date-fns'
import { BenefitPlanV2, NATIVE } from 'store/benefit-plans/types'

export const getVisitedBenefitPlans = (
	benefitPlans: BenefitPlanV2[],
	clickedOfferIds: Record<string, string>,
	today: Date = new Date(),
) => {
	if (benefitPlans.length === 0) return []

	// this function is used to determine if a user has visited a plan in the past 30 days
	const hasVisited = (plan: BenefitPlanV2): boolean => {
		if (plan.enrollmentMethod === NATIVE) return false
		const clickedDate = clickedOfferIds?.[plan.benefitPlanId]

		if (clickedDate) {
			// add 'Z' to let javascript know the date is UTC
			const daysSinceVisited = differenceInDays(today, new Date(`${clickedDate}Z`))

			return daysSinceVisited <= 30
		}

		return false
	}

	return benefitPlans.filter(hasVisited)
}
