// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { SimplinkAddressForm } from './types'

// https://benefitelections.int.corestream.com/swagger/index.html
export const queryKeys = {
	all: () => ['benefitelections'] as const,
	benefitElections: {
		all: () => [...queryKeys.all(), 'benefitelections'] as const,
		dependents: () => [...queryKeys.benefitElections.all(), 'dependents'] as const,
		getRecentElections: () => [...queryKeys.benefitElections.all(), 'getRecentElections'] as const,
		pets: () => [...queryKeys.benefitElections.all(), 'pets'] as const,
	},
	corestreamProducts: {
		all: () => [...queryKeys.all(), 'corestreamProducts'] as const,
		byId: (id: string) => [...queryKeys.corestreamProducts.all(), id] as const,
	},
	nationwidePet: {
		all: () => [...queryKeys.all(), 'nationwidePet'] as const,
		getBreeds: {
			all: () => [...queryKeys.nationwidePet.all(), 'breeds'] as const,
			bySpecies: (speciesCode: 'c' | 'f') => [...queryKeys.nationwidePet.getBreeds.all(), speciesCode] as const,
		},
	},
	rates: {
		all: () => [...queryKeys.all(), 'rates'] as const,
		coverageOptionsAndLowestRates: (params: { benefitPlanId; keyDate }) =>
			[...queryKeys.rates.all(), 'coverageOptionsAndLowestRates', params] as const,
	},

	trustmarkSimplink: {
		all: () => [...queryKeys.all(), 'trustmarksimplink'] as const,
		getEnrollment: () => [...queryKeys.trustmarkSimplink.all(), 'getEnrollment'] as const,
		getToken: (data: SimplinkAddressForm) => [...queryKeys.trustmarkSimplink.all(), 'getToken', data] as const,
		getWorkerAddress: () => [...queryKeys.trustmarkSimplink.all(), 'getWorkerAddress'] as const,
	},
}

export const mutationKeys = {
	benefitElections: {
		all: () => ['benefitelections'] as const,
		submit: () => [...mutationKeys.benefitElections.all(), 'submit'] as const,
	},
}
