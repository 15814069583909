import { QueryObserverOptions } from '@tanstack/react-query'
import { LTCEnrollmentResponse } from 'api/benefit-elections/types'
import { queryKeys } from 'api/enrollments/keys'
import { TrustmarkSimplinkService } from 'api/enrollments/services'

export const getEnrollmentsTrustmarkEnrollmentQuery = (options?: {
	enabled?: boolean
}): QueryObserverOptions<LTCEnrollmentResponse, unknown, LTCEnrollmentResponse> => {
	return {
		enabled: options?.enabled ?? true,
		queryFn: () => TrustmarkSimplinkService.getEnrollmentData(),
		queryKey: queryKeys.trustmarkSimplink.getEnrollment(),
	}
}
