import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { oidcSettings, setDynamicOidcSettings } from 'config/auth'
import { UserManager, UserManagerSettings } from 'oidc-client'
import {
	LOADING_USER,
	SESSION_TERMINATED,
	SILENT_RENEW_ERROR,
	USER_EXPIRING,
	USER_FOUND,
	createUserManager,
} from 'redux-oidc-js'
import IdentityService from 'services/identity.service'
import RecommendedOfferService from 'services/recommended-offers.service'
import { actions as siteInfoActions } from 'store/site/slice'
import { AppThunkAction } from 'store/util-types/types'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { LocalStore } from 'utils/utils'
import { IMyProfileUser, MyProfileUser, getNewProfileUser } from './selectors'
import { UserState, initialState } from './types'

function defineUserScopes(hasBenefits: string | undefined, hasAutoQuoting: string | undefined): string {
	let baseScopes = 'openid tenants_api profile offline_access'

	if (hasBenefits && hasBenefits === '1') {
		baseScopes += ' enrollments_api benefitElections_api billing_api userInsights_api'
	}

	if (hasAutoQuoting && hasAutoQuoting === '1') {
		baseScopes += ' autoquoting_api'
	}

	return baseScopes
}

function updateUserManager(
	primarySubdomain: string,
	organizationId: string,
	hasBenefits: string | undefined,
	hasAutoQuoting: string | undefined,
): UserManager {
	const acrValues: string = `tenant:${primarySubdomain}`
	const updatedOidcSettings: UserManagerSettings = setDynamicOidcSettings(oidcSettings(), {
		acr_values: acrValues,
		client_id: organizationId,
		// TODO : Might need to scope deduction history outside of enrollments...
		scope: defineUserScopes(hasBenefits, hasAutoQuoting),
	})

	return createUserManager(updatedOidcSettings)
}

const loadingUser = (state: UserState): void => {
	state.isLoadingUser = true
}

const userSlice = createSlice({
	extraReducers: {
		[siteInfoActions.getSiteInfoSuccess.type]: (state: UserState, action: PayloadAction<SiteInfoResponse>): void => {
			state.userManager = updateUserManager(
				action.payload.properties.primarySubdomain ?? '',
				action.payload.organizationId,
				String(action.payload.properties.hasVoluntaryBenefits ? 1 : ''),
				String(action.payload.properties.hasAutoQuoting ? 1 : ''),
			)
		},
		[LOADING_USER]: loadingUser,
		[SESSION_TERMINATED]: (state: UserState): void => {
			state.isLoadingUser = false
			state.isUserProfileLoaded = false
			state.userManager.removeUser()
			state.isLoggedIn = false
			state.accessTokenReady = false
			if (LocalStore.get('prevRoute')) {
				LocalStore.remove('prevRoute')
			}
		},
		[SILENT_RENEW_ERROR]: (state: UserState): void => {
			state.isUserProfileLoaded = false
			state.isLoggedIn = false
			state.accessTokenReady = false
			if (LocalStore.get('prevRoute')) {
				LocalStore.remove('prevRoute')
			}
		},
		[USER_EXPIRING]: (state: UserState): void => {
			state.isUserProfileLoaded = false
		},
		[USER_FOUND]: (state: UserState, action): void => {
			state.isLoadingUser = false
			state.userProfile = !state.isUserProfileLoaded ? new MyProfileUser(action.payload.profile) : state.userProfile
			state.isUserProfileLoaded = true
			state.accessTokenReady = true
			// don't set this here, we need to let this be set by our axios setup to
			// make sure we DON"T CALL APIS before we have a token assigned
			// state.isLoggedIn = true
		},
	},
	initialState,
	name: 'user',
	reducers: {
		loadingRecommendedOffers: (state: UserState): void => {
			state.isRecommendedOffersLoading = LOADING
		},
		loadingUser,
		resetUpdateStatus: (state: UserState) => {
			state.updatingProfileStatus = IDLE
		},
		setIsLoggedIn: (state: UserState, action: PayloadAction<boolean>): void => {
			state.isLoggedIn = action.payload
		},
		setRecommendedOfferIds: (state: UserState, action): void => {
			state.isRecommendedOffersLoading = SUCCESS
			state.recommendedOfferIds = action.payload
		},
		setRecommendedOffersError: (state: UserState): void => {
			state.isRecommendedOffersLoading = ERROR
		},
		updateUserInfoError: (state: UserState, action): void => {
			state.error = action.payload
			state.updatingProfileStatus = ERROR
		},
		updateUserInfoSuccess: (state: UserState, action): void => {
			state.isLoadingUser = false
			state.isUserProfileLoaded = true
			state.updatingProfileStatus = SUCCESS
			state.userProfile = {
				...getNewProfileUser(state.userProfile, action.payload),
				employeeId: state.userProfile.employeeId,
				isDiscountsOnly: state.userProfile.isDiscountsOnly,
			}
		},
		updateUserProfileDobSuccess: (state: UserState, action): void => {
			state.userProfile = {
				...state.userProfile,
				birthDate: action.payload,
			}
		},
		updatingUser: (state: UserState) => {
			state.updatingProfileStatus = LOADING
		},
	},
})

const { actions, reducer } = userSlice

export { actions, reducer as user }

export const fetchRecommendedUserOffers =
	(): AppThunkAction =>
	async (dispatch): Promise<Array<string> | undefined> => {
		try {
			dispatch(actions.loadingRecommendedOffers())
			const recommendedIds: Array<string> = await RecommendedOfferService.getRecommendedOfferIds()
			dispatch(actions.setRecommendedOfferIds(recommendedIds))

			return recommendedIds
		} catch (err) {
			dispatch(actions.setRecommendedOffersError())
		}
	}

export const updateUserProfile =
	(user: Partial<IMyProfileUser>, phoneNumberVerificationCode?: string): AppThunkAction =>
	async (dispatch): Promise<unknown> => {
		dispatch(actions.loadingUser)
		dispatch(actions.updatingUser)

		try {
			const updatedUser: unknown = await IdentityService.updateProfile(
				user as MyProfileUser,
				phoneNumberVerificationCode,
			)
			dispatch(actions.updateUserInfoSuccess(updatedUser))

			return updatedUser
		} catch (err) {
			dispatch(actions.updateUserInfoError(err))
		}
	}

export const updateUserProfileDob =
	(claimBirthDate: string, profileBirthDate: string): AppThunkAction =>
	async (dispatch): Promise<void> => {
		try {
			await IdentityService.updateProfileDob(claimBirthDate)
			dispatch(actions.updateUserProfileDobSuccess(profileBirthDate))
		} catch (err) {
			throw new Error(err as string)
		}
	}
