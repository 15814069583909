// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
export const queryKeys = {
	all: () => ['algolia'] as const,

	benefits: {
		all: () => [...queryKeys.all(), 'benefits'] as const,
		plan: ({ organizationId, benefitPlanId }: { organizationId: string; benefitPlanId: number }) =>
			[...queryKeys.benefits.all(), 'plan', organizationId, benefitPlanId] as const,
		search: (query: string) => [...queryKeys.benefits.all(), 'search', query] as const,
	},

	marketingContent: {
		all: () => [...queryKeys.all(), 'marketingContent'] as const,
		byId: (benefitPlanMarketingContentId) =>
			[...queryKeys.marketingContent.all(), benefitPlanMarketingContentId] as const,
	},
}
