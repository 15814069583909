import { Relationship } from 'api/benefit-elections/types'
import { Gender } from 'types/genders'
import { Dependent, PersonsState, Worker } from './types'

export const workerInitialState: Worker = {
	address: {
		city: '',
		state: '',
		streetOne: '',
		streetTwo: '',
		zipCode: '',
	},
	birthDate: '',
	email: '',
	firstName: '',
	gender: Gender.EMPTY,
	lastName: '',
	phoneNumber: '',
	socialSecurityNumber: '',
}

export const personsInitialState: PersonsState = {
	beneficiaries: [],
	dependents: [],
	worker: workerInitialState,
}

export const dependentInitialState: Dependent = {
	birthDate: '',
	firstName: '',
	gender: '',
	id: '',
	isActive: true,
	isOfAge: true,
	isTobacco: undefined,
	lastName: '',
	relationship: Relationship.SPOUSE,
	socialSecurityNumber: '',
}
