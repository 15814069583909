import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'
import { API_SUBDOMAIN, endpoints } from '../constants'
import { UserRecommendations } from '../types'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class UserRecommendationService {
	static getUserRecommendation(formSubmissionId?: string) {
		let url = urlBuilder.setEndpoint(endpoints.recommendations.getUserRcommendation()).url()

		if (formSubmissionId) url += `?formSubmissionId=${formSubmissionId}`

		return axios.get<UserRecommendations>(url)
	}

	static clearUserRecommendations() {
		return axios.delete(urlBuilder.setEndpoint(endpoints.recommendations.clearUserRcommendation()).url())
	}
}
