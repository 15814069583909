import { Snackbar as MuiSnackbar } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'

import { ERROR, INFO, SUCCESS, WARNING } from 'types/status-types'
import { Alert } from './alert'

interface SnackBarProps {
	anchorOrigin?: any
	onClose?: any
	onExited?: any
	message: string
	open: boolean
	shouldAutoHide?: boolean
	status?: typeof ERROR | typeof SUCCESS | typeof INFO | typeof WARNING
}

const Snackbar: FC<SnackBarProps> = (props: SnackBarProps) => {
	const autoHide = props.shouldAutoHide ? 5000 : null // 5s in ms
	const [localOpen, setLocalOpen] = useState(props.open ?? false)
	const handleClose = (_?: SyntheticEvent | Event, reason?: string): void | null => {
		if (props.onClose) props.onClose()
		if (reason === 'clickaway') return null
		setLocalOpen(false)
	}

	useEffect(() => {
		setLocalOpen(props.open)
	}, [props.open])

	return (
		<MuiSnackbar
			autoHideDuration={autoHide}
			open={localOpen}
			anchorOrigin={props.anchorOrigin}
			onClose={handleClose}
			TransitionProps={{ onExited: props.onExited }}
		>
			<div>
				<Alert onClose={handleClose} severity={props.status}>
					{props.message}
				</Alert>
			</div>
		</MuiSnackbar>
	)
}

Snackbar.defaultProps = {
	anchorOrigin: { horizontal: 'center', vertical: 'top' },
	shouldAutoHide: true,
}

export default Snackbar
