const ERROR = 'error'
const IDLE = 'idle'
const LOADING = 'loading'
const SUCCESS = 'success'
const INFO = 'info'
const WARNING = 'warning'

export { ERROR, IDLE, LOADING, SUCCESS, INFO, WARNING }

export type StatusTypes = typeof ERROR | typeof IDLE | typeof LOADING | typeof SUCCESS | typeof INFO | typeof WARNING
