import { useEffect, useState } from 'react'
import styled from 'styled-components'

import GenericModal from 'components/modals/generic-modal'

import './ie-modal.scss'

const Container = styled.div`
	margin: 2rem 0 0;
`

const StyledText = styled.div`
	font-size: 1rem;
	text-align: center;
	margin-bottom: 1rem;
`

export default function IEModal(): JSX.Element {
	const [showBanner, setShowBanner] = useState<boolean>(false)
	const hideModal = (): void => setShowBanner(false)
	const message = 'Internet Explorer is no longer supported, please download Chrome, Firefox, or Edge.'

	useEffect(() => {
		const isIE = /MSIE|Trident/.test(window.navigator.userAgent)
		if (isIE) {
			setShowBanner(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<GenericModal
			actions={<button onClick={hideModal}>OK</button>}
			maxWidth='xs'
			showModal={showBanner}
			onClose={hideModal}
			disableBackdropClick
			hideTitle
		>
			<Container>
				<StyledText>{message}</StyledText>
			</Container>
		</GenericModal>
	)
}
