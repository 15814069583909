import { Link, List, ListItemButton, ListItemText } from '@mui/material'
import { cx } from 'class-variance-authority'
import { events } from 'config/analytics'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useTrackEvent } from 'hooks/use-track-event'
import { useUserBenefitEligibility } from 'hooks/user-data/use-benefit-eligibility'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { User } from 'oidc-client'
import { ReactNode } from 'react'
import { routesMap } from 'routes/routes-map'
import { LinkBehavior } from 'theme/routing/LinkBehavior'
import { Tabs } from '../../types'
import mobileStyles from '../mobile-menu/mobile-menu.module.scss'
import desktopStyles from './navigation-tabs.module.css'

/**
 * Theses are the default tabs that will show regardless of if a user is logged in or not
 * These tabs will not call any hooks that require user authentication
 */
export const DefaultTabs = (props: {
	activeTab?: any
	callback?: () => void
	children?: ReactNode
	mobileView?: boolean
	user: User | undefined
}) => {
	const isBenefitsEligible = useUserBenefitEligibility()
	const { b4b_10023_custom_event_migration } = useFlags()
	const trackEvent = useTrackEvent()
	const track = useTrackDetailedPageEvent()
	const styles = props.mobileView ? mobileStyles : desktopStyles

	const handleHomeClick = (): void => {
		if (b4b_10023_custom_event_migration) {
			track({
				actionType: 'click',
				elementName: 'mega menu home cta',
				elementType: 'button',
			})
		} else {
			trackEvent(events.header.homeClicked)
		}
		if (props.callback) props.callback()
	}
	const handleHelpClick = (): void => {
		if (b4b_10023_custom_event_migration) {
			track({
				actionType: 'click',
				elementName: 'mega menu help cta',
				elementType: 'button',
			})
		} else {
			trackEvent(events.header.helpClicked)
		}
		if (props.callback) props.callback()
	}

	if (props.mobileView) {
		return (
			<List>
				<li>
					<ListItemButton component={LinkBehavior} href={routesMap.home} onClick={handleHomeClick}>
						<ListItemText
							primary='Home'
							primaryTypographyProps={{
								component: 'p',
								variant: 'h2',
							}}
							id='mobile-home-menu'
						/>
					</ListItemButton>
				</li>
				{props.children}
				<li>
					<ListItemButton component={LinkBehavior} href={routesMap.faq} onClick={handleHelpClick}>
						<ListItemText
							primary='Help'
							primaryTypographyProps={{
								component: 'p',
								variant: 'h2',
							}}
							id='mobile-help-menu'
						/>
					</ListItemButton>
				</li>
			</List>
		)
	}

	return (
		<nav className={cx(styles['container'], { [styles['has-user']]: props.user })}>
			{isBenefitsEligible && (
				<Link
					id='home'
					href={props.user ? routesMap.home : routesMap.start}
					onClick={handleHomeClick}
					className={cx(styles['tab'], {
						[styles['tab-active']]: props.activeTab === Tabs.Home,
					})}
				>
					Home
				</Link>
			)}
			{props.children}
			<Link
				id='help'
				onClick={handleHelpClick}
				className={cx(styles['tab'], {
					[styles['tab-active']]: props.activeTab === Tabs.Help,
				})}
				href={routesMap.faq}
			>
				Help
			</Link>
		</nav>
	)
}
