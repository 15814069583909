import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RateRequestBoolVals } from 'api/benefit-elections/types'
import { CartItem } from 'store/cart/types/cartItemTypes'
import { BenefitEnrollmentState, initialState } from './types'
import { updateCartItemProperties } from './updateCartItemProperties'

const benefitEnrollmentSlice = createSlice({
	extraReducers: {},
	initialState,
	name: 'benefit-enrollment',
	reducers: {
		answeredQuestions: (state, action: PayloadAction<boolean>) => {
			state.questions.answered = action.payload
		},
		clear: (state) => {
			return {
				...initialState,
				shared: state.shared,
			}
		},
		clearRate: (state) => {
			if (state.benefit) {
				state.benefit.coverage = ''
				state.benefit.tierId = ''
				state.rate.selected = false
			}
		},
		resetBenefitEnrollmentForm: (state) => {
			if (state.benefit) {
				// Rates
				state.benefit.coverage = ''
				state.benefit.tierId = ''

				// Terms
				state.benefit.hasAcceptedTermsAndConditions = false

				//Disclaimer
				state.benefit.hasAcceptedAdditionalDisclaimer = false

				// Tiers
				state.benefit.tier = undefined

				// Pet
				state.benefit.confirmedDisclaimer = ''
				state.benefit.pets = []
				state.benefit.postalCode = undefined
			}

			// Rates
			state.rate.selected = false

			// Questions
			state.questions.answered = false

			// Meta
			state.meta.showAllRequiredQuestions = false
		},
		selectedRate: (state, action: PayloadAction<boolean>) => {
			state.rate.selected = action.payload
		},
		/**
		 * updates provided values in the benefit object
		 *
		 * keys that are not found in the payload are ignored - enables multi-step segmented updates
		 *
		 * @param action
		 */
		updateBenefit: (state, action: PayloadAction<Partial<CartItem>>) => {
			// create state object if it doesn't exist
			if (!state.benefit) state.benefit = {} as CartItem

			// wipe the lookupKey if none is sent
			if (!action.payload.lookupKey) {
				state.benefit.lookupKey = undefined
			}

			updateCartItemProperties(state, 'benefit', action.payload)
		},
		updateMeta: (state, action: PayloadAction<Partial<BenefitEnrollmentState['meta']>>) => {
			state.meta = { ...state.meta, ...action.payload }
		},
		updateRatesParams: (state, action: PayloadAction<Partial<RateRequestBoolVals>>) => {
			state.rate.params = { ...state.rate.params, ...action.payload }
		},
		/**
		 * share values across benefits by including them in the shared object
		 */
		updateShared: (state, action: PayloadAction<Partial<CartItem>>) => {
			updateCartItemProperties(state, 'shared', action.payload)
		},
	},
})

const { actions, reducer } = benefitEnrollmentSlice
export { reducer as benefitEnrollment }

export function _getType(value: any) {
	if (value === null) {
		return 'null'
	} else if (Array.isArray(value)) {
		return 'array'
	} else if (value instanceof Date) {
		return 'date'
	} else {
		return typeof value
	}
}

export const beActions = {
	...actions,
	update,
}

/**
 * update benefit and shared properties in one action
 */
function update(payload: Partial<CartItem>) {
	return (dispatch) => {
		dispatch(actions.updateBenefit(payload))
		dispatch(actions.updateShared(payload))
	}
}
