const runCacheBuster = async (): Promise<void> => {
	const currentVersion = localStorage.getItem('appVersion')
	const showLogs = true

	const log = (message, isError = false) => {
		if (showLogs) {
			message = `[use-cache-buster] ${message}`
			if (isError) {
				// eslint-disable-next-line no-console
				console.error(message)
			} else {
				// eslint-disable-next-line no-console
				console.log(message)
			}
		}
	}
	const isThereNewVersion = (metaVersion, currentVersion) => {
		if (!currentVersion) {
			return false
		}

		if (metaVersion !== currentVersion) {
			return true
		}

		return false
	}

	const refreshCacheAndReload = async (newVersion: string) => {
		try {
			localStorage.setItem('appVersion', newVersion)

			window.location.hash = `#${new Date().getTime()}`
			window.location.reload()
		} catch (error) {
			log('An error occurred while deleting the cache.', true)
			log(error, true)
		}
	}

	try {
		// Try to prevent browsers from caching the .json file by adding a random query string
		const res = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
		const { version: metaVersion } = await res.json()
		const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion)
		if (shouldForceRefresh) {
			log(`There is a new version: ${metaVersion}. Should force refresh.`)
			refreshCacheAndReload(metaVersion)
		} else {
			log('There is no new version. No cache refresh needed.')

			localStorage.setItem('appVersion', metaVersion)
		}
	} catch (error) {
		log('An error occurred while checking cache status.', true)
		log(error, true)
	}
}

export { runCacheBuster }
