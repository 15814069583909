import { accountRoutes, benefitRoutes, routesMap } from 'routes/routes-map'
import { pageNames } from './useCaptureEvent'

export const PageNameSpecMap: Record<string, pageNames> = {
	// accounts routes
	[accountRoutes.deductions]: 'account deductions',
	[accountRoutes.enrollments]: 'account enrollments',
	[accountRoutes.profile]: 'account profile',
	[routesMap.myProfile]: 'account profile legacy',

	// login routes
	[routesMap.adminLogin]: 'login',
	[routesMap.login]: 'login',
	[routesMap.logout]: 'login',
	[routesMap.register]: 'register',
	[routesMap.forgotPassword]: 'forgot password',
	[routesMap.saml]: 'saml',
	[routesMap.callback]: 'callback',
	[routesMap.workerRedirect]: 'worker redirect',

	// legacy benefits pages
	[routesMap.benefitCategory]: 'plan category legacy',
	[routesMap.benefitPlan]: 'plan legacy',

	// auto
	[routesMap.autoQuote]: 'auto',

	// legacy wizard
	[routesMap.benefitsEnroll]: 'old wizard',
	[routesMap.benefitsEnrollInfo]: 'old wizard',
	[routesMap.benefitsEnrollPlan]: 'old wizard',
	[routesMap.benefitsEnrollConfirm]: 'old wizard',
	[routesMap.confirmation]: 'old wizard confirmation',

	// new wizard
	[benefitRoutes.wizard.base]: 'wizard',
	[benefitRoutes.wizard.intro]: 'wizard intro',
	[benefitRoutes.wizard.moreCoverage]: 'more coverage',

	// random pages
	[routesMap.comingSoon]: 'coming soon',
	[routesMap.faq]: 'faq',
	[routesMap.dataUsePolicy]: 'data use policy',
	[routesMap.error]: 'error',
	[routesMap.terms]: 'terms',

	// offers
	[routesMap.deals.base]: 'deals',

	// home routes
	[routesMap.home]: 'home',
	[routesMap.start]: 'home',

	// legacy integrations
	[routesMap.ltc]: 'trustmark-simplink',

	// search
	[routesMap.search]: 'search',

	// themes
	[routesMap.themes.base]: 'themes',
}
