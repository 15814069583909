/**
 * @function isEqualViaPropertyChecks - Compares two objects to see if they are equal via a lookup of properties
 * @param propertyKeyLookup - A set of property keys to lookup on the object.
 * If all properties are defined and equal, the objects are considered equal.
 * @returns A function that takes two objects and returns a boolean indicating if they are equal
 */
export function isEqualViaPropertyChecks<T>(propertyKeyLookup: string[][]): (i: T, givenKeys: T) => boolean {
	return (a, b) => {
		let match = false

		// iterate over each set of properties to see if each entry matches
		propertyKeyLookup.forEach((keyLookup) => {
			// for a match, all properties must be defined and equal
			if (keyLookup.every((k) => !!a[k] && !!b[k] && a[k] === b[k])) {
				match = true
			}
		})

		return match
	}
}
