export function camelToSnakeCase(str: string) {
	const trimmedStr = str.trim()
	const singleSpace = trimmedStr.replace(/\s+/g, '_')
	const snakeCase = singleSpace.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
	const trimmedSnakeCase = snakeCase.replace(/^_+|_+$/g, '')

	// remove duplicates
	const noDuplicates = trimmedSnakeCase.replace(/_+/g, '_')

	return noDuplicates
}
