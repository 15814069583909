import { createContext, useContext } from 'react'
import { EnrollmentJourneyState } from '../feature/home/hooks'

export interface UserEnrollmentJourneyContextState {
	// useful for analytics and components that need to know page variations
	state: EnrollmentJourneyState
}

// do not export this - use the provider component below
const UserEnrollmentJourneyContext = createContext({} as UserEnrollmentJourneyContextState)

export type UserEnrollmentJourneyContextProviderProps = {
	children: React.ReactNode
	values: UserEnrollmentJourneyContextState
}

export const UserEnrollmentJourneyContextProvider = ({
	children,
	values,
}: UserEnrollmentJourneyContextProviderProps) => {
	return <UserEnrollmentJourneyContext.Provider value={values}>{children}</UserEnrollmentJourneyContext.Provider>
}

export const useUserEnrollmentJourneyContext = () => {
	const context = useContext(UserEnrollmentJourneyContext)
	if (!context) {
		throw new Error(
			'useUserEnrollmentJourneyContext must be used within a <UserEnrollmentJourneyContextProvider> component',
		)
	}

	return context
}
