import { RequiredTypes, _useCaptureEvent } from './useCaptureEvent'

interface TrackOffer extends RequiredTypes {
	offerType: 'discounts' | 'benefits'
	offerBrand: string // hotwire, truecar, ticketsatwork, ge appliances, etc.
	offerName: string // “10% off!”, “save on flights + hotels”, “preferred pricing”, etc.
	discountOfferId: string // unique identifier for the offer
	elementDetails?: string
}

/**
 * Requires the offer properties
 */
export const useTrackOffer = () => {
	const capture = _useCaptureEvent()

	return (eventData: TrackOffer) => capture(eventData)
}
