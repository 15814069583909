import { useAppSelector } from 'hooks/redux'
import { useCallback } from 'react'
import { userSelector } from 'store/user/selectors'

type HasRoleFunction = (roleToCheck: string) => boolean

export function useRoleCheck(): { hasRole: HasRoleFunction } {
	const user = useAppSelector(userSelector)

	const hasRole = useCallback(
		(roleToCheck: string): boolean => {
			const role = user?.profile?.role ?? ''

			return role.includes(roleToCheck)
		},
		[user],
	)

	return { hasRole }
}
