import { useMemo } from 'react'
import { Offer } from 'types/offer'
import { useIsPaychex } from 'utils/url'

/**
 * Retrieves the referral code for the selected Offer
 */
export const useOfferCode = (offer: Offer): string | null => {
	const isPaychex = useIsPaychex()

	return useMemo(() => {
		let code = offer.code

		// only replace link when present && when subdomain is paychex
		if (isPaychex && offer.paychexCouponCode) {
			code = offer.paychexCouponCode
		}

		return code
	}, [isPaychex, offer.code, offer.paychexCouponCode])
}
