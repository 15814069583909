import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function stepper(): CssVarsThemeOptions['components'] {
	return {
		MuiStepper: {
			styleOverrides: {
				root: {
					whiteSpace: 'normal',
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				line: {
					backgroundColor: 'var(--color-gray-2)',
					border: 0,
					borderRadius: 1,
					height: 3,
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				labelContainer: {
					color: 'var(--neutral-neutral-50)',
				},
				active: {},
				label: {},
			},
		},
		MuiStep: {
			styleOverrides: {
				root: {
					'& svg': {
						border: '2px solid black',
						borderRadius: '50%',
						fill: 'var(--color-gray-9)',
						height: '2rem',
						width: '2rem',
					},
					"& svg[class*='active']": {
						fill: 'var(--color-alert)',
					},
					"& svg[class*='completed']": {
						background: 'black',
					},

					'& text': {
						fill: 'black',
						fontSize: '1rem',
						fontWeight: '600',
					},
				},
			},
		},
	}
}
