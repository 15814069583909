import { createContext, useContext } from 'react'

export interface PublicContextState {
	// This controls the activation of the new styles for the login/register pages
}

export const PublicContext = createContext({} as PublicContextState)

export const usePublicContext = () => {
	const context = useContext(PublicContext)
	if (!context) {
		throw new Error('usePublicContext must be used within a <usePublicContext.Provider> component')
	}

	return context
}
