import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { Tier } from './benefit-plan'
import { PartialDependentList } from './dependent'
import { ElectionPetData } from './pet'

// IMPORTANT NOTE:
//   bpId ==> the plans benefit id
//   benefitPlanId ==> the program id
export type UserEnrollmentRes = {
	bpId?: number
	benefitPlanId: number
	benefitPlanImageUrl: string
	benefitProviderName: string
	carrierName: string
	collectDependents: boolean
	collectDisabled: boolean
	collectSSN: boolean
	coverage: string
	coverageTitle: string
	declined?: boolean
	dependents: PartialDependentList
	history?: UserEnrollmentRes[]
	isModifiedByLtdAutoPend?: boolean
	isNative: boolean
	logoUrl: string
	nonNativeLinkUrl?: string
	petData?: ElectionPetData
	planEffectiveDate: string
	ppdsProviderCode: string
	ppdsProductCode: string
	programEnrollmentId: string
	profileId: number
	programId: number
	programTitle: string
	rate: string
	rateFrequency: EnrollmentRateFrequencyNative | EnrollmentRateFrequencyNonNative
	source: EnrollmentSource | null
	statusCode: EnrollmentStatus
	submittedOn: string
	tier: Tier
	tierTitle: string

	/**
	 * These are only used for migrated DNN STD Plans
	 */
	totalPrice?: string
	totalCoverageText?: string
}

export type RecentUserEnrollmentRes = UserEnrollmentRes & {
	confirmationNumber: string
}

export enum EnrollmentSource {
	WRPV2 = 'WRPV2',
}

export enum EnrollmentRateFrequencyNative {
	MONTH = 'month',
	BIWEEK = 'bi-weekly',
	WEEK = 'weekly',
	SEMIMONTH = 'semi-monthly',
}
export enum EnrollmentRateFrequencyNonNative {
	DEDUCTIONPERIOD = 'deductionperiod',
	MONTH = 'month',
	UNKNOWN = 'unknown',
	ANNUAL = 'annual',
}
