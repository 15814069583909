import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SiteInfoResponse } from 'types/tenant-site-types'
import { SiteState, initialState } from './types'

const mergeProperties = (siteInfo: SiteInfoResponse): SiteInfoResponse => ({
	...siteInfo,
	properties: siteInfo.properties,
})
export const siteSlice = createSlice({
	initialState,
	name: 'site',
	reducers: {
		getSiteInfoError: (state: SiteState, action: PayloadAction<Error>): void => {
			state.siteError = action.payload
			state.isSiteLoading = false
		},
		getSiteInfoSuccess: (state: SiteState, action: PayloadAction<SiteInfoResponse>): void => {
			state.siteError = null
			state.isSiteLoading = false
			state.siteInfo = mergeProperties(action.payload)
		},
	},
})

// Extract the action creators object and the reducer
const { actions, reducer } = siteSlice
// Export the reducer and actions
export { actions, reducer as site }
