import { Close } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, CardMedia, IconButton, Modal } from '@mui/material'
import { WysiwigContent } from 'components/wysiwig-content/wysiwig-content'
import { useTrackOffer } from 'hooks/analytics'
import { Fragment, ReactElement, useCallback } from 'react'
import { match } from 'ts-pattern'
import { useOfferCode, useOfferRedeemHandler } from '../hooks'
import { OfferCodeButton } from './components'
import styles from './offer-modal.module.css'
import { OfferModalProps } from './types'

const OfferModal = (props: OfferModalProps) => {
	const { showModal, hideModal, trackSearchOfferClicked } = props
	const track = useTrackOffer()

	const offerCode = useOfferCode(props.offer)

	const actionButton: ReactElement = (
		<IconButton onClick={hideModal} className={styles['button']} aria-label='settings' size='large'>
			<Close className={styles['icon']} />
		</IconButton>
	)

	function modalLinkListener(e): void {
		const url = e.target.getAttribute('href')
		const win = window.open(url, '_blank')
		win?.focus()
		e.preventDefault()
	}

	const modalRef = useCallback((node) => {
		if (node !== null) {
			const modalLinks = node.querySelectorAll('.js-modal-content a:not([href^=mailto])')
			modalLinks.forEach((mL) => {
				mL.addEventListener('click', modalLinkListener)
			})
		}
	}, [])

	const onModalClose = useCallback(() => {
		const modalLinks = document.querySelectorAll('.js-modal-content a:not([href^=mailto])')
		modalLinks.forEach((mL) => {
			mL.removeEventListener('click', modalLinkListener)
		})
	}, [])

	const onOfferRedeem = useOfferRedeemHandler(props.offer, 'Offer Modal')

	const onCtaClick = useCallback(
		(e) => {
			if (trackSearchOfferClicked) trackSearchOfferClicked('conversion')
			onOfferRedeem(e)
		},
		[onOfferRedeem, trackSearchOfferClicked],
	)

	if (!showModal) return <Fragment />

	return (
		<Modal
			disableAutoFocus
			disableEnforceFocus
			className={styles['container']}
			open={showModal}
			ref={modalRef}
			onClose={onModalClose}
		>
			<Card square={true}>
				<CardHeader
					action={actionButton}
					avatar={<CardMedia className={styles['logo']} image={props.offer.vendor.logo} />}
					className={styles['header']}
					classes={{
						action: styles['action'],
						avatar: styles['avatar'],
					}}
				/>
				<CardContent className={styles['card']}>
					<div className={styles['content']}>
						<div className={styles['main']}>
							<div className={styles['top-content']}>
								<p>{props.offer.category}</p>
								<h2 className={styles['title']}>{props.offer.title}</h2>
								<p className={styles['body']}>{props.offer?.description ?? ''}</p>
								{match({ hasCode: !!offerCode })
									.when(
										({ hasCode }) => hasCode === false,
										() => (
											<Button className={styles['content-button']} onClick={onCtaClick}>
												{props.offer.cta}
											</Button>
										),
									)
									.when(
										({ hasCode }) => hasCode === true,
										() => (
											<OfferCodeButton
												className={styles['content-button']}
												offer={props.offer}
												trackSearchOfferClicked={trackSearchOfferClicked}
											/>
										),
									)
									.otherwise(() => (
										<Button className={styles['content-button']} onClick={onCtaClick}>
											{props.offer.cta}
										</Button>
									))}
							</div>

							<WysiwigContent
								className='offer-modal-bottom-content js-modal-content'
								content={props.offer.terms}
								onLinkClick={(url) => {
									track({
										actionType: 'click',
										discountOfferId: props.offer.objectID,
										elementDetails: url,
										elementName: 'offer terms',
										elementType: 'hyperlink',
										offerBrand: props.offer.vendor.name,
										offerName: props.offer.title,
										offerType: 'discounts', // NOTE - offer modal is only used for discounts now
										pageName: '', // TODO pull this from the new 'analytics page context'
									})
								}}
							/>
							<WysiwigContent
								className='offer-modal-bottom-content js-modal-content'
								content={props.offer.vendor?.description ?? ''}
								onLinkClick={(url) => {
									track({
										actionType: 'click',
										discountOfferId: props.offer.objectID,
										elementDetails: url,
										elementName: 'offer description',
										elementType: 'hyperlink',
										offerBrand: props.offer.vendor.name,
										offerName: props.offer.title,
										offerType: 'discounts', // NOTE - offer modal is only used for discounts now
										pageName: '', // TODO pull this from the new 'analytics page context'
									})
								}}
							/>
						</div>
					</div>
				</CardContent>
			</Card>
		</Modal>
	)
}

export default OfferModal
