/* eslint-disable @typescript-eslint/member-ordering */
/**
 * Refer to https://github.com/angel-rs/css-color-filter-generator/tree/master
 * for more information on how this works
 * Some of the code is adapted from the above repo, and then modified to fit our needs
 */
function hexToRgb(hex: string): number[] | null {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b)

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

class Color {
	private r = 0
	private g = 0
	private b = 0

	constructor(r: number, g: number, b: number) {
		this.set(r, g, b)
	}

	set(r: number, g: number, b: number): void {
		this.r = this.clamp(r)
		this.g = this.clamp(g)
		this.b = this.clamp(b)
	}

	hsl(): { h: number; s: number; l: number } {
		const r = this.r / 255
		const g = this.g / 255
		const b = this.b / 255
		const max = Math.max(r, g, b)
		const min = Math.min(r, g, b)
		let h = 0
		let s = 0
		const l = (max + min) / 2

		if (max !== min) {
			const d = max - min
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0)
					break
				case g:
					h = (b - r) / d + 2
					break
				case b:
					h = (r - g) / d + 4
					break
			}
			h /= 6
		}

		return {
			h: h * 100,
			l: l * 100,
			s: s * 100,
		}
	}

	private clamp(value: number): number {
		return Math.min(255, Math.max(0, value))
	}

	// Color manipulation methods
	invert(value = 1): void {
		this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255)
		this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255)
		this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255)
	}

	sepia(value = 1): void {
		this.multiply([
			0.393 + 0.607 * (1 - value),
			0.769 - 0.769 * (1 - value),
			0.189 - 0.189 * (1 - value),
			0.349 - 0.349 * (1 - value),
			0.686 + 0.314 * (1 - value),
			0.168 - 0.168 * (1 - value),
			0.272 - 0.272 * (1 - value),
			0.534 - 0.534 * (1 - value),
			0.131 + 0.869 * (1 - value),
		])
	}

	saturate(value = 1): void {
		this.multiply([
			0.213 + 0.787 * value,
			0.715 - 0.715 * value,
			0.072 - 0.072 * value,
			0.213 - 0.213 * value,
			0.715 + 0.285 * value,
			0.072 - 0.072 * value,
			0.213 - 0.213 * value,
			0.715 - 0.715 * value,
			0.072 + 0.928 * value,
		])
	}

	hueRotate(angle = 0): void {
		angle = (angle / 180) * Math.PI
		const sin = Math.sin(angle)
		const cos = Math.cos(angle)

		this.multiply([
			0.213 + cos * 0.787 - sin * 0.213,
			0.715 - cos * 0.715 - sin * 0.715,
			0.072 - cos * 0.072 + sin * 0.928,
			0.213 - cos * 0.213 + sin * 0.143,
			0.715 + cos * 0.285 + sin * 0.14,
			0.072 - cos * 0.072 - sin * 0.283,
			0.213 - cos * 0.213 - sin * 0.787,
			0.715 - cos * 0.715 + sin * 0.715,
			0.072 + cos * 0.928 + sin * 0.072,
		])
	}

	brightness(value = 1): void {
		this.linear(value)
	}

	contrast(value = 1): void {
		this.linear(value, -(0.5 * value) + 0.5)
	}

	private linear(slope = 1, intercept = 0): void {
		this.r = this.clamp(this.r * slope + intercept * 255)
		this.g = this.clamp(this.g * slope + intercept * 255)
		this.b = this.clamp(this.b * slope + intercept * 255)
	}

	private multiply(matrix: number[]): void {
		const newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2])
		const newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5])
		const newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8])
		this.r = newR
		this.g = newG
		this.b = newB
	}
}

class Solver {
	private target: Color
	private targetHSL: { h: number; s: number; l: number }
	private reusedColor: Color

	constructor(target: Color) {
		this.target = target
		this.targetHSL = target.hsl()
		this.reusedColor = new Color(0, 0, 0)
	}

	solve(): string {
		let result = this.solveNarrow(this.solveWide())
		let attempts = 0
		const lossThreshold = 1.0
		const attemptsThreshold = 50
		/**
		 * If the loss is greater than the loss threshold
		 * try to narrow it down(if attempts are less than the attempts threshold)
		 */
		while (result.loss > lossThreshold && attempts < attemptsThreshold) {
			result = this.solveNarrow(this.solveWide())
			attempts++
		}

		return result.loss > lossThreshold ? '' : this.raw(result.values)
	}

	private solveWide(): { values: number[]; loss: number } {
		const a = [60, 180, 18000, 600, 1.2, 1.2]
		let best = { loss: Infinity, values: [] as number[] }

		for (let i = 0; best.loss > 25 && i < 3; i++) {
			const result = this.spsa(5, a, 15, [50, 20, 3750, 50, 100, 100], 1000)
			if (result.loss < best.loss) {
				best = result
			}
		}

		return best
	}

	private solveNarrow(wide: { values: number[]; loss: number }): { values: number[]; loss: number } {
		const A1 = wide.loss + 1
		const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1]

		return this.spsa(wide.loss, a, 2, wide.values, 500)
	}

	private spsa(A: number, a: number[], c: number, values: number[], iters: number): { values: number[]; loss: number } {
		let best = values.slice(0)
		let bestLoss = Infinity

		for (let k = 0; k < iters; k++) {
			const ck = c / Math.pow(k + 1, 0.16666666666666666)
			const deltas = Array(6)
				.fill(0)
				.map(() => (Math.random() > 0.5 ? 1 : -1))

			const highArgs = values.map((v, i) => v + ck * deltas[i])
			const lowArgs = values.map((v, i) => v - ck * deltas[i])

			const lossDiff = this.loss(highArgs) - this.loss(lowArgs)

			for (let i = 0; i < 6; i++) {
				const g = (lossDiff / (2 * ck)) * deltas[i]
				const ak = a[i] / Math.pow(A + k + 1, 1)
				values[i] = this.fixValue(values[i] - ak * g, i)
			}

			const loss = this.loss(values)
			if (loss < bestLoss) {
				best = values.slice(0)
				bestLoss = loss
			}
		}

		return { loss: bestLoss, values: best }
	}

	private fixValue(value: number, idx: number): number {
		let max = 100
		if (idx === 2) max = 7500
		else if (idx === 4 || idx === 5) max = 200

		if (idx === 3) {
			if (value > max) value %= max
			else if (value < 0) value = max + (value % max)
		} else {
			value = Math.min(max, Math.max(0, value))
		}

		return value
	}

	private loss(filters: number[]): number {
		const color = this.reusedColor
		color.set(0, 0, 0)

		color.invert(filters[0] / 100)
		color.sepia(filters[1] / 100)
		color.saturate(filters[2] / 100)
		color.hueRotate(filters[3] * 3.6)
		color.brightness(filters[4] / 100)
		color.contrast(filters[5] / 100)

		const colorHSL = color.hsl()

		return (
			Math.abs((color as any).r - (this.target as any).r) +
			Math.abs((color as any).g - (this.target as any).g) +
			Math.abs((color as any).b - (this.target as any).b) +
			Math.abs(colorHSL.h - this.targetHSL.h) +
			Math.abs(colorHSL.s - this.targetHSL.s) +
			Math.abs(colorHSL.l - this.targetHSL.l)
		)
	}

	private raw(filters: number[]): string {
		const fmt = (idx: number, multiplier = 1) => Math.round(filters[idx] * multiplier)

		return `brightness(0) saturate(100%) invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(
			3,
			3.6,
		)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`
	}
}

export function hexToFilter(hex: string): string {
	const rgb = hexToRgb(hex)
	if (!rgb) return ''

	const color = new Color(rgb[0], rgb[1], rgb[2])
	const solver = new Solver(color)

	return solver.solve()
}
