import BlockUI from 'components/block-ui'
import { PostLoginFeatureRouter } from 'feature/post-login-feature-router/post-login-feature-router'
import { useTrackLoginEvent } from 'hooks/analytics/login/useTrackLoginEvent'
import { useAppSelector } from 'hooks/redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { siteInfoSelector } from 'store/site/selectors'
import { MyProfileUser, isLoggedInSelector, myProfileSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { LocalStore } from 'utils/utils'
import { routesMap } from './routes-map'

const ProtectedRoute = (): JSX.Element => {
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)
	const location = useLocation()
	const { b4b_marketingcontent_preview } = useFlags()
	const siteInfo: SiteInfoResponse = useAppSelector(siteInfoSelector)
	const trackSSOLogin = useTrackLoginEvent()
	// Pseudo Loading
	const [loading, setLoading] = useState<boolean>(true)

	if (!isLoggedIn) {
		LocalStore.set('prevRoute', location.pathname + location.search)
		const isPreview = b4b_marketingcontent_preview && location.search.includes('marketingContentId')

		if (siteInfo.properties.ssoEnabled && siteInfo.properties.ssoAuthUrl) {
			if (!isPreview) {
				trackSSOLogin({
					actionType: 'on leave',
					elementName: 'sso-login',
					elementType: 'page',
					pageName: 'login',
				})
			}
			setTimeout(() => setLoading(false), 2000) // 2 second pseudo load

			if (!loading) window.location.href = isPreview ? routesMap.adminLogin : siteInfo.properties.ssoAuthUrl

			return <BlockUI shouldBlock showSpinner message='Redirecting you to login page...' />
		}
		if (isPreview) return <Navigate to={routesMap.adminLogin} replace />

		return <Navigate to='/' replace />
	} else {
		LocalStore.remove('prevRoute')

		teardownUrlStorage()
	}

	if (!siteInfo.properties.hasVoluntaryBenefits || userProfile.isDiscountsOnly) {
		return <Outlet />
	}

	return (
		<PostLoginFeatureRouter>
			<Outlet />
		</PostLoginFeatureRouter>
	)
}

export default ProtectedRoute

function teardownUrlStorage() {
	LocalStore.remove('returnUrl')
	LocalStore.remove('isImpersonation')
	LocalStore.remove('query')
}
