import { MegaMenuItem } from 'components/app-header/components/mega-menu/types'
import { events } from 'config/analytics'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { selectMemberExclusiveFacets, selectNonExclusiveFacets } from 'store/search/selectors'
import { getDealCategorySearchUrl } from 'utils/url'
import { routesMap } from '../routes/routes-map'
import { useTrackDetailedPageEvent } from './analytics'
import { useTrackEvent } from './use-track-event'

const useMegaMenuDeals = () => {
	const { b4b_10023_custom_event_migration } = useFlags()

	const memberExclusiveOfferFacets = useSelector(selectMemberExclusiveFacets)
	const nonExclusiveOfferFacets = useSelector(selectNonExclusiveFacets)
	const trackEvent = useTrackEvent()
	const track = useTrackDetailedPageEvent()

	const formatDealsFacetIntoMenuItem = (facet): MegaMenuItem => {
		return {
			href: getDealCategorySearchUrl(facet.value),
			label: facet.value,
			onClick: () => {
				if (b4b_10023_custom_event_migration) {
					track({
						actionType: 'click',
						elementDetails: facet.value,
						elementName: 'mega menu deals category cta',
						elementType: 'button',
					})
				} else {
					trackEvent(events.megaMenu.deals.category, { productCategory: facet.value })
				}
			},
		}
	}
	const dealsMenuItems = nonExclusiveOfferFacets.map(formatDealsFacetIntoMenuItem)
	const exclusiveDealsMenuItems = memberExclusiveOfferFacets.map(formatDealsFacetIntoMenuItem)

	const supplementalDealsItems: MegaMenuItem[] = [
		{
			href: routesMap.deals.base,
			label: 'Deals Home',
			onClick: () => {
				if (b4b_10023_custom_event_migration) {
					track({
						actionType: 'click',
						elementName: 'mega menu deals home cta',
						elementType: 'button',
					})
				} else {
					trackEvent(events.megaMenu.deals.dealsHome)
				}
			},
		},
		{
			href: routesMap.deals.travel.path,
			label: 'Exclusive Travel Deals',
			onClick: () => {
				track({
					actionType: 'click',
					elementDetails: 'mega menu item',
					elementName: 'Exclusive Travel Deals',
					elementType: 'hyperlink',
				})
			},
		},
		...exclusiveDealsMenuItems,
	]

	return { dealsMenuItems, supplementalDealsItems }
}

export default useMegaMenuDeals
