import { tabClasses } from '@mui/material'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function tabs(): CssVarsThemeOptions['components'] {
	return {
		MuiTab: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&:not(:last-of-type)': {
						marginRight: theme.spacing(3),
						[theme.breakpoints.up('sm')]: {
							marginRight: theme.spacing(5),
						},
					},
					[`&:not(.${tabClasses.selected})`]: {
						color: theme.palette.text.secondary,
					},
					fontWeight: theme.typography.fontWeightMedium,
					minHeight: 48,
					minWidth: 48,
					opacity: 1,
					padding: 0,
				}),
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: ({ theme }) => ({
					backgroundColor: theme.vars.palette.text.primary,
				}),
				scrollButtons: {
					borderRadius: '50%',
					width: 48,
				},
			},
		},
	}
}
