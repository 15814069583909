import { radioClasses } from '@mui/material/Radio'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function radio(): CssVarsThemeOptions['components'] {
	return {
		// CHECKBOX, RADIO, SWITCH
		MuiFormControlLabel: {
			styleOverrides: {
				label: ({ theme }) => ({
					...theme.typography.body2,
				}),
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const { color } = ownerState

					return {
						padding: theme.spacing(1),
						...(color === 'default' && {
							[`&.${radioClasses.checked}`]: {
								color: theme.vars.palette.text.primary,
							},
						}),
						[`&.${radioClasses.disabled}`]: {
							color: theme.vars.palette.action.disabled,
						},
					}
				},
			},
		},
	}
}
