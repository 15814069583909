import { toggleButtonClasses } from '@mui/material/ToggleButton'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// ----------------------------------------------------------------------
export function toggleButton(): CssVarsThemeOptions['components'] {
	return {
		MuiToggleButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const defaultStyle = {
						[`&.${toggleButtonClasses.selected}`]: {
							backgroundColor: theme.palette.common.black,
							color: theme.palette.common.white,
							'&:hover': {
								backgroundColor: theme.palette.common.black,
							},
						},
						[`&:not(${toggleButtonClasses.selected})`]: {
							backgroundColor: theme.palette.common.white,
							color: theme.palette.common.black,
						},
						width: theme.spacing(8),
						border: `1px solid ${theme.palette.divider}`,
					}

					const colorStyle = COLORS.map((color) => ({
						...(ownerState.color === color && {
							[`&.${toggleButtonClasses.selected}`]: {
								backgroundColor: theme.palette[color].main,
								color: theme.palette.common.white,
								'&:hover': {
									backgroundColor: theme.palette[color].main,
								},
							},
							[`&:not(${toggleButtonClasses.selected})`]: {
								backgroundColor: theme.palette.common.white,
								color: theme.palette[color].main,
							},
							border: `1px solid ${theme.palette[color].main}`,
						}),
					}))

					const disabledState = {
						[`&.${toggleButtonClasses.disabled}`]: {
							[`&.${toggleButtonClasses.selected}`]: {
								color: theme.palette.action.disabled,
								backgroundColor: theme.palette.action.selected,
							},
						},
					}

					return [defaultStyle, ...colorStyle, disabledState]
				},
			},
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				grouped: ({ theme }) => ({
					'&:not(:first-of-type)': {
						borderRadius: '0',
						margin: 0,
					},
					'&:first-of-type': {
						borderTopLeftRadius: theme.shape.borderRadius,
						borderBottomLeftRadius: theme.shape.borderRadius,
					},
					'&:last-of-type': {
						borderTopRightRadius: theme.shape.borderRadius,
						borderBottomRightRadius: theme.shape.borderRadius,
					},
				}),
			},
		},
	}
}
