import { intercomScript, intercomSettings } from 'config/intercom'
import { useScript } from 'hooks/use-script'
import { useCallback, useEffect, useRef } from 'react'
import { getCurrentSubdomain } from 'services/util/subdomain-parser'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { isProdOrUat } from 'utils/env'

/**
 * initial unauthenticated intercom setup
 *
 * 'update' is called when the user logs in and appends that data to the intercom object
 *
 * 'boot' in this case should ONLY be run once, so we use a ref to track that
 *
 * @param siteInfo
 */
export const useBootIntercom = (siteInfo: SiteInfoResponse) => {
	const hasBootedIntercom = useRef(false)

	const setupIntercom = useCallback(() => {
		window['intercomSettings'] = {
			...intercomSettings,
			// company is the only object type allowed to be passed
			company: { company_id: siteInfo.organizationId, name: siteInfo.organizationName },
			company_id: siteInfo.organizationId,
			company_site: `${getCurrentSubdomain().split('.')[0]}.corestream.com`,
			name: siteInfo.organizationName,
			org_unit_id: siteInfo.organizationUnitId,
		}

		if (typeof window.Intercom === 'function') {
			window.Intercom('boot', window['intercomSettings'])
		}
	}, [siteInfo])

	// run when siteInfo is present, is prod, and only run once
	useEffect(() => {
		if (siteInfo.organizationId && siteInfo.organizationName && !hasBootedIntercom.current && isProdOrUat()) {
			setupIntercom()

			hasBootedIntercom.current = true
		}
	}, [setupIntercom, siteInfo])

	useScript(intercomScript, isProdOrUat())
}
