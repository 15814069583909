import { StateMachineStatus } from 'hooks/use-async-fetch'
import { useParamSelector } from 'hooks/use-param-selector'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { overviewByIdSelector, userEnrollmentOverviewStatusSelector } from 'store/enrollments/selectors'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { getCoverageDetailsFromEnrollments } from 'utils/benefit-plans'
import { BenefitEnrollmentWithCoverage } from './types'
import { getPlanEnrollmentInfo } from './utils'
import { selectPlanById } from 'store/benefit-plans/selectors'

export function useEnrollmentsForBenefit(benefitPlanId: string | undefined): BenefitEnrollmentWithCoverage & {
	enrollmentsStatus: StateMachineStatus
} {
	const { und3484 } = useFlags()
	const enrollmentsStatus = useSelector(userEnrollmentOverviewStatusSelector)
	const overviewData = useParamSelector(overviewByIdSelector, benefitPlanId) as UserEnrollmentRes[]
	const plan = useParamSelector(selectPlanById, Number(benefitPlanId))

	const enrollmentInfos = getPlanEnrollmentInfo(und3484, overviewData)
	const coverageDetails = getCoverageDetailsFromEnrollments(
		enrollmentInfos.effectiveEnrollment,
		enrollmentInfos.latestEnrollment,
		plan,
	)

	return {
		...enrollmentInfos,
		coverageDetails,
		enrollmentsStatus,
	}
}
