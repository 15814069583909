import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '../keys'
import { MarketingContentService } from '../services'

export const useIndividualMarketingContent = (
	benefitPlanMarketingContentId: string,
	options: { enabled?: boolean } = {},
) => {
	return useQuery({
		...options,
		queryFn: () => MarketingContentService.getMarketingContent(benefitPlanMarketingContentId),
		queryKey: queryKeys.marketingContent.byId(benefitPlanMarketingContentId),
	})
}
