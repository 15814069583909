export const stateSanitizer = (state: { [key: string]: any }) => {
	return {
		...state,
		// Don't send these to LogRocket
		// TODO - improve autoquotes state sanitization
		autoquotes: undefined,
		enrollmentWizard: {
			...state.enrollmentWizard,
			personal: undefined,
		},
		oidc: undefined,
		persons: undefined,
		user: {
			...state.user,
			userManager: undefined,
			userProfile: {
				employeeId: state.user.userProfile?.employeeId,
				userId: state.user.userProfile?.userId,
				workerId: state.user.userProfile?.workerId,
			},
		},
	}
}
