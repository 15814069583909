import { Alert, Grow, Snackbar, Typography, alertClasses } from '@mui/material'
import { addYears } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { routesMap } from 'routes/routes-map'
import styles from './cookie-policy.module.css'

const isExpired = (ttl: number) => {
	return new Date(ttl) < new Date()
}

const ignoreList = [routesMap.forgotPassword, routesMap.login, routesMap.logout, routesMap.register, routesMap.start]

const CookiePolicy = () => {
	const ttl = localStorage.getItem('CookieConsent') ? Number(localStorage.getItem('CookieConsent')!) : 0

	const [showBanner, setShowBanner] = useState(isExpired(ttl))

	const acknowledgeCookie = useCallback(() => {
		//www.cookieyes.com/knowledge-base/cookie-consent/how-long-does-cookie-consent-last
		// in general - 1 year for re-consent
		localStorage.setItem('CookieConsent', addYears(new Date(), 1).toString())

		setShowBanner(false)
	}, [])

	const location = useLocation()

	// force a single use of location.pathname
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const currentLocation = useMemo(() => location.pathname, [])

	useEffect(() => {
		if (ignoreList.includes(location.pathname)) {
			return
		}

		if (currentLocation !== location.pathname) {
			acknowledgeCookie()
		}
	}, [acknowledgeCookie, currentLocation, location.pathname])

	if (!showBanner) {
		return null
	}

	return (
		<Snackbar TransitionComponent={Grow} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={showBanner}>
			<Alert
				className={styles['cookie-container']}
				elevation={6}
				color='info'
				icon={false}
				onClose={acknowledgeCookie}
				aria-label='Cookie consent modal'
				aria-modal='true'
				sx={{
					[`& .${alertClasses.action}`]: {
						padding: '10px',
					},
				}}
			>
				<div className={styles['cookie-text-container']}>
					<Typography color={(theme) => theme.palette.primary.contrastText}>
						We use cookies to personalize content, tailor products, and provide a safer experience. By using this site,
						you agree to our
						<a
							className={styles['cookie-link']}
							href='https://corp.corestream.com/corestream-cookie-policy'
							target='_blank'
							rel='noopener noreferrer'
						>
							{'cookie policy'}
						</a>
					</Typography>
				</div>
			</Alert>
		</Snackbar>
	)
}

export default CookiePolicy
