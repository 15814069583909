import { shallowEqual, useSelector } from 'react-redux'

export const useParamSelector = (selector, ...params) =>
	useSelector((state) => selector(state, ...params), shallowEqual)

type SelectorWithParams<S, TArgs extends any[], TReturn> = (state: S, ...args: TArgs) => TReturn

export function useParamSelectorTyped<S, TArgs extends any[], TReturn>(
	selector: SelectorWithParams<S, TArgs, TReturn>,
	...args: TArgs
): TReturn {
	return useSelector<S, TReturn>((state) => selector(state, ...args))
}
