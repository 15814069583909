import { Comparator } from './Comparator'

/**
 * Create a composite comparator from an array of comparators
 */

export function createCompositeComparator<T>(comparators: Comparator<T>[]): Comparator<T> {
	return (a: T, b: T): number => {
		for (const comparator of comparators) {
			const comparison = comparator(a, b)
			if (comparison !== 0) {
				return comparison
			}
		}

		return 0
	}
}
