import { useQuery } from '@tanstack/react-query'
import { getTrustmarkEnrollmentQuery } from 'api/benefit-elections'
import { getEnrollmentsTrustmarkEnrollmentQuery } from 'api/enrollments'
import { useFlags } from 'launchdarkly-react-client-sdk'

type TrustmarkQueryOptions = {
	enabled: boolean
}
export const useTrustmarkEnrollment = (
	options: TrustmarkQueryOptions = {
		enabled: true,
	},
) => {
	const { und3484 } = useFlags()

	return useQuery(
		trustmarkEnrollmentQueryFactory(
			{
				useBenniesApi: und3484,
			},
			options,
		),
	)
}
const trustmarkEnrollmentQueryFactory = (data: { useBenniesApi: boolean }, options: TrustmarkQueryOptions) => {
	return data.useBenniesApi
		? getTrustmarkEnrollmentQuery({ enabled: options.enabled })
		: getEnrollmentsTrustmarkEnrollmentQuery({ enabled: options.enabled })
}
