import { SubmitBenefitElectionResponseDTO } from 'api/benefit-elections/types/benefit-elections'
import { axiosInstance } from 'config/axios'
import { SubmitBenefitElectionDTO } from 'types/benefit-election'
import { DependentList } from 'types/dependent'
import { LTCEnrollmentResponse } from 'types/ltc-enrollment-response'
import { RecentElection } from 'types/recent-election'
import { BenefitElectionsOverviewResponse } from 'types/user-elections'
import { WorkerAddressResponse } from 'types/worker-address-response'
import UrlBuilder from './util/url-builder'

const urlBuilder = new UrlBuilder('benefitelections')

export class BenefitElectionsService {
	static clearEnrollments(): Promise<unknown> {
		return axiosInstance.post(urlBuilder.setEndpoint('BenefitElections/ClearEnrollments').url())
	}

	static GetDependents(): Promise<DependentList> {
		return axiosInstance.get(urlBuilder.setEndpoint(`BenefitElections/Dependents`).url())
	}

	static GetRecentElections(): Promise<RecentElection> {
		return axiosInstance.get(urlBuilder.setEndpoint(`BenefitElections/Recent`).url())
	}

	static submitBenefitElections(props: SubmitBenefitElectionDTO): Promise<SubmitBenefitElectionResponseDTO> {
		const url = urlBuilder.setEndpoint('BenefitElections/BenefitElections').url()

		return axiosInstance.post(url, props)
	}

	static getUserElections(): Promise<BenefitElectionsOverviewResponse> {
		const url = urlBuilder.setEndpoint('BenefitElections/Overview').url()

		return axiosInstance.get(url)
	}

	static getEnrollmentData(signal?: AbortSignal): Promise<LTCEnrollmentResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint('TrustmarkLtc/EnrollmentData')

		return axiosInstance.get(endpoint.url(), { signal })
	}

	static getWorkerAddress(): Promise<WorkerAddressResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`TrustmarkLtc/UserAddressInfo`)

		return axiosInstance.get(endpoint.url())
	}

	static getTrustmarkClientToken(locationData: Partial<WorkerAddressResponse>, isGroupFlow: boolean): Promise<string> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint('TrustmarkLtc')

		return axiosInstance.post(endpoint.url(), { ...locationData, isCustomFunctionality: isGroupFlow })
	}
}
