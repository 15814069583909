import { loadingButtonClasses } from '@mui/lab/LoadingButton'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function loadingButton(): CssVarsThemeOptions['components'] {
	return {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		MuiLoadingButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'soft' && {
						[`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
							left: 10,
						},
						[`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
							right: 14,
						},
						...(ownerState.size === 'small' && {
							[`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
								left: 10,
							},
							[`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
								right: 10,
							},
						}),
					}),
				}),
			},
		},
	}
}
