// https://userinsights.int.corestream.com/swagger/index.html
export const queryKeys = {
	all: () => ['userinsights'] as const,
	recommendations: {
		all: () => [...queryKeys.all(), 'userRecommendation'] as const,
		userRecommendation: {
			getById: (formSubmissionId: string) => [...queryKeys.recommendations.all(), formSubmissionId] as const,
			getLatest: () => [...queryKeys.recommendations.all(), 'get'] as const,
		},
	},
}
