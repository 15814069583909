import { SearchOptions } from '@algolia/client-search'
import { RequestOptions } from '@algolia/transporter'
import { marketingContentClient } from 'config/search-config'
import { ModularMarketingContent } from 'store/benefit-plans/types'

export class MarketingContentService {
	static getMarketingContent = (id: string, options?: RequestOptions & SearchOptions) => {
		return marketingContentClient.getObject<ModularMarketingContent>(id, options)
	}
}
