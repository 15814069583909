import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function tooltip(): CssVarsThemeOptions['components'] {
	return {
		MuiTooltip: {
			styleOverrides: {
				tooltip: ({ theme }) => ({
					backgroundColor: theme.vars.palette.grey[800],
				}),
				arrow: ({ theme }) => ({
					color: theme.vars.palette.grey[800],
				}),
			},
		},
	}
}
