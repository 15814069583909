import { EffectCallback, useEffect } from 'react'

const useEffectOnce = (effect: EffectCallback) => {
	useEffect(effect, [effect])
}

/**
 * The given function will run once as long as the current browser tab is running.
 */
export const useEffectOnceSessionStorage = (fn: () => void, key: string) => {
	useEffectOnce(() => {
		const item = sessionStorage.getItem(key)

		if (!item) {
			// Run function
			fn()

			// Update session storage
			sessionStorage.setItem(key, '1')
		}
	})
}

/**
 * The given function will run once as long as the current browser tab is running.
 */
export const useEffectOnceSessionStorageCondition = (fn: () => void, key: string, condition: boolean) => {
	useEffect(() => {
		if (!condition) return

		const item = sessionStorage.getItem(key)

		if (!item) {
			// Run function
			fn()

			// Update session storage
			sessionStorage.setItem(key, '1')
		}
	}, [condition, fn, key])
}
