/**
 * converts URL query params into object
 */
export function deparam(queryString) {
	const params = new URLSearchParams(queryString)
	const obj = {}
	for (const [key, value] of params.entries()) {
		obj[key] = value
	}

	return obj
}
