import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'
import { API_SUBDOMAIN, endpoints } from '../constants'
import { CorestreamProduct } from '../types/corestream-product'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class CorestreamProductsService {
	static getCorestreamProducts(): Promise<CorestreamProduct[]> {
		const url = urlBuilder.setEndpoint(endpoints.CorestreamProducts.all).url()

		return axios.get(url)
	}

	static getCorestreamProductById(id: string): Promise<CorestreamProduct> {
		const url = urlBuilder.setEndpoint(endpoints.CorestreamProducts.byId(id)).url()

		return axios.get(url)
	}
}
