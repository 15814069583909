import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getFilters, rawClient } from 'config/search-config'
import { SearchResponseList } from 'feature/discounts'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { RootState } from 'store/rootReducer'
import { EXCLUSIVE_PARTNERSHIPS, FEATURED, HERO, NONE, TRENDING } from 'types/feature-level'
import { Offer } from 'types/offer'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { CategoryFacetHit, GlobalSearchState } from './types'

const initialState: GlobalSearchState = {
	facetSearchStatus: IDLE,
	facets: [],
	offerFetchStatus: IDLE,
	offers: { exclusivePartnerships: [], featured: [], hero: [], standard: [], trending: [] },
	recommendedFetchStatus: IDLE,
	recommendedOffers: [],
}

const searchSlice = createSlice({
	initialState,
	name: 'search',
	reducers: {
		fetchFacetsSuccess(state: GlobalSearchState, action: PayloadAction<CategoryFacetHit[]>) {
			state.facetSearchStatus = SUCCESS
			state.facets = [...action.payload]
		},
		fetchOffersSuccess(state: GlobalSearchState, action: PayloadAction<SearchResponseList>) {
			const sortedRankList = [...action.payload].sort((a: Offer, b: Offer) => +a.rank - +b.rank)
			const standard: SearchResponseList = sortedRankList.filter((offer) => offer.level === NONE)
			const featured: SearchResponseList = sortedRankList.filter((offer) => offer.level === FEATURED)
			const trending: SearchResponseList = sortedRankList.filter((offer) => offer.level === TRENDING)
			const hero: SearchResponseList = sortedRankList.filter((offer) => offer.level === HERO)
			const exclusivePartnerships: SearchResponseList = sortedRankList.filter(
				(offer) => offer.level === EXCLUSIVE_PARTNERSHIPS,
			)
			state.offers = {
				exclusivePartnerships: [...exclusivePartnerships],
				featured: [...featured],
				hero: [...hero],
				standard: [...standard],
				trending: [...trending],
			}
			state.offerFetchStatus = SUCCESS
		},
		fetchRecommendedOffersSuccess(
			state: GlobalSearchState,
			action: PayloadAction<{ SearchResponseList: SearchResponseList; isDiscountsOnly: boolean; siteInfo }>,
		) {
			const currentDate: string = Math.trunc(Date.now() / 1000).toString()
			const filterRecommended = (offer: Offer): boolean => {
				// remove benefits for discount only users
				if (
					!offer ||
					(action.payload.isDiscountsOnly && offer?.offerType.toLowerCase() === 'benefit') ||
					// remove expired offers
					offer?.endDate <= currentDate ||
					offer?.startDate > currentDate ||
					//remove blacklist
					(action.payload.siteInfo.properties.discounterBlacklist &&
						action.payload.siteInfo.properties.discounterBlacklist.includes(offer.vendor.discounterId)) ||
					// remove exlcusive offers
					(!offer.tenantIDs.includes('ALL') &&
						!offer.tenantIDs.includes(action.payload.siteInfo.organizationId.toLowerCase()))
				) {
					return false
				}

				return true
			}

			const filteredRecommended: SearchResponseList = []
			action.payload.SearchResponseList.forEach((o) => {
				if (filterRecommended(o)) filteredRecommended.push(o)
			})
			state.recommendedOffers = [...filteredRecommended]
			state.recommendedFetchStatus = SUCCESS
		},
		searchFetchStatus(
			state: GlobalSearchState,
			action: PayloadAction<{ stateKey: string; value: StateMachineStatus }>,
		) {
			state[action.payload.stateKey] = action.payload.value
		},
	},
})

const { actions, reducer } = searchSlice
export { actions, reducer as search }

export const getFacets =
	(attribute: string = 'categoryNames', maxFacetHits: number = 100, sortFacetValuesBy: 'count' | 'alpha' = 'alpha') =>
	async (dispatch, getState): Promise<void> => {
		const { site, user }: RootState = getState()
		const stateKey = 'facetSearchStatus'
		dispatch(actions.searchFetchStatus({ stateKey, value: LOADING }))
		const { hasDiscounts } = site.siteInfo.properties
		const { isDiscountsOnly } = user.userProfile
		const filters = getFilters(false)(site.siteInfo, !!hasDiscounts)

		const fetchFacets = async (isMemberExclusive: boolean): Promise<{ facetHits: CategoryFacetHit[] }> => {
			const memberExclusivesFactFilter = filters.concat(
				' AND ',
				isMemberExclusive ? 'isMemberExclusiveOffer: true' : 'NOT isMemberExclusiveOffer: true',
			)

			return rawClient
				.searchForFacetValues(attribute, '', {
					filters: memberExclusivesFactFilter,
					maxFacetHits,
					sortFacetValuesBy,
				})
				.then((res) => ({
					...res,
					facetHits: res.facetHits
						.filter((facet) => {
							if (isDiscountsOnly && facet.value.toLowerCase().includes('benefits')) {
								return false
							}

							return facet.count > 0
						})
						.map((facet) => ({ ...facet, isMemberExclusive })),
				}))
		}

		try {
			const { facetHits: nonExclusiveFacetHits } = await fetchFacets(false)
			const { facetHits: exclusiveFacetHits } = await fetchFacets(true)
			const facetHits = [...nonExclusiveFacetHits, ...exclusiveFacetHits]
			dispatch(actions.fetchFacetsSuccess(facetHits))
		} catch (e) {
			dispatch(actions.searchFetchStatus({ stateKey, value: ERROR }))
		}
	}

export const getOffers =
	(wrp2642: boolean, hitsPerPage: number = 10000, withFilterLevels: boolean = true) =>
	async (dispatch, getState) => {
		const stateKey = 'offerFetchStatus'
		const {
			site: { siteInfo },
		}: RootState = getState()
		const { hasDiscounts } = siteInfo.properties
		try {
			if (hasDiscounts || wrp2642) {
				dispatch(actions.searchFetchStatus({ stateKey, value: LOADING }))
				const filters = getFilters(withFilterLevels)(siteInfo, !!hasDiscounts, wrp2642)
				const results = await rawClient.search('', { filters, hitsPerPage })
				dispatch(actions.fetchOffersSuccess(results.hits as SearchResponseList))
			}
		} catch (e) {
			dispatch(actions.searchFetchStatus({ stateKey, value: ERROR }))
		}
	}

export const getRecommendedOffers = () => async (dispatch, getState) => {
	const stateKey = 'recommendedFetchStatus'
	const {
		user: { recommendedOfferIds },
	}: RootState = getState()
	try {
		const { site, user }: RootState = getState()

		dispatch(actions.searchFetchStatus({ stateKey, value: LOADING }))
		const results = await rawClient.getObjects(recommendedOfferIds.map((s) => s.toUpperCase()))
		dispatch(
			actions.fetchRecommendedOffersSuccess({
				SearchResponseList: results.results as SearchResponseList,
				isDiscountsOnly: user.userProfile.isDiscountsOnly,
				siteInfo: site.siteInfo,
			}),
		)
	} catch (e) {
		dispatch(actions.searchFetchStatus({ stateKey, value: ERROR }))
	}
}
