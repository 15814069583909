import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { MyProfileUser, isLoggedInSelector, myProfileSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { ldIdentify } from 'utils/launch-darkly-utils'

export default function useLdIdentify() {
	const ldClient = useLDClient()
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)

	useEffect(() => {
		if (!ldClient) return

		const ldContext = ldClient?.getContext()
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore - we are using multi-context user is available to us
		if (isLoggedIn && ldContext?.user?.employeeId !== userProfile.employeeId) {
			const { employeeId, userId, employmentGroup, employmentType } = userProfile
			ldIdentify(ldClient, siteInfo.organizationName, userId, employeeId, employmentGroup, employmentType)
		}
	}, [isLoggedIn, ldClient, siteInfo.organizationName, userProfile])
}
