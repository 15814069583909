import { AccountCircle } from '@mui/icons-material'
import { ButtonBase } from '@mui/material'
import { cx } from 'class-variance-authority'
import { FC, KeyboardEvent, MouseEvent } from 'react'
import styles from './my-profile-tab.module.css'

interface MyProfileTabProps {
	className: string
	handleOnClick: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void
	id?: string
	text: string
	href?: string
	selected: boolean
}

const MyProfileTab: FC<MyProfileTabProps> = ({
	className,
	handleOnClick,
	id = 'my-profile',
	text,
	selected,
}: MyProfileTabProps) => {
	const onKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleOnClick(event)
		}
	}

	return (
		<ButtonBase
			className={cx(className, { [styles['selected']]: selected })}
			id={id}
			onClick={handleOnClick}
			onKeyDown={onKeyDown}
			aria-label='My Profile'
		>
			{!!text && (
				<span className={styles['user-text']} id='my-profile-user-text'>
					{text}
				</span>
			)}
			<AccountCircle />
		</ButtonBase>
	)
}

export default MyProfileTab
