import { MutableRefObject, useCallback, useRef } from 'react'

import { debounce } from 'utils/utils'

export const useDebouncedCallback = <T extends any[]>(
	callback: (...args: T) => void,
	delay: number,
): ((...args: T) => unknown) => {
	const callbackRef = useRef(callback) as MutableRefObject<typeof callback>

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useCallback(
		debounce<(...args: T) => void, T>((...args: T) => callbackRef.current(...args), delay),
		[],
	)
}
