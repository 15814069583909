import { Button } from '@mui/material'
import { cx } from 'class-variance-authority'
import { useOfferCode, useOfferRedeemHandler } from 'components/offer/hooks'
import { Tooltip } from 'components/tooltip'
import { AlgoliaEventType } from 'hooks/analytics'
import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard'
import useSize from 'hooks/use-size'
import { useCallback } from 'react'
import { MQ_MD } from 'types/breakpoints'
import { Offer } from 'types/offer'
import styles from './offer-code-button.module.css'

export interface OfferCodeButtonProps {
	className?: string
	offer: Offer
	trackSearchOfferClicked?: (eventType: AlgoliaEventType) => void
}

export const OfferCodeButton = (props: OfferCodeButtonProps) => {
	const size = useSize()
	const copy = useCopyToClipboard()

	const offerCode = useOfferCode(props.offer)

	const onOfferCodeClick = useOfferRedeemHandler(props.offer, 'Offer Modal')

	const onCtaClick = useCallback(
		(e) => {
			// Algolia Event: convertedObjectIDsAfterSearch
			if (props.trackSearchOfferClicked) props.trackSearchOfferClicked('conversion')
			onOfferCodeClick(e)
		},
		[onOfferCodeClick, props],
	)

	return (
		<Tooltip
			classes={{ arrow: 'arrow', tooltip: 'tooltip' }}
			title='Click to Copy Code & Launch Site'
			placement='top'
			arrow
		>
			{size < MQ_MD ? (
				<div className={cx(styles['mobile-container'], props.className)}>
					<button onClick={() => copy(offerCode ?? '')} className={styles['mobile-code']}>
						<span className={styles['code']}>{offerCode}</span>
					</button>
					<Button onClick={onCtaClick}>Copy Code and Visit Site</Button>
				</div>
			) : (
				<button
					className={cx(styles['container'], props.className)}
					id={props.offer.objectID ?? ''}
					onClick={onCtaClick}
				>
					<span className={styles['code']}>{offerCode}</span>
					<Button className={styles['button']}>Copy Code and Visit Site</Button>
				</button>
			)}
		</Tooltip>
	)
}

export default OfferCodeButton
