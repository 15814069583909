import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectBenefitPlansFetchStatus, selectTemplateTokensFetchStatus } from 'store/benefit-plans/selectors'
import { getBenefitPlansAndEffectiveDates, getTemplateTokens } from 'store/benefit-plans/slice'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoadingUserSelector, userSelector } from 'store/user/selectors'
import { IDLE, SUCCESS } from 'types/status-types'
import { useAppSelector } from './redux'
import { StateMachineStatus } from './use-async-fetch'
import { useCallbackDispatch } from './use-callback-dispatch'

export const useBenefitPlanInit = (isLoggedIn: boolean) => {
	const dispatch = useCallbackDispatch()
	const benefitPlansFetchStatus: StateMachineStatus = useSelector(selectBenefitPlansFetchStatus)
	const templateTokensFetchStatus: StateMachineStatus = useSelector(selectTemplateTokensFetchStatus)
	const isUserLoading = useSelector(isLoadingUserSelector)
	const siteInfo = useSelector(siteInfoSelector)
	const { hasVoluntaryBenefits } = siteInfo.properties
	const user = useSelector(userSelector)
	const sessionToken = useAppSelector((s) => s.oidc.user?.access_token)
	const { und16737_rename_plan_groups } = useFlags()

	// Plan Info + Effective Date Fetch when Bennies as Data Source is enabled
	useEffect(() => {
		if (
			!isLoggedIn ||
			!user ||
			isUserLoading ||
			!hasVoluntaryBenefits ||
			benefitPlansFetchStatus !== IDLE ||
			templateTokensFetchStatus !== SUCCESS ||
			!sessionToken
		) {
			return
		}
		dispatch(getBenefitPlansAndEffectiveDates(und16737_rename_plan_groups))
	}, [
		benefitPlansFetchStatus,
		dispatch,
		hasVoluntaryBenefits,
		isLoggedIn,
		isUserLoading,
		sessionToken,
		templateTokensFetchStatus,
		und16737_rename_plan_groups,
		user,
	])

	useEffect(() => {
		if (templateTokensFetchStatus === IDLE && hasVoluntaryBenefits) {
			dispatch(getTemplateTokens())
		}
	}, [dispatch, hasVoluntaryBenefits, templateTokensFetchStatus])
}
