import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		['h4-body']: true
		['h5-body']: true
		['h3-bold']: true
		['h6-body']: true
	}
}

export function typography(): CssVarsThemeOptions['components'] {
	return {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					['h4-body']: 'p',
					['h5-body']: 'p',
					['h3-bold']: 'p',
					['h6-body']: 'p',
				},
			},
			styleOverrides: {
				root: {
					'&.MuiLink-root': {
						padding: '4px',
					},
					fontFamily: [
						'aktiv-grotesk',
						'-apple-system',
						'BlinkMacSystemFont',
						'Segoe UI',
						'Roboto',
						'Oxygen',
						'Ubuntu',
						'Cantarell',
						'Fira Sans',
						'Droid Sans',
						'Helvetica Neue',
						'sans-serif',
					].join(','),
				},
				paragraph: ({ theme }) => ({
					marginBottom: theme.spacing(2),
				}),
				gutterBottom: ({ theme }) => ({
					marginBottom: theme.spacing(1),
				}),
			},
		},
	}
}
