import { QueryObserverOptions } from '@tanstack/react-query'
import { queryKeys } from 'api/benefit-elections/keys'
import { TrustmarkSimplinkService } from 'api/benefit-elections/services'
import { LTCEnrollmentResponse } from 'api/benefit-elections/types'

export const getTrustmarkEnrollmentQuery = (options?: {
	enabled?: boolean
}): QueryObserverOptions<LTCEnrollmentResponse, unknown, LTCEnrollmentResponse> => {
	return {
		enabled: options?.enabled ?? true,
		queryFn: () => TrustmarkSimplinkService.getEnrollmentData(),
		queryKey: queryKeys.trustmarkSimplink.getEnrollment(),
	}
}
