import { useMemo } from 'react'
import DiscountOffersService, { ReferralUrl } from 'services/discount-offers.service'
import { Offer } from 'types/offer'
import { useIsPaychex } from 'utils/url'

/**
 * Retrieves the referral URL for the selected Offer
 *
 * Depending on vendor, the URL will be different and possibly modified by the server
 * @returns {Promise<string>} The referral URL
 */
export const useOfferUrl = async (offer: Offer) => {
	const isPaychex = useIsPaychex()

	return useMemo(() => {
		const vendorName = offer.vendor.name

		let link = offer.link

		// only replace link when present && when subdomain is paychex
		if (isPaychex && offer.paychexLink) {
			link = offer.paychexLink
		}

		if (vendorName === 'Samsung') {
			return _getSamsungUrl(link)
		} else {
			return link
		}
	}, [isPaychex, offer.link, offer.paychexLink, offer.vendor.name])
}

/**
 * internal method to retrieve the Samsung referral URL
 *
 * DO NOT use this method directly, use useOfferUrl() instead
 */
export const _getSamsungUrl = async (offerUrl: string): Promise<string> => {
	const refUrl: ReferralUrl = await DiscountOffersService.getSamsungRef()

	return `${offerUrl}?${new URLSearchParams(refUrl).toString()}`
}
