export const API_SUBDOMAIN = 'benefitelections'

export const endpoints = {
	BenefitElections: getBenefitElectionsEndpoints(),
	BenefitPlans: getBenefitPlansEndpoints(),
	CorestreamProducts: getCorestreamProductsEndpoints(),
	Debug: getDebugEndpoints(),
	NationwidePet: getNationwidePetEndpoints(),
	OpenEnrollmentWindows: getOpenEnrollmentWindowsEndpoints(),
	Rates: getRatesEndpoints(),
	TrustmarkSimplink: getTrustmarkSimplinkEndpoints(),
}

function getRatesEndpoints() {
	const ratesEndpoints = {
		all: 'Rates' as const,
		coverageOptionsAndLowestRates: () => `${ratesEndpoints.all}/CoverageOptionsAndLowestRates`,
	}

	return ratesEndpoints
}

function getBenefitPlansEndpoints() {
	const bpEndpoints = {
		all: 'BenefitPlans' as const,
		fetchEffectiveDates: (orgId: string) => `${bpEndpoints.all}/Organizations/${orgId}/EffectiveDates`,
		getBenefitQuestions: (benefitPlanId: number) => `${bpEndpoints.all}/${benefitPlanId}/Questions/v2`,
		getEligiblePlans: () => `${bpEndpoints.all}/EligiblePlans`,
		getQuestions: (benefitPlanId: number) => `${bpEndpoints.all}/${benefitPlanId}/Questions`,
	}

	return bpEndpoints
}

function getBenefitElectionsEndpoints() {
	const beEndpoints = {
		all: 'BenefitElections' as const,
		clearEnrollments: () => `${beEndpoints.all}/ClearEnrollments` as const,
		dependents: () => `${beEndpoints.all}/Dependents` as const,
		getRecentElections: () => `${beEndpoints.all}/Recent` as const,
		pets: () => `${beEndpoints.all}/Pets` as const,
		submitBenefitElections: () => `${beEndpoints.all}/BenefitElections` as const,
	}

	return beEndpoints
}

function getDebugEndpoints() {
	const debugEndpoints = {
		all: 'Debug' as const,
		cacheBuster: () => `${debugEndpoints.all}/CacheBuster` as const,
	}

	return debugEndpoints
}

function getNationwidePetEndpoints() {
	const petEndpoints = {
		all: 'NationwidePet' as const,
		getBreeds: () => `${petEndpoints.all}/NationwidePetBreeds` as const,
		getRates: () => `${petEndpoints.all}/Rates` as const,
	}

	return petEndpoints
}

function getTrustmarkSimplinkEndpoints() {
	const tEndpoints = {
		all: 'TrustmarkLtc' as const,
		getEnrollmentData: () => `${tEndpoints.all}/EnrollmentData` as const,
		getToken: () => `${tEndpoints.all}` as const,
		getWorkerAddress: () => `${tEndpoints.all}/UserAddressInfo` as const,
	}

	return tEndpoints
}

function getOpenEnrollmentWindowsEndpoints() {
	const oewEndpoints = {
		all: 'OpenEnrollmentWindows' as const,
		toggleOE: (orgId: string) => `${oewEndpoints.all}/Organizations/${orgId}/ToggleOE` as const,
	}

	return oewEndpoints
}

function getCorestreamProductsEndpoints() {
	const cpEndpoints = {
		all: 'CorestreamProducts' as const,
		byId: (id: string) => `${cpEndpoints.all}/${id}` as const,
	}

	return cpEndpoints
}
