import { Variant } from '@mui/material/styles/createTypography'
import { cx } from 'class-variance-authority'
import { FC, ReactNode } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { onKeyDownEventFactory } from 'utils/a11y'
import { isThemePrimarySelector } from '../../store/site/selectors'
import { StyledTextV2 } from './styles-v2'
import styles from './text-v2.module.scss'

type TextVariant =
	| 'category'
	| 'body1'
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'inherit'
	| 'body'
	| 'offerLarge'
	| 'offerMedium'
	| 'offerSmall'
	| 'p'
	| 'span'

export type TextV2Props = {
	children?: string | string[] | ReactNode
	className?: string
	color?: string
	component?: string
	for?: string
	id?: string
	isLink?: boolean
	onClick?: (any) => void
	onKeyDown?: () => void
	override?: string
	role?: string
	usecta?: boolean
	variant?: TextVariant
	useOrgForegroundTextColor?: boolean
	ignoreOrg?: boolean
	error?: boolean
	name?: string
	textAlign?: string
	style?: any
	tabIndex?: number
	ref?: any
	dangerouslySetInnerHTML?:
		| {
				__html: string
		  }
		| undefined
	'data-testid'?: string
}

const TextV2: FC<TextV2Props> = (props: TextV2Props) => {
	const isPrimaryTheme = useSelector(isThemePrimarySelector, shallowEqual)
	const classes = cx(props.className, styles['custom-text'], {
		[styles['category']]: props.variant === 'category' && !isPrimaryTheme,
		[styles['category-secondary']]: props.variant === 'category' && !isPrimaryTheme,
		[styles['styled-v2--link']]: props.isLink,
		'offer-large': props.variant === 'offerLarge',
		'offer-medium': props.variant === 'offerMedium',
		'offer-small': props.variant === 'offerSmall',
		[styles['styled-v2--org-foreground-text']]: props.useOrgForegroundTextColor && !props.ignoreOrg,
		[styles['styled-v2--default-foreground-text']]: !props.useOrgForegroundTextColor && !props.ignoreOrg,
		[styles['styled-v2--error']]: props.error,
	})
	const variantMap = (variant?: TextVariant): string =>
		({
			category: 'body1',
			h1: 'h1',
			h2: 'h2',
			h3: 'h3',
			h4: 'h4',
			h5: 'h5',
			inherit: 'inherit',
			offerLarge: 'body1',
			offerMedium: 'body1',
			offerSmall: 'body1',
			p: 'body2',
			span: 'span',
		}[variant ?? ''] ?? 'body1')

	const onKeyDown = onKeyDownEventFactory(() => props.onKeyDown?.())

	return (
		<div className={styles['styled-v2']}>
			<StyledTextV2
				className={classes}
				name={props.name}
				component={props.component}
				htmlFor={props.for}
				id={props.id}
				onClick={props.onClick}
				onKeyDown={onKeyDown}
				override={props.override}
				textcolor={props.color}
				role={props.role}
				variant={variantMap(props.variant) as Variant}
				usecta={props.usecta}
				textalign={props.textAlign}
				style={props.style}
				tabIndex={props.tabIndex}
				ref={props.ref}
				dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
				data-testid={props['data-testid']}
			>
				{props.children}
			</StyledTextV2>
		</div>
	)
}

export default TextV2
