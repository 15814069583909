import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function card(): CssVarsThemeOptions['components'] {
	return {
		MuiCard: {
			styleOverrides: {
				root: ({ theme }) => ({
					position: 'relative',
					// boxShadow: theme.customShadows.card,
					borderRadius: theme.shape.borderRadius * 2,
					zIndex: 0, // Fix Safari overflow: hidden with border radius
				}),
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: theme.spacing(3, 3, 0),
				}),
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: theme.spacing(3),
				}),
			},
		},
	}
}
