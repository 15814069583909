import { benefitPlansIndex, getFilters, offersIndex, searchEligibleBenefitPlansFilters } from 'config/search-config'
import { useAppSelector } from 'hooks/redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Configure, Index } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { selectEligiblePlanIds } from 'store/benefit-plans/selectors'
import { MyProfileUser, myProfileSelector } from 'store/user/selectors'
import { match } from 'ts-pattern'
import { SiteInfoResponse } from '../../types/tenant-site-types'

const SearchConfiguration = ({ siteInfo }: { siteInfo: SiteInfoResponse }): JSX.Element | null => {
	const { wrp2642 } = useFlags()
	const { properties, organizationId }: SiteInfoResponse = siteInfo
	const shouldShowBenefits: boolean = Boolean(properties.hasVoluntaryBenefits)
	const shouldShowDiscounts: boolean = Boolean(properties.hasDiscounts)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const eligiblePlanIds = useSelector(selectEligiblePlanIds)
	const userId = useAppSelector((state) => state.user.userProfile.userId)

	const useDiscountsIndex = shouldShowDiscounts
	const useBenefitsIndex = !userProfile.isDiscountsOnly && shouldShowBenefits && eligiblePlanIds.length > 0

	if (!siteInfo?.organizationId || !userId) {
		return null
	}

	// eslint-disable-next-line no-constant-condition
	const benefitPlanFilters = searchEligibleBenefitPlansFilters(organizationId, eligiblePlanIds)

	return (
		<>
			{match(useDiscountsIndex)
				.with(true, () => (
					<Index indexName={offersIndex}>
						<Configure
							filters={getFilters(false)(siteInfo, shouldShowDiscounts, wrp2642)}
							clickAnalytics={true}
							userToken={userId}
						/>
					</Index>
				))
				.otherwise(() => null)}
			{match({ useBenefitsIndex })
				.with({ useBenefitsIndex: true }, () => (
					<Index indexName={benefitPlansIndex}>
						<Configure filters={benefitPlanFilters} clickAnalytics={true} userToken={userId} />
					</Index>
				))
				.otherwise(() => null)}
		</>
	)
}

export default SearchConfiguration
