import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { Properties } from '../types/tenant-site-types'

function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
	return obj[key]
}

export function useSiteProperty(key: keyof Properties) {
	const siteInfo = useSelector(siteInfoSelector)

	return getProperty(siteInfo.properties, key)
}
