import { Suspense, createContext, createElement, useContext, useState } from 'react'
import { ModalName, modalRegistry } from './modal-registry'

type ModalContextType = {
	openModal: (name: ModalName, props: DIModalProps) => void
	closeModal: () => void
	currentModal: { name: ModalName; props: DIModalProps } | null
}

export type DIModalProps = {
	onClose: (submit: boolean) => any
}

export const ModalContext = createContext<ModalContextType>({
	closeModal: () => {},
	currentModal: null,
	openModal: () => {},
})

export function ModalProvider({ children }: { children: React.ReactNode }) {
	const [currentModal, setCurrentModal] = useState<{ name: ModalName; props: DIModalProps } | null>(null)

	const openModal = (name: ModalName, props: DIModalProps) => {
		setCurrentModal({ name, props })
	}

	const closeModal = () => {
		setCurrentModal(null)
	}

	return (
		<ModalContext.Provider value={{ closeModal, currentModal, openModal }}>
			{children}
			<Suspense>
				{currentModal != null && createElement<DIModalProps>(modalRegistry[currentModal.name], currentModal.props)}
			</Suspense>
		</ModalContext.Provider>
	)
}

export function useOpenModal() {
	const context = useContext(ModalContext)
	if (context === undefined) {
		throw new Error('useOpenModal must be used within a ModalProvider')
	}

	return context.openModal
}

export function useCloseModal() {
	const context = useContext(ModalContext)
	if (context === undefined) {
		throw new Error('useCloseModal must be used within a ModalProvider')
	}

	const closeModal = () => {
		context.closeModal()
	}

	return closeModal
}
