import { checkboxClasses } from '@mui/material/Checkbox'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function checkbox(): CssVarsThemeOptions['components'] {
	return {
		MuiCheckbox: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const { color } = ownerState

					return {
						padding: theme.spacing(1),
						[`&.${checkboxClasses.root}`]: {
							color: theme.palette.text.primary,
						},
						...(color === 'default' && {
							[`&.${checkboxClasses.checked}`]: {
								color: theme.palette.text.primary,
							},
						}),
						...(color === 'error' && {
							[`&.${checkboxClasses.root}`]: {
								color: theme.palette.error.main,
							},
						}),
						[`&.${checkboxClasses.disabled}`]: {
							color: theme.palette.action.disabled,
						},
						'&.Mui-focusVisible': {
							border: 'solid 2px black',
							padding: '6px',
						},
						'&.focused:not(.Mui-focusVisible):not($checked)': {
							color: 'pink',
						},
					}
				},
			},
		},
	}
}
