import { Typography } from '@mui/material'
import { useAppSelector } from 'hooks/redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { routesMap } from 'routes/routes-map'
import { selectAvailablePlans } from 'store/benefit-plans/selectors'
import { selectOfferState } from 'store/search/selectors'
import { BenefitSection } from './components/benefit-section/benefit-section'
import { CategorySection } from './components/category-section/category-section'
import { DealsList } from './components/deals-list/deals-list'
import { TravelSection } from './components/travel-section/travel-section'
import styles from './theme.module.css'
import { ThemePage } from './types'

export function Theme() {
	const { themeName } = useParams()
	const { b4b_17199_static_theme_page: theme } = useFlags<{ b4b_17199_static_theme_page: ThemePage }>()
	const availablePlans = useAppSelector(selectAvailablePlans)
	const offers = useSelector(selectOfferState)
	const allOffers = [
		...offers.exclusivePartnerships,
		...offers.featured,
		...offers.standard,
		...offers.hero,
		...offers.trending,
	]

	if (theme.name !== themeName) {
		return <Navigate to={routesMap.home} replace />
	}

	const benefit = availablePlans.find((plan) => plan.benefitProductId === theme.benefit.benefitProductId)

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img className={styles['header-image']} src={theme.header.imageLink} alt='' />
				<h1 className={styles['header-title']}>{theme.header.title}</h1>
				<Typography variant='h3' className={styles['header-subtitle']}>
					{theme.header.subtitle}
				</Typography>
			</div>
			<div className={styles.content}>
				<DealsList {...theme.top_deals} offers={allOffers} className='top-deals' />
				{benefit && <BenefitSection benefitContent={theme.benefit} benefit={benefit} />}
				<TravelSection {...theme.travel} />
				<DealsList {...theme.more_deals} offers={allOffers} className='more-deals' />
				<CategorySection {...theme.category} />
			</div>
		</div>
	)
}
