import { useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'api/tenants/keys'
import { TrackingService } from 'api/tenants/services'
import { useCallback } from 'react'

export const useClickedOffers = () => {
	return useQuery({
		queryFn: () => TrackingService.fetchClickedOffers(),
		queryKey: queryKeys.tracking.offers.all(),
	})
}

export const usePrefetchClickedOffers = () => {
	const queryClient = useQueryClient()

	return () =>
		queryClient.prefetchQuery({
			queryFn: () => TrackingService.fetchClickedOffers(),
			queryKey: queryKeys.tracking.offers.all(),
		})
}

export const useInvalidateClickedOffersData = () => {
	const queryClient = useQueryClient()

	return useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: queryKeys.tracking.offers.all() })
	}, [queryClient])
}
