import { useDispatch } from 'react-redux'
import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { RootState } from 'store/rootReducer'

export type AppThunkAction = ThunkAction<Promise<any>, RootState, unknown, AnyAction>
export type ReduxDispatch = ThunkDispatch<RootState, unknown, Action>

export function useReduxDispatch(): ReduxDispatch {
	return useDispatch<ReduxDispatch>()
}
