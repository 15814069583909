import { buttonClasses } from '@mui/material/Button'
import { circularProgressClasses } from '@mui/material/CircularProgress'
import { formControlClasses } from '@mui/material/FormControl'
import { iconButtonClasses } from '@mui/material/IconButton'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { listClasses } from '@mui/material/List'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { paperClasses } from '@mui/material/Paper'
import { CssVarsThemeOptions, alpha } from '@mui/material/styles'

import { paper } from '../../css'

// ----------------------------------------------------------------------

export function dataGrid(): CssVarsThemeOptions['components'] {
	const paperStyles = (theme) => paper({ theme, dropdown: true })

	return {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		MuiDataGrid: {
			styleOverrides: {
				root: ({ theme }) => ({
					'--unstable_DataGrid-radius': 0,
					'--unstable_DataGrid-headWeight': theme.vars.typography.fontWeightMedium,
					borderWidth: 0,
				}),
				withBorderColor: ({ theme }) => ({
					borderColor: theme.vars.palette.divider,
				}),
				// Column
				columnHeaders: {
					borderBottom: 0,
				},
				columnHeader: ({ theme }) => ({
					fontSize: 14,
					color: theme.vars.palette.text.secondary,
					backgroundColor: theme.vars.palette.background.neutral,
					'&--sorted': {
						color: theme.vars.palette.text.primary,
					},
				}),
				columnSeparator: ({ theme }) => ({
					color: theme.vars.palette.divider,
				}),
				// Row, Cell
				cell: ({ theme }) => ({
					borderBottom: `1px dashed`,
					'&--editing': {
						boxShadow: 'none !important',
						backgroundColor: `rgba(${theme.vars.palette.primary.mainChannel} / 0.08) !important`,
					},
				}),
				// Toolbar
				toolbarContainer: ({ theme }) => ({
					gap: theme.spacing(2),
					padding: theme.spacing(2),
				}),
				toolbarQuickFilter: ({ theme }) => ({
					padding: 0,
					width: '100%',
					[theme.breakpoints.up('md')]: {
						width: 'unset',
					},
				}),
				// Paper
				paper: ({ theme }) => ({
					...paperStyles(theme),
					padding: 0,
				}),
				menu: ({ theme }) => ({
					[`& .${paperClasses.root}`]: {
						...paperStyles(theme),
						minWidth: 140,
					},
					[`& .${listClasses.root}`]: {
						padding: 0,
						[`& .${listItemIconClasses.root}`]: {
							minWidth: 0,
							marginRight: theme.spacing(2),
						},
					},
				}),
				// Icons
				menuIcon: ({ theme }) => ({
					[`& .${iconButtonClasses.root}`]: {
						margin: theme.spacing(0, 1),
						padding: theme.spacing(0.25),
					},
				}),
				iconButtonContainer: ({ theme }) => ({
					[`& .${iconButtonClasses.root}`]: {
						padding: theme.spacing(0.25),
						marginLeft: theme.spacing(1),
					},
				}),
				// Footer
				footerContainer: {
					minHeight: 'auto',
					borderTop: `1px dashed`,
				},
				selectedRowCount: {
					display: 'none',
					whiteSpace: 'nowrap',
				},
				overlay: ({ theme }) => ({
					[`& .${circularProgressClasses.root}`]: {
						color: theme.vars.palette.text.primary,
					},
				}),
				// Columns Panel
				panelHeader: ({ theme }) => ({
					padding: theme.spacing(2, 2, 0, 2),
				}),
				panelContent: ({ theme }) => ({
					padding: theme.spacing(1),
				}),
				columnsPanelRow: ({ theme }) => ({
					margin: theme.spacing(0.5, 0),
				}),
				panelFooter: ({ theme }) => ({
					display: 'none',
					gap: theme.spacing(1),
					padding: theme.spacing(2),
					justifyContent: 'flex-end',
					borderTop: `dashed 1px ${theme.vars.palette.divider}`,
					[`& .${buttonClasses.root}`]: {
						padding: theme.spacing(0.5, 1.5),
						'&:first-of-type': {
							border: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
						},
						'&:last-of-type': {
							color: theme.vars.palette.background.paper,
							backgroundColor: theme.vars.palette.text.primary,
						},
					},
				}),
				filterForm: ({ theme }) => ({
					alignItems: 'center',
					gap: theme.spacing(1.5),
					padding: theme.spacing(1),
				}),
				filterFormValueInput: {
					[`& .${formControlClasses.root}`]: {
						width: '100%',
					},
					[`& .${inputLabelClasses.root}`]: {
						transform: 'translate(14px, -9px) scale(0.75)',
					},
				},
			},
		},
	}
}
