enum EnrollmentEvent {
	EVERGREEN = 'enrollment.evergreen',
	LIFECHANGE = 'enrollment.lifechange',
	NEWHIRE = 'enrollment.newhire',
	OPENENROLLMENT = 'enrollment.openenrollment',
	PROGRAMSTART = 'enrollment.programstart', // bennies only
	SINGLE_PRODUCT = 'enrollment.singleproduct',
	BENEFICIARYUPDATE = 'enrollment.beneficiaryupdate',
}

export default EnrollmentEvent
