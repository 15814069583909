import { useAppSelector } from 'hooks/redux'
import { useScriptSrc } from 'hooks/use-script'
import { isLoggedInSelector } from 'store/user/selectors'
import { getEnv } from 'utils/env'

const getAccessTravelSource = () => {
	const env = getEnv && getEnv()
	switch (env) {
		case 'dev':
		case 'int':
		case 'test':
			return import.meta.env.VITE_ACCESSTRAVEL_SCRIPT_DEV
		default:
			return import.meta.env.VITE_ACCESSTRAVEL_SCRIPT_PROD
	}
}

export const useAccessTravel = () => {
	const isLoggedIn = useAppSelector(isLoggedInSelector)

	useScriptSrc(getAccessTravelSource(), isLoggedIn)
}
