import { Button, Typography } from '@mui/material'
import OfferModal from 'components/offer/offer-modal'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useState } from 'react'
import { routesMap } from 'routes/routes-map'
import { Offer } from 'types/offer'
import { Deal, DealsListProps } from '../../types'
import styles from './deals-list.module.css'

export function DealsList({ deals, cta, title, className, offers, limit }: DealsListProps) {
	const [showModal, setShowModal] = useState<boolean>(() => false)
	const [selectedOffer, setSelectedOffer] = useState<Offer | null>(() => null)
	const track = useTrackDetailedPageEvent()
	let dealsCount = 0

	const onDealClick = (deal: Deal) => {
		track({
			actionType: 'click',
			discountOfferId: deal.offerId,
			elementName: 'offer view cta',
			elementType: 'button',
		})
		setSelectedOffer(offers.find((o) => o.objectID === deal.offerId) ?? null)
		setShowModal(true)
	}

	const onAllDealsClick = () => {
		track({
			actionType: 'click',
			elementDetails: 'deals',
			elementName: 'see all deals',
			elementType: 'button',
		})
	}

	return (
		<>
			<div className={styles[className]}>
				<div className={styles[`${className}-content`]}>
					<Typography variant='h2' className={styles[`${className}-title`]}>
						{title}
					</Typography>
					<div className={styles.deals}>
						{deals.map((deal, index) => {
							if (dealsCount < limit && offers.some((o) => o.objectID === deal.offerId)) {
								dealsCount++

								return (
									<Button
										className={styles.deal}
										variant='text'
										color='inherit'
										key={index}
										onClick={() => onDealClick(deal)}
									>
										<img className={styles['deal-image']} src={deal.imageLink} alt={`deal ${index}`} />
										<div className={styles['deal-content']}>
											<Typography variant='h3-bold' className={styles['deal-heading']}>
												{deal.heading}
											</Typography>
											<Typography variant='h5' className={styles['deal-description']}>
												{deal.description}
											</Typography>
										</div>
									</Button>
								)
							}
						})}
					</div>
					{cta.visible && (
						<Button
							className={styles['deal-cta']}
							variant='outlined'
							color='inherit'
							href={routesMap.search + '?category=' + cta.category}
							onClick={onAllDealsClick}
						>
							{cta.title}
						</Button>
					)}
				</div>
			</div>
			{selectedOffer && (
				<OfferModal
					offer={selectedOffer}
					hideModal={(e: { stopPropagation: () => void }): void => {
						e.stopPropagation()
						setShowModal(false)
					}}
					showModal={showModal}
				/>
			)}
		</>
	)
}
