import { sliderClasses } from '@mui/material/Slider'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function slider(): CssVarsThemeOptions['components'] {
	return {
		MuiSlider: {
			styleOverrides: {
				root: ({ theme }) => ({
					[`&.${sliderClasses.disabled}`]: {
						color: theme.palette.action.disabled,
					},
				}),
				rail: {
					opacity: 0.32,
				},
				markLabel: ({ theme }) => ({
					fontSize: 13,
					color: theme.palette.text.disabled,
				}),
				valueLabel: ({ theme }) => ({
					borderRadius: 8,
					backgroundColor: theme.palette.grey[800],
				}),
			},
		},
	}
}
