import { ModuleInstanceProperty } from 'store/benefit-plans/types'

const withDeserializedProps = <P extends object>(
	Component: React.ComponentType<P>,
	mapFunction: (properties: ModuleInstanceProperty[]) => P,
): React.ComponentType<any> => {
	// eslint-disable-next-line react/display-name
	return ({ properties }: { properties: ModuleInstanceProperty[] }) => {
		const deserializedProps = mapFunction(properties)

		return <Component {...deserializedProps} />
	}
}

withDeserializedProps.displayName = 'withDeserializedProps'

export default withDeserializedProps
