import { createSelector } from 'reselect'

import { RootState } from 'store/rootReducer'
import { SiteConfig, SiteState } from './types'
import { PageSelectorReturnType, SiteInfoResponse } from 'types/tenant-site-types'

export const selectSite = (state: RootState): SiteState => state.site

const isSiteLoading = (site: SiteState): boolean => site.isSiteLoading
const siteInfo = (site: SiteState): SiteInfoResponse => site.siteInfo
const getSiteConfig = ({
	siteInfo: {
		properties: { hasAutoQuoting, hasDiscounts, hasVoluntaryBenefits, hasPayroll },
	},
}: SiteState): SiteConfig => ({ hasAutoQuoting, hasBenefits: hasVoluntaryBenefits, hasDiscounts, hasPayroll })
export const hasVBSelector = createSelector(
	(state: RootState) => state.site.siteInfo,
	(siteInfo: SiteInfoResponse): boolean => siteInfo.properties?.hasVoluntaryBenefits ?? false,
)
const isThemePrimary = (siteInfo: SiteInfoResponse): boolean => siteInfo.properties.theme === 'primary'
const getPageSelectorReturnType = (siteInfo: SiteInfoResponse): PageSelectorReturnType =>
	siteInfo.properties.getPageSelectorReturnType()

export const siteConfigSelector = createSelector(selectSite, getSiteConfig)
export const siteSelector = createSelector(selectSite, (site: SiteState): SiteState => site)
export const isSiteLoadingSelector = createSelector(selectSite, isSiteLoading)
export const siteInfoSelector = createSelector(selectSite, siteInfo)
export const isOfferingDiscountsSelector = createSelector(
	selectSite,
	(site: SiteState): boolean | undefined => site.siteInfo.properties.hasDiscounts,
)
export const isThemePrimarySelector = createSelector(siteInfoSelector, isThemePrimary)
export const pageSelector = createSelector(siteInfoSelector, getPageSelectorReturnType)
