import { useEffect, useRef } from 'react'

export const useScript = (file, shouldEnable: boolean): void => {
	const cancel = useRef(false)
	if (!cancel.current && shouldEnable) {
		const script = document.createElement('script')
		script.appendChild(document.createTextNode(file))

		document.body.appendChild(script)
	}
	useEffect(() => {
		return () => {
			cancel.current = true
		}
	}, [])
}

export const useScriptSrc = (src: string, shouldEnable: boolean): void => {
	const cancel = useRef(false)
	if (!cancel.current && shouldEnable) {
		const script = document.createElement('script')
		script.src = src
		script.async = true

		document.body.appendChild(script)
	}
	useEffect(() => {
		return () => {
			cancel.current = true
		}
	}, [])
}
