import { QueryClient } from '@tanstack/react-query'

export const queryClient: QueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			/**
			 * We chose to override the default refetchOnWindowFocus to false because the applicaiton
			 * doesn't need to have real time data. For features needing this, overrides can easily be made
			 * in the useQuery for that specific feature.
			 */
			refetchOnWindowFocus: false,
			retry: 3,
			retryOnMount: false,
			/**
			 * We chose to override the default staleTime of 0 to Infinity to prevent
			 * queries occuring after existing data was already fetched on the page.
			 *
			 * EG: the header first renders and uses the query to get data, then the body also uses the query.
			 * No need to fetch twice
			 *
			 * Link: https://react-query-v3.tanstack.com/guides/important-defaults#_top
			 */
			staleTime: Infinity,
		},
	},
})
