import { axiosInstance } from 'config/axios'
import UrlBuilder from './util/url-builder'

const urlBuilder: UrlBuilder = new UrlBuilder()

export default class RecommendedOfferService {
	static getRecommendedOfferIds(): Promise<Array<string>> {
		return axiosInstance.get(urlBuilder.setEndpoint('DiscountOffers/Recommended').url())
	}
}
