import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ExternalStoreLoadingStatus } from './types'

export type ExternalStoreState = {
	isRemoveNeeded: boolean
	isSavingNeeded: boolean
	loadingStatus: ExternalStoreLoadingStatus
}

const initialState: ExternalStoreState = {
	isRemoveNeeded: false,
	isSavingNeeded: false,
	loadingStatus: ExternalStoreLoadingStatus.Pending,
}

const externalStoreSlice = createSlice({
	initialState,
	name: 'externalStore',
	reducers: {
		setIsRemoveNeeded: (state, action: PayloadAction<boolean>) => {
			state.isRemoveNeeded = action.payload
		},
		setIsSavingNeeded: (state, action: PayloadAction<boolean>) => {
			state.isSavingNeeded = action.payload
		},
		setLoadingStatus: (state, action: PayloadAction<ExternalStoreLoadingStatus>) => {
			state.loadingStatus = action.payload
		},
	},
})

const { actions, reducer } = externalStoreSlice
export { actions, reducer as externalStore }
