import { categories, events } from 'config/analytics'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard'
import { useTrackEvent } from 'hooks/use-track-event'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MouseEvent, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { trackView } from 'services/track-view-service'
import { AnalyticsOffer } from 'types/analytics-offer'
import { Offer } from 'types/offer'
import { isRelativeLink } from 'utils/url/isRelativeLink'
import { openLinkInNewTab } from 'utils/utils'
import { useOfferCode } from './useOfferCode'
import { useOfferUrl } from './useOfferUrl'

/**
 * tracks the offer view and opens the offer link in a new tab
 * @param source - the source of the offer redemption
 */
export function useOfferRedeemHandler(offer: Offer, source: string) {
	const { b4b_10023_custom_event_migration } = useFlags()
	const trackEvent = useTrackEvent()
	const track = useTrackDetailedPageEvent()
	const copy = useCopyToClipboard()
	const offerUrl = useOfferUrl(offer)
	const offerCode = useOfferCode(offer)
	const navigate = useNavigate()

	return useCallback(
		async (e: MouseEvent | KeyboardEvent): Promise<void> => {
			e.stopPropagation()

			if (offerCode) {
				copy(offerCode)
			}

			const url = await offerUrl
			const analyticsOffer: AnalyticsOffer = new AnalyticsOffer({ ...offer, parentCategory: categories.discounts })

			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'click',
					discountOfferId: offer.objectID,
					elementDetails: source,
					elementName: 'offer click cta',
					elementType: 'button',
				})
			} else {
				// TODO-10023 - documented by CK - https://corestreamcorp.sharepoint.com/:x:/s/Incognito/EXVNmU8aNc1BsIZtJe7VrZsB7YOMOY1s9pVvcbaBGIe9ZQ?e=3ZRp3P&nav=MTJfJEYkMjdfezIxMDYxRDZELUNBMUItNEEwQy1BOTVDLTIyQjE1ODFGQzEzQX0
				trackEvent(events.product.clicked, {
					corestreamProductId: analyticsOffer.product_id,
					...analyticsOffer,
					source,
				})
			}

			trackView(offer)

			if (isRelativeLink(url)) {
				navigate(url)

				return
			}

			openLinkInNewTab(e, url)
		},
		[b4b_10023_custom_event_migration, copy, navigate, offer, offerCode, offerUrl, source, track, trackEvent],
	)
}
