//@index(['./*', '!./**.{test,stories}.*', '!./{__test__,test}', '!./**.scss' ], f => `export * from '${f.path}'`)
//@endindex

import { RateRequestBoolVals } from 'api/benefit-elections/types'
import { BenefitItem, CartItem, NwPetItem, QuestionAnswer } from 'store/cart/types/cartItemTypes'

export type QuestionKey = {
	questionId?: string
	fieldDefinitionId?: number
}

export type BenefitsUpdate = {
	planIds: number[]
	data: Partial<CartItem>
}

/**
 * base action type with a type and action
 */
export interface BenefitAction<D, T> {
	data: D
	type: T
}

/**
 * Submit benefit types, this is used to determine type safety for the submit action
 */
export const SubmitBenefitTypes = ['native', 'trustmark', 'pet', 'non-native'] as const
export type SubmitBenefitType = (typeof SubmitBenefitTypes)[number]

// TODO:pdp - with questions being an optional field under benefit, we will have a difficult time
// persisting answers between benefits
// TODO:pdp - RFC - should questions live in its own slice or should it be a unique property on the
// benefit enrollment slice that will not get cleared out between benefits?
export type BenefitEnrollmentState = {
	benefit: CartItem | null
	meta: {
		isEditingBeneficiaries: boolean
		showAllRequiredQuestions: boolean
		showBeneficiaries: boolean
	}
	shared: SharedBenefitEnrollmentState
	rate: {
		params: RateRequestBoolVals
		selected: boolean
	}
	questions: {
		answered: boolean
	}
}

export type SharedBenefitEnrollmentState =
	/**
	 * Support employee to enter who's enrolling by default (whole family - just self) across questions
	 */
	Partial<Pick<BenefitItem, 'tier' | 'tierId'>> &
		/**
		 * support one time pet entry across benefits
		 *
		 * NOTE: this could/should live under `persons` or a `constituents` slice
		 */
		Pick<NwPetItem, 'pets'> & {
			questions: QuestionAnswer[]
		}

export const initialState: BenefitEnrollmentState = {
	benefit: null,
	meta: {
		isEditingBeneficiaries: false,
		showAllRequiredQuestions: false,
		showBeneficiaries: false,
	},
	questions: {
		answered: false,
	},
	rate: {
		params: {
			benefitPlanId: -1,
			keyDate: new Date().toDateString(),
		},
		selected: false,
	},
	shared: {
		pets: [],
		questions: [],
	},
}
