import { axiosInstance as axios, petAxiosInstance } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'
import { API_SUBDOMAIN, endpoints } from '../constants'
import {
	PetEnrollmentSaveV2,
	SaveEnrollmentResponseV2,
	SaveEnrollmentV2,
	SavePersonalV2,
	StartEnrollmentPropsV2,
	UserEnrollmentRes,
	UserNewHireInfo,
} from '../types'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class BenefitElectionsService {
	static deleteProgramEnrollmentById(programEnrollmentId: string): Promise<void> {
		const endpoint = endpoints.BenefitElections.deleteByProgramEnrollmentId(programEnrollmentId)

		return axios.delete(urlBuilder.setEndpoint(endpoint).url())
	}

	static startEnrollmentV2(props: StartEnrollmentPropsV2): Promise<SaveEnrollmentResponseV2> {
		const startEndpoint = endpoints.BenefitElections.startEnrollment()

		return axios.post(urlBuilder.setEndpoint(startEndpoint).url(), props)
	}

	static verifyNewHireDateForStartingEnrollment(): Promise<SaveEnrollmentResponseV2> {
		const endpoint = endpoints.BenefitElections.verifyNewHireDate()

		return axios.post(urlBuilder.setEndpoint(endpoint).url())
	}

	static saveEnrollmentV2(enrollment: SaveEnrollmentV2): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint: string = endpoints.BenefitElections.saveEnrollment.all()

		return axios.post(urlBuilder.setEndpoint(saveEndpoint).url(), enrollment)
	}

	static saveEnrollmentPersonsV2(enrollment: SavePersonalV2): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint = endpoints.BenefitElections.saveEnrollment.persons()

		return axios.post(urlBuilder.setEndpoint(saveEndpoint).url(), enrollment)
	}

	static userNewHireInfo(): Promise<UserNewHireInfo> {
		const endpoint = endpoints.BenefitElections.userNewHireInfo()

		return axios.post(urlBuilder.setEndpoint(endpoint).url())
	}

	/**
	 *
	 * @param petData Data formatted to save for pet enrollments
	 * @returns Standard save enrollment response
	 */
	static savePetEnrollmentV2(
		petData: PetEnrollmentSaveV2 | Omit<PetEnrollmentSaveV2, 'profileId'>,
	): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint: string = endpoints.BenefitElections.saveEnrollment.pets()

		return petAxiosInstance.post(urlBuilder.setEndpoint(saveEndpoint).url(), petData)
	}

	static submitEnrollmentV2(
		payload: { enrollmentId: string; terms: string; trustmarkCoverageTiers?: string[] }, // ["Employee", "Spouse"]
	): Promise<{
		confirmationId: string
		enrollmentId: string
		programEnrollmentId: string
		additionalEvents: Array<string>
	}> {
		const submitEndpoint: string = endpoints.BenefitElections.submitEnrollment()

		return axios.post(urlBuilder.setEndpoint(submitEndpoint).url(), payload)
	}

	static getEnrollmentOverview(): Promise<UserEnrollmentRes[]> {
		const url = urlBuilder.setEndpoint(endpoints.BenefitElections.overview()).url()

		return axios.get(url)
	}
}
