import MuiAddIcon from '@mui/icons-material/Add'
import MuiRemoveIcon from '@mui/icons-material/Remove'
import styled from 'styled-components'

export const RemoveIcon = styled(MuiRemoveIcon)`
	color: ${(props): string => props.theme.primaryColor};
	border: 0.063rem solid ${(props): string => props.theme.primaryColor};
	border-radius: 3.125rem;
`

export const AddIcon = styled(MuiAddIcon)`
	color: ${(props): string => props.theme.primaryColor};
	border: 0.063rem solid ${(props): string => props.theme.primaryColor};
	border-radius: 3.125rem;
`
