import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function breadcrumbs(): CssVarsThemeOptions['components'] {
	return {
		MuiBreadcrumbs: {
			styleOverrides: {
				separator: ({ theme }) => ({
					marginLeft: theme.spacing(2),
					marginRight: theme.spacing(2),
				}),
				li: ({ theme }) => ({
					display: 'inline-flex',
					margin: theme.spacing(0.25, 0),
					'& > *': {
						...theme.typography.body2,
					},
				}),
			},
		},
	}
}
