import { useClickedOffers } from 'api/tenants/queries'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { useVisitedPlans } from '../useVisitedPlans'

/**
 * Given all products related to a product type, determine if any of the products have been visited in the past 30 days.
 *
 * The second argument (today) is used to make it easier to write tests.
 */
export const useHasVisitedProductType = (benefitPlans: BenefitPlanV2[], today: Date = new Date()) => {
	const { data } = useClickedOffers()

	const visited = useVisitedPlans(benefitPlans, today)

	if (!data) {
		return false
	}

	return visited.length > 0
}
