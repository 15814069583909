import { ModuleInstanceProperty } from 'store/benefit-plans/types'
import { tryParseJSON } from 'utils/utils'

/**
 * Hook to deserialize the properties of a module instance to a specific given type
 * @param properties The properties of a module instance
 * @returns Type T with the properties deserialized
 */
export const usePropertyToTypeMapping = <T>(properties: ModuleInstanceProperty[]): T => {
	return properties?.reduce((acc, property) => {
		acc[property.propertyName as keyof T] = tryParseJSON(property.propertyValue) as T[keyof T]

		return acc
	}, {} as T)
}
