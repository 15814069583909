import { ElectionStatus, EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { match } from 'ts-pattern'
import { UserElection } from 'types/user-elections'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { ParsedOverviewData } from './types'

/**
 * Overview data has several items for the same benefit plan. Here we are
 * creating a new object with the unique key(benefitPlanId) for each plan and adding an
 * array of all the matching plans as its value
 * @param overviewArray
 * @returns
 */
const normalizeOverview = (
	overviewArray: UserEnrollmentRes[],
	availablePlans: BenefitPlanV2[],
): Record<number, UserEnrollmentRes[]> => {
	/**
	 * handle DNN enrollments by changing their benefitplanIds to match what
	 * available plans have to prevent mis-matched ids throughout the app
	 */
	const getEnrollmentKey = (programId: number) => {
		// If programId is 0 then enrollment is non-native and must be indexed on -1
		if (programId === 0) {
			return -1
		}

		const planByProgramId = availablePlans.find((p) => p.programId === programId)
		if (planByProgramId) return planByProgramId.benefitPlanId

		return programId
	}

	const overviewData = overviewArray.reduce((acc, bp) => {
		const enrollmentKey = getEnrollmentKey(bp.programId)
		if (!acc[enrollmentKey]) {
			return { ...acc, [enrollmentKey]: [{ ...bp, benefitPlanId: enrollmentKey }] }
		}

		if (acc[enrollmentKey]) {
			const planData = acc[enrollmentKey]

			return { ...acc, [enrollmentKey]: [...planData, { ...bp, benefitPlanId: enrollmentKey }] }
		}

		return acc
	}, {})

	sortOverviewData(overviewData)

	return overviewData
}

const sortOverviewData = (data) => {
	Object.values(data).forEach((elem: any) => {
		elem.sort((a, b) => {
			const dateA = new Date(a.submittedOn).valueOf()
			const dateB = new Date(b.submittedOn).valueOf()

			return dateA - dateB
		})
	})
}

export const parseOverviewData = (
	overviewData: UserEnrollmentRes[],
	availablePlans: BenefitPlanV2[],
): ParsedOverviewData => {
	const userEnrollmentOverview = normalizeOverview(overviewData, availablePlans)

	const allLatestUserEnrollments = {}
	const planIds = Object.keys(userEnrollmentOverview)
	planIds.forEach((planId) => {
		// filter out non-natives
		if (planId !== '-1') {
			const enrollment = [...userEnrollmentOverview[planId]].pop()
			const sortedDependents = enrollment.dependents
				? [...enrollment.dependents].sort((a, b) => {
						if (b.relationship > a.relationship) return 1
						if (b.relationship < a.relationship) return -1

						return 0
				  })
				: []

			return (allLatestUserEnrollments[planId] = {
				...enrollment,
				dependents: sortedDependents,
			})
		}
	})

	return { allLatestUserEnrollments, userEnrollmentOverview }
}

/**
 * Overview data now contains an individual record for each enrolled pet
 * this method handles parsing that data for display
 * @param elections UserElection array
 * @returns object with benefit plan as key and array of elections as values
 */
export const parseElectionData = (elections: UserElection[]): Record<number, UserEnrollmentRes[]> => {
	/**
	 * Parses ElectionStatus values to EnrollmentStatus values
	 * NOTE: this is a stopgap fix until we swap over completely to ElectionStatus
	 * @param status EnrollmentStatus
	 * @returns EnrollmentStatus
	 */
	const parseStatusCode = (status): EnrollmentStatus => {
		return match({
			isCanceledStatusType: status === ElectionStatus.CANCELLED || status === ElectionStatus.TERMINATED,
			isEnrollmentStatusType: Object.values(EnrollmentStatus).includes(status),
		})
			.with(
				{
					isCanceledStatusType: true,
				},
				() => EnrollmentStatus.CANCELED,
			)
			.with(
				// no need to parse
				{
					isEnrollmentStatusType: true,
				},
				() => status,
			)
			.otherwise(() => EnrollmentStatus[status])
	}
	const formatToUserEnrollment = (election: UserElection): UserEnrollmentRes => {
		return {
			...election,
			benefitPlanImageUrl: '',
			collectDependents: false,
			collectDisabled: false,
			collectSSN: false,
			coverage: election.coverageCode,
			declined: false,
			history: election.history?.map((be) => formatToUserEnrollment(be)) ?? [],
			logoUrl: '',
			nonNativeLinkUrl: '',
			planEffectiveDate: election.startDate.toString(),
			programEnrollmentId: election.corestreamPolicyId, // TODO: Persist corestreamPolicyId rename
			programId: 0,
			rate: election.rate?.toString() ?? '',
			statusCode: parseStatusCode(election.statusCode),
			submittedOn: election.createdDate.toString(),
			tier: election.tierCode,
			tierTitle: election.tierLabel,
		}
	}

	// Overview endpoint returns a list of users elections grouped by benefitplanid
	// We map it here to a record with bp id as key and elections of that bp as its value, sorted by effective date
	// This will go away when we fully use the new structure

	return elections.reduce((acc, bp) => {
		const benefitPlanHistory = formatToUserEnrollment(bp)

		if (bp.history && bp.history?.length > 0) {
			benefitPlanHistory.history = [...bp.history.map((x) => formatToUserEnrollment(x))]
		}

		//elections with multiple current records(i.e. pet) with need the records added under the same key
		acc[bp.benefitPlanId]
			? acc[bp.benefitPlanId].push(benefitPlanHistory)
			: (acc[bp.benefitPlanId] = [benefitPlanHistory])

		return acc
	}, {})
}
