import { getFlatSortedOverview } from 'hooks/oe'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { EnrollmentStatusUtilities } from 'utils/benefit-plans/enrollment-status'
import { BenefitEnrollmentInfos } from '../types'

export function getPlanEnrollmentInfo(und3484: any, overviewData: UserEnrollmentRes[]): BenefitEnrollmentInfos {
	const enrollments = und3484 ? getFlatSortedOverview(overviewData) : overviewData

	const latestEnrollment = enrollments?.[enrollments.length - 1]

	// find the latest effective enrollment - escape early if cancelled
	let effectiveEnrollment: UserEnrollmentRes | undefined = undefined

	if (enrollments) {
		const enrollmentsReversed = [...enrollments].reverse()
		// from latest to oldest - find the first enrolled plan
		// escape(return:null) if there is a declined plan
		// escape(return:plan) if there is a future canceled plan
		for (let i = 0; i < enrollmentsReversed.length; i++) {
			const item = enrollmentsReversed[i]

			// if there is a declined plan, return null
			if (EnrollmentStatusUtilities.isDeclined(item.statusCode)) {
				// for clarity
				effectiveEnrollment = undefined
				break
			}

			// if the next item is a past cancelled item - return null
			if (new Date(item.planEffectiveDate) <= new Date() && EnrollmentStatusUtilities.isCanceled(item.statusCode)) {
				// for clarity
				effectiveEnrollment = undefined
				break
			}

			// if there is a future canceled plan, return that plan
			if (new Date(item.planEffectiveDate) > new Date() && EnrollmentStatusUtilities.isCanceled(item.statusCode)) {
				effectiveEnrollment = item
				break
			}

			// if there is a currently enrolled plan, return that plan
			if (new Date(item.planEffectiveDate) <= new Date() && EnrollmentStatusUtilities.isEnrolled(item.statusCode)) {
				effectiveEnrollment = item
				break
			}
		}
	}

	let latestEnrolledEnrollment = [...enrollments]
		?.reverse()
		.find((plan) => EnrollmentStatusUtilities.isEnrolled(plan.statusCode))
	const latestNonLeavedEnrolledEnrollment = [...enrollments]
		?.reverse()
		.slice(1)
		.find((plan) => EnrollmentStatusUtilities.isEnrolledOrCancelled(plan.statusCode))

	const earliestEnrolledEnrollment = [...enrollments].find((plan) =>
		EnrollmentStatusUtilities.isEnrolled(plan.statusCode),
	)

	const earliestCanceledEnrollment = [...enrollments].find((plan) =>
		EnrollmentStatusUtilities.isCanceled(plan.statusCode),
	)

	// This will ensure that a canclled or a leaved plan will have the proper data still.
	// A Leaved (and sometimes cancelled) plan will not have all the data needed to be displayed
	if (
		(latestEnrolledEnrollment?.statusCode === EnrollmentStatus.LEAVED ||
			latestEnrolledEnrollment?.statusCode === EnrollmentStatus.CANCELED) &&
		latestNonLeavedEnrolledEnrollment
	) {
		latestEnrolledEnrollment = {
			...latestEnrolledEnrollment,
			coverageTitle: latestNonLeavedEnrolledEnrollment.coverageTitle,
			rate: latestNonLeavedEnrolledEnrollment.rate,
			rateFrequency: latestNonLeavedEnrolledEnrollment.rateFrequency,
			tierTitle: latestNonLeavedEnrolledEnrollment.tierTitle,
		}
	}

	return {
		// Do not use these! Only used for stacked policies which only Sandia has
		earliestCanceledEnrollment,
		earliestEnrolledEnrollment,
		// --
		effectiveEnrollment,
		latestEnrolledEnrollment,
		latestEnrollment,
	}
}
