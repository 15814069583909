import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from './rootReducer'

type ErrorState = {
	errorMessage: string
	shouldAutoHide: boolean
	showError: boolean
}
const initialState: ErrorState = {
	errorMessage: '',
	shouldAutoHide: true,
	showError: false,
}

const errorSlice = createSlice({
	initialState,
	name: 'error',
	reducers: {
		setShowErrorMessage: (state, action): void => {
			state.errorMessage = action.payload.errorMessage
			state.shouldAutoHide = action.payload?.shouldAutoHide ?? true
			state.showError = action.payload.showError
		},
	},
})

const { actions, reducer: error } = errorSlice
const errorStateSelector = createSelector(
	(state: RootState) => state.error,
	(errorState: ErrorState): ErrorState => errorState,
)

export { actions, error, errorStateSelector }
