import { createContext, useContext } from 'react'

export type AlgoliaEventType = 'view' | 'click' | 'conversion'

export type AlgoliaTrackingProps = {
	searchIndex: string
	eventType?: AlgoliaEventType
	position?: number
	objectId?: string
	queryId?: string
}

/**
 * A read heavy context, used for adding shared context around algolia click tracking / recommendations / ai / etc
 */
export const AlgoliaTracking = createContext({} as AlgoliaTrackingProps)

export const useAlgoliaTrackingContext = () => {
	const context = useContext(AlgoliaTracking)

	return context
}
