export const API_SUBDOMAIN = 'userinsights'

export const endpoints = {
	recommendations: getformEndpoints(),
}

function getformEndpoints() {
	const uiEndpoints = {
		all: 'UserRecommendation' as const,
		clearUserRcommendation: () => `${uiEndpoints.all}` as const,
		getUserRcommendation: () => `${uiEndpoints.all}` as const,
	}

	return uiEndpoints
}
