import { useClickedOffers } from 'api/tenants/queries'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { getVisitedBenefitPlans } from 'utils/benefit-plans/get-visited-benefit-plans'

/**
 * Retrieve a set of visited plans from the preconfigured visited plans feature
 * @param plans a set of plans to check against
 * @returns a set of visited plans seen within 30 days per getVisitedBenefitPlans
 */
export function useVisitedPlans(plans: BenefitPlanV2[], today = new Date()) {
	const { data } = useClickedOffers()

	const visited = getVisitedBenefitPlans(plans, data?.offerClickedDates || {}, today)

	return visited
}
