import { EnrollmentStatus } from 'pages/enrollment-wizard/types'

export class EnrollmentStatusUtilities {
	static enrollingStatuses = [
		EnrollmentStatus.NEW,
		EnrollmentStatus.LEAVED,
		EnrollmentStatus.ENROLLED,
		EnrollmentStatus.PENDING,
	]
	static enrollingOrCancelledStatuses = [
		EnrollmentStatus.NEW,
		EnrollmentStatus.LEAVED,
		EnrollmentStatus.ENROLLED,
		EnrollmentStatus.CANCELED,
		EnrollmentStatus.PENDING,
	]
	static enrolledStatuses = [EnrollmentStatus.LEAVED, EnrollmentStatus.ENROLLED]
	static enrolledOrCancelledStatuses = [EnrollmentStatus.ENROLLED, EnrollmentStatus.CANCELED]
	static canceledStatuses = [EnrollmentStatus.CANCELED]
	static pendingStatuses = [EnrollmentStatus.PENDING]

	/**
	 * New, Enrolled, Leaved
	 */
	static isEnrolling(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.enrollingStatuses.includes(status)
	}

	/**
	 * New, Enrolled, Leaved, Cancelled
	 */
	static isEnrollingOrCancelled(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.enrollingOrCancelledStatuses.includes(status)
	}

	/**
	 * Enrolled, Leaved
	 */
	static isEnrolled(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.enrolledStatuses.includes(status)
	}

	/**
	 * Enrolled, Cancelled
	 */
	static isEnrolledOrCancelled(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.enrolledOrCancelledStatuses.includes(status)
	}

	/**
	 * Canceled
	 */
	static isCanceled(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.canceledStatuses.includes(status)
	}

	/**
	 * Declined
	 */
	static isDeclined(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return status === EnrollmentStatus.DECLINED
	}

	/**
	 * Pending
	 */
	static isPending(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return EnrollmentStatusUtilities.pendingStatuses.includes(status)
	}

	/**
	 * Leaved
	 */
	static isLeaved(status: EnrollmentStatus | undefined): boolean {
		if (!status) return false

		return status === EnrollmentStatus.LEAVED
	}
}
