import { Button, Typography } from '@mui/material'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { BenefitSectionProps } from '../../types'
import styles from './benefit-section.module.css'

function formatCodeMessage(code: string, codeMessage: string) {
	const [before, after] = codeMessage.split('${code}')

	return (
		<>
			{before}
			<strong>{code}</strong>
			{after}
		</>
	)
}

export function BenefitSection({ benefitContent, benefit }: BenefitSectionProps) {
	const track = useTrackDetailedPageEvent()

	const onBenefitClick = () => {
		track({
			actionType: 'click',
			benefitPlanId: benefit.benefitPlanId,
			elementName: 'benefit cta',
			elementType: 'button',
		})
	}

	return (
		<div className={styles.benefit}>
			<div className={styles['benefit-content']}>
				<div className={styles['benefit-content-wrapper']}>
					<div className={styles['benefit-header']}>
						<img className={styles['benefit-image']} src={benefit.benefitProviderImage} alt='img' />
						<Typography variant='h2' className={styles['benefit-title']}>
							{benefitContent.title}
						</Typography>
					</div>
					<div className={styles['benefit-details']}>
						<div className={styles['benefit-details-copy']}>
							<Typography variant='h5-body' className={styles['benefit-subtitle']}>
								{benefitContent.subtitle}
							</Typography>
							<Typography variant='h5-body' className={styles['benefit-subtitle']}>
								{formatCodeMessage(benefitContent.code, benefitContent.codeMessage)}
							</Typography>
						</div>
						<div className={styles['benefit-cta-container']}>
							{benefitContent.cta.visible && (
								<Button
									className={styles['benefit-cta']}
									variant='outlined'
									color='inherit'
									onClick={onBenefitClick}
									href={benefit.effectiveMarketingContent?.ctaUrl ?? ''}
									target='_blank'
								>
									{benefitContent.cta.title}
								</Button>
							)}
						</div>
					</div>
				</div>
				<div className={styles['benefit-footer']}>
					<img className={styles['benefit-footer-image']} src={benefitContent.imageLink} alt='img' />
				</div>
			</div>
		</div>
	)
}
