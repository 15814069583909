import { KeyboardEvent } from 'react'

export function onKeyDownEventFactory<T extends HTMLElement>(
	onClick: (e?: KeyboardEvent<T>) => void,
	keys: string[] = ['Enter', 'Space'],
) {
	return function (event: KeyboardEvent<T>) {
		if (keys.includes(event.key)) {
			onClick(event)
		}
	}
}
