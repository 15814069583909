// snake case conversion
// spaces -> underscores
// lowercase everything
// also replace special characters with underscores
export function spaceToSnakeCase(str: string) {
	return str
		.toLowerCase()
		.replace(/\s/g, '_')
		.replace(/[^a-z0-9_]/g, '_')
		.replace(/_+$/, '')
		.replace(/^_+/, '')
}
