import { accordionClasses } from '@mui/material/Accordion'
import { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import { typographyClasses } from '@mui/material/Typography'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function accordion(): CssVarsThemeOptions['components'] {
	return {
		MuiAccordion: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: 'transparent',
					[`&.${accordionClasses.expanded}`]: {
						// boxShadow: theme.customShadows.z8,
						borderRadius: theme.shape.borderRadius,
						backgroundColor: theme.palette.background.paper,
					},
					[`&.${accordionClasses.disabled}`]: {
						backgroundColor: 'transparent',
					},
				}),
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: ({ theme }) => ({
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(1),
					[`&.${accordionSummaryClasses.disabled}`]: {
						opacity: 1,
						color: theme.palette.action.disabled,
						[`& .${typographyClasses.root}`]: {
							color: 'inherit',
						},
					},
				}),
				expandIconWrapper: {
					color: 'inherit',
				},
			},
		},
	}
}
