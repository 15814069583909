import { benefitRoutes } from 'routes/routes-map'
import { BenefitPlanV2 } from 'store/benefit-plans/types'

export const useGetBenefitPlanUrl = () => {
	function getBenefitProductPageLink(bp: BenefitPlanV2) {
		return benefitRoutes.benefit.plan(bp.benefitPlanId)
	}

	return getBenefitProductPageLink
}
