import { oidcSettings } from 'config/auth'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { UserManager } from 'oidc-client'
import { createUserManager } from 'redux-oidc-js'
import { IDLE } from 'types/status-types'
import { MyProfileUser } from './selectors'

export type UserState = {
	clickedOfferIds: Array<string>
	clickedOfferIdsStatus: StateMachineStatus
	error: Error | null
	isLoadingUser: boolean
	isLoggedIn: boolean
	isRecommendedOffersLoading: StateMachineStatus
	isUserProfileLoaded: boolean
	recommendedOfferIds: Array<string>
	updatingProfileStatus: StateMachineStatus
	userManager: UserManager
	userProfile: MyProfileUser
	accessTokenReady: boolean
}

export const initialState: UserState = {
	accessTokenReady: false,
	clickedOfferIds: [],
	clickedOfferIdsStatus: IDLE,
	error: null,
	isLoadingUser: true,
	isLoggedIn: false,
	isRecommendedOffersLoading: IDLE,
	isUserProfileLoaded: false,
	recommendedOfferIds: [],
	updatingProfileStatus: IDLE,
	userManager: createUserManager(oidcSettings()),
	userProfile: new MyProfileUser(),
}
