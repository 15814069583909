import { axiosInstance as axios } from 'config/axios'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { DeductableHistory } from 'types/deductibles-history'
import { QLEEventsList } from 'types/enrollment-profile'
import { LTCEnrollmentResponse } from 'types/ltc-enrollment-response'
import { PetBreed } from 'types/pet'
import { UserEnrollmentRes } from 'types/user-enrollment'
import UrlBuilder from './util/url-builder'

const enrollmentsSubdomain: string = 'enrollments'
const urlBuilder: UrlBuilder = new UrlBuilder(enrollmentsSubdomain)
export enum Endpoints {
	BenefitElections = 'BenefitElections',
	BenefitPlans = 'BenefitPlans',
	ClearEnrollments = 'ClearEnrollments',
	ClearEnrollmentsAndDependents = 'ClearEnrollmentsAndDependents',
	BackdateEnrollments = 'BackdateEnrollments',
	EnrollmentData = 'EnrollmentData',
	EnrollmentProfiles = 'EnrollmentProfiles',
	EnrollmentReporting = 'EnrollmentReporting',
	LTC = 'LTC',
	NationwidePetBreeds = 'NationwidePetBreeds',
	Overview = 'Overview',
	Questions = 'Questions',
	Rates = 'Rates',
	StartEnrollment = 'StartEnrollment',
	SaveEnrollment = 'SaveEnrollment',
	SubmitEnrollment = 'SubmitEnrollment',
	UserAddressInfo = 'UserAddressInfo',
	UserNewHire = 'UserNewHireInfo',
	VerifyNewHireDate = 'VerifyNewHireDateForStartingEnrollment',
	VPI = 'VPI',
	SendThirdPartyConfirmationEmail = 'SendThirdPartyConfirmationEmail',
}

export type WizardValidationErrors = {
	code: string
	entityId: string
	group: string
	severity: string
	text: string
}

export type SaveEnrollmentResponseV2 = {
	benefitPlan: BenefitPlanV2
	confirmationId: string
	enrollmentId: string
	programEnrollmentId: string
	validationResults: Array<WizardValidationErrors>
}

export type ThirdPartyConfirmationEmailRequest = {
	benefitPlanId: number
	effectiveDate: Date
	amount: string
	tier: string
	deductionPeriod?: string
}

export type UserNewHireInfo = {
	isNewHire: boolean
	isProgramStart?: boolean
	newHireQualificationStartDate: string
	newHireQualificationEndDate: string
}

export class EnrollmentService {
	static clearEnrollments(): Promise<unknown> {
		const clearEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.ClearEnrollments}`

		return axios.post(urlBuilder.setEndpoint(clearEndpoint).url())
	}

	static clearEnrollmentsAndDependents(): Promise<unknown> {
		const clearEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.ClearEnrollmentsAndDependents}`

		return axios.post(urlBuilder.setEndpoint(clearEndpoint).url())
	}

	static backdateEnrollments(): Promise<unknown> {
		const backdateEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.BackdateEnrollments}`

		return axios.post(urlBuilder.setEndpoint(backdateEndpoint).url())
	}

	static getQLEEvents(employerAccountCode: string): Promise<QLEEventsList> {
		const endpoint = `${Endpoints.EnrollmentProfiles}/${employerAccountCode}/QLE/Events`

		return axios.get(urlBuilder.setEndpoint(endpoint).url())
	}

	/**
	 *
	 * @param startDate - the date to start getting history for (most recent)
	 * @param endDate - the date to stop getting history for (furthest back)
	 * @param ppdsProductCode - the product code for the benefit
	 * @returns deduction history for a user
	 */
	static getDeductionsV2(
		startDate: Date,
		endDate: Date,
		ppdsProductCode?: string,
		signal?: AbortSignal,
	): Promise<DeductableHistory> {
		const submitEndpoint: string = new UrlBuilder(enrollmentsSubdomain)
			.setEndpoint('api/worker/DeductionHistory')
			.setQueryParams(
				new URLSearchParams({
					endDate: endDate.toISOString(),
					ppdsProductCode: ppdsProductCode ?? '',
					startDate: startDate.toISOString(),
				}),
			)
			.url()

		return axios.get(submitEndpoint, { signal })
	}

	static getMockedDeductions(demoMyDeductions: string): DeductableHistory {
		const today = new Date()

		const mockDeductionHistory = JSON.parse(demoMyDeductions)

		for (const item of mockDeductionHistory.items) {
			item.deductionDate = new Date(today.getFullYear(), today.getMonth() - item.deductionDateDifference, 1).toString()
		}

		return mockDeductionHistory
	}

	static getEnrollmentData(signal?: AbortSignal): Promise<LTCEnrollmentResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`${Endpoints.LTC}/${Endpoints.EnrollmentData}`)

		return axios.get(endpoint.url(), { signal })
	}

	static getNationwidePetBreeds(speciesCode: string): Promise<PetBreed[]> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`api/${Endpoints.VPI}/${Endpoints.NationwidePetBreeds}`)
		endpoint.setQueryParams(new URLSearchParams({ species: speciesCode }))

		return axios.get(endpoint.url())
	}

	static getEnrollmentOverview(): Promise<UserEnrollmentRes[]> {
		const url = urlBuilder.setEndpoint(`${Endpoints.BenefitElections}/${Endpoints.Overview}`).url()

		return axios.get(url)
	}

	static sendThirdPartyEnrollmentEmail(request: ThirdPartyConfirmationEmailRequest): Promise<void> {
		const endPoint: string = `${Endpoints.EnrollmentReporting}/${Endpoints.SendThirdPartyConfirmationEmail}`

		return axios.post(urlBuilder.setEndpoint(endPoint).url(), request)
	}
}
