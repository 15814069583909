import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { pageSelector, siteInfoSelector } from '../../store/site/selectors'
import { PageSelectorReturnType, SiteInfoResponse } from '../../types/tenant-site-types'
import styles from './app-footer.module.css'

const AppFooter = () => {
	const { customerCarePhone, customerCareEmail }: PageSelectorReturnType = useSelector(pageSelector)
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)

	return (
		<footer id='footer' className={styles['footer']}>
			<div className={styles['left']}>
				<div className={styles['logo-container']}>
					<a href='https://www.corestream.com' target='_blank' rel='noreferrer' title='Visit Corestream website'>
						<img
							alt='Corestream'
							src='https://cdn2.corestream.com/tenantimages/corestream-logo-full-color.svg'
							className={styles['logo']}
							loading='lazy'
						/>
					</a>
				</div>
				<ul className={styles['links']}>
					<li>
						<Link to='/faq' className={styles['text']}>
							FAQ & Help
						</Link>
					</li>
					<li>
						<Link to='/data-use-policy' className={styles['text']}>
							Data Use Policy
						</Link>
					</li>
					<li>
						<Link to='/terms' className={styles['text']}>
							Terms Of Use
						</Link>
					</li>
					<li>
						<Link to='https://corp.corestream.com/privacy-policy/' target='_blank' className={styles['text']}>
							Privacy Policy
						</Link>
					</li>
				</ul>
			</div>
			<div className={styles['right']}>
				<Typography variant='h5' component='p' className={styles['support-container']}>
					<div className={styles['support-schedule']}>Support: M-F 8:30am-8:00pm ET</div>
				</Typography>
				<Typography variant='h5' component='p' className={styles['support-container']}>
					<a href={`mailto:${customerCareEmail}`} target='_blank' rel='noopener noreferrer'>
						{customerCareEmail}
					</a>
					{!siteInfo.properties.excludeCSPhoneNumber && (
						<>
							<Typography className={styles['vertical-break']}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Typography>
							<a href={`tel:${customerCarePhone}`} rel='noopener noreferrer' target='_blank'>
								{customerCarePhone}
							</a>
						</>
					)}
				</Typography>
			</div>
		</footer>
	)
}

export default AppFooter
