import { LogrocketFuzzySearch } from './utils'

export const blacklistedFields = ['legalId', 'ssn', 'socialSecurityNumber', 'password', 'Password']

const sanitizer = LogrocketFuzzySearch.setup(blacklistedFields)

const requestSanitizer = sanitizer.requestSanitizer
const responseSanitizer = sanitizer.responseSanitizer

export { requestSanitizer, responseSanitizer }
