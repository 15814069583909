import {
	LOADING_USER,
	LOAD_USER_ERROR,
	SESSION_TERMINATED,
	SILENT_RENEW_ERROR,
	USER_EXPIRED,
	USER_EXPIRING,
	USER_FOUND,
	USER_SIGNED_OUT,
} from 'redux-oidc-js'

export const ignoredOidcActions = [
	LOADING_USER,
	LOAD_USER_ERROR,
	SESSION_TERMINATED,
	SILENT_RENEW_ERROR,
	USER_EXPIRED,
	USER_EXPIRING,
	USER_FOUND,
	USER_SIGNED_OUT,
]
