import { Draft } from '@reduxjs/toolkit'
import { CartItem, CartItemMappingKeys } from 'store/cart/types/cartItemTypes'
import { upsertWithoutImmer } from 'store/utils'
import { isEqualViaPropertyChecks } from 'utils/equality'
import { _getType } from './slice'

type WritableDraft<T> = {
	-readonly [K in keyof T]: Draft<T[K]>
}

/**
 * Utility to make CartItem property updates painless and centralized under one roof
 * @param properties object with defined properties to update
 * @param state
 * @param stateProperty property on the state to update
 */
export function updateCartItemProperties<S>(
	state: WritableDraft<S>,
	stateProperty: keyof S,
	properties: Partial<CartItem>,
) {
	Object.keys(properties).forEach((property) => {
		const key = property as keyof CartItem
		const propertyValueType = _getType(properties[key])

		// when values don't exist, apply the payload value
		if (!state[stateProperty][key]) {
			state[stateProperty][property] = properties[property]

			return
		}

		// apply updates to nested existing values
		switch (propertyValueType) {
			case 'object': {
				state[stateProperty][key] = { ...state[stateProperty][key], ...properties[property] }

				return
			}
			case 'array': {
				const data = properties[property] as any[]

				data.forEach((item) => {
					// get all ways to lookup an array item. 2D array supports composite keys
					const lookupMethods: string[][] = CartItemMappingKeys[key]

					state[stateProperty][key] = upsertWithoutImmer(
						state[stateProperty][key] as unknown as any[],
						item,
						isEqualViaPropertyChecks(lookupMethods),
					)
				})

				return
			}
			default:
				break
		}

		// assign primitive values
		state[stateProperty][property] = properties[property]
	})
}
