// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { SimplinkAddressForm } from 'api/benefit-elections'

// https://enrollments.int.corestream.com/swagger/index.html
export const queryKeys = {
	all: () => ['enrollments'] as const,

	benefitElections: {
		all: () => [...queryKeys.all(), 'benefitElections'] as const,

		recent: () => [...queryKeys.benefitElections.all(), 'recent'] as const,
		userNewHireInfo: () => [...queryKeys.benefitElections.all(), 'userNewHireInfo'] as const,
	},

	benefitPlans: {
		all: () => [...queryKeys.all(), 'benefitPlans'] as const,
		benefitPlanQuestions: (benefitPlanId: number) =>
			[...queryKeys.benefitPlans.all(), 'benefitPlanQuestions', benefitPlanId] as const,
		effectiveDates: (orgId: string) => [...queryKeys.benefitPlans.all(), 'effectiveDates', orgId] as const,
		questions: (benefitPlanId: number) => [...queryKeys.benefitPlans.all(), 'questions', benefitPlanId] as const,
	},

	dependents: {
		all: () => [...queryKeys.all(), 'dependents'] as const,
		dependents: () => [...queryKeys.dependents.all(), 'dependents'] as const,
		petEnrollments: () => [...queryKeys.dependents.all(), 'petEnrollments'] as const,
		petRates: () => [...queryKeys.dependents.all(), 'petRates'] as const,
	},

	questions: {
		all: () => [...queryKeys.all(), 'questions'] as const,
		getAnsweredQuestionsForProgramEnrollment: (programEnrollmentId: string) =>
			[...queryKeys.questions.all(), 'getAnsweredQuestionsForProgramEnrollment', programEnrollmentId] as const,
	},

	trustmarkSimplink: {
		all: () => [...queryKeys.all(), 'trustmarkSimplink'] as const,
		getEnrollment: () => [...queryKeys.trustmarkSimplink.all(), 'getEnrollment'] as const,
		getToken: (data: SimplinkAddressForm & { isCustomFunctionality: boolean }) =>
			[...queryKeys.trustmarkSimplink.all(), 'getToken', data] as const,
		getWorkerAddress: () => [...queryKeys.trustmarkSimplink.all(), 'getWorkerAddress'] as const,
	},

	workers: {
		all: () => [...queryKeys.all(), 'workers'] as const,
		deductions: (payload: object, version: 'v1' | 'v2') =>
			[...queryKeys.workers.all(), 'deductions', payload, version] as const,
		recentEnrollments: () => [...queryKeys.workers.all(), 'recentEnrollments'] as const,
		userEnrollments: () => [...queryKeys.workers.all(), 'userEnrollments'] as const,
	},
}

export const mutationKeys = {
	all: () => ['enrollments'] as const,
	/**
	 * A collection of mutations that coordinate submitting enrollments
	 *
	 * When these are loading display display a loading indicator via useIsFetching
	 *
	 * eg:
	 * ```ts
	 * const isSubmitting = useIsFetching({
	 * 	queryKey: mutationKeys.submit.all(),
	 * 	active: true,
	 * })
	 * ```
	 */
	submit: {
		all: () => [...mutationKeys.all(), 'submit'] as const,
		saveEnrollment: () => [...mutationKeys.submit.all(), 'saveEnrollment'] as const,
		saveEnrollmentPersons: () => [...mutationKeys.submit.all(), 'saveEnrollmentPersons'] as const,
		saveEnrollmentPet: () => [...mutationKeys.submit.all(), 'saveEnrollmentPet'] as const,
		startEnrollment: () => [...mutationKeys.submit.all(), 'startEnrollment'] as const,
		submitUserEnrollment: () => [...mutationKeys.submit.all(), 'submitUserEnrollment'] as const,
	},
}
