import { Button, Typography } from '@mui/material'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { routesMap } from 'routes/routes-map'
import { ThemePage } from '../../types'
import styles from './category-section.module.css'

type CategorySectionProps = ThemePage['category']

export function CategorySection({ cta, imageLink, subtitle, title }: CategorySectionProps) {
	const track = useTrackDetailedPageEvent()

	const onAllDealsClick = () => {
		track({
			actionType: 'click',
			elementDetails: 'category',
			elementName: 'see all deals',
			elementType: 'button',
		})
	}

	return (
		<div className={styles.category}>
			<div className={styles['category-content']}>
				<div className={styles['category-content-wrapper']}>
					<Typography variant='h2' className={styles['category-title']}>
						{title}
					</Typography>
					<Typography variant='h3' className={styles['category-subtitle']}>
						{subtitle}
					</Typography>
					{cta.visible && (
						<Button
							className={styles['category-cta']}
							variant='outlined'
							color='inherit'
							href={routesMap.search + '?category=' + cta.category}
							onClick={onAllDealsClick}
						>
							{cta.title}
						</Button>
					)}
				</div>
				<img className={styles['category-image']} src={imageLink} alt='img' />
			</div>
		</div>
	)
}
