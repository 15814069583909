import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { CartItem } from 'store/cart/types/cartItemTypes'
import { UserEnrollmentRes } from 'types/user-enrollment'

export const getFlatSortedOverview = (overviewData: UserEnrollmentRes[]) => {
	const sortElectionsBySubmittedOn = (a: UserEnrollmentRes, b: UserEnrollmentRes) => {
		const aTime = new Date(a.submittedOn).getTime()
		const bTime = new Date(b.submittedOn).getTime()

		return aTime - bTime
	}

	if (!overviewData || overviewData.length === 0) {
		return []
	}

	const currentElection = overviewData[0]
	const flatElectionOverview = [currentElection, ...(currentElection.history ?? [])]
	flatElectionOverview?.sort(sortElectionsBySubmittedOn)

	return flatElectionOverview
}

/**
 * The purpose of this function is to get in a list of latest enrollments, sort them (if necessary), and
 *  return the latest one
 * - IMPORTANT NOTE: The subAmount param was needed to grab the latest enrollment on the receipt page, but we wanted to
 *  ignore the one that was just enrolled, as it is added to the userEnrollmentOverview by the time
 *  you get to the receipt page (@see {@link subAmount})
 * @param overviewData The data to be sorted (if necessary)
 * @param und3484 The FF that determines if the data needs sorting first
 * @param subAmount How many latest enrollments to ignore
 * @returns The latest enrollment as UserEnrollmentRes
 */
export const getLatestEnrollment = (
	overviewData: UserEnrollmentRes[],
	und3484: boolean,
	subAmount?: number,
): UserEnrollmentRes => {
	const sortedEnrollmentsForPlan = und3484 ? getFlatSortedOverview(overviewData) : overviewData
	// The reason for this is if you wanted to ignore the latest x amount of enrollments (not needed, default is 1)
	// This was needed to grab the latest enrollment on the receipt page (to check for cancellation), but ignore the one
	// that is just had enrolled in as it is added to the userEnrollmentOverview by the time you get to the receipt page
	const sub = subAmount && subAmount <= sortedEnrollmentsForPlan?.length ? subAmount + 1 : 1

	return sortedEnrollmentsForPlan?.[sortedEnrollmentsForPlan?.length - sub]
}

// This function checks if there is a re-enrollment after a cancellation in the cart
// This can happen if a user cancels a plan, then edits and selects the same plan again
export const hasReEnrollmentAfterCancellation = (
	userEnrollmentOverview: Record<number, UserEnrollmentRes[]>,
	cartItems: CartItem[],
	und3484: boolean,
) => {
	return cartItems.some((item) => {
		const overviewData = userEnrollmentOverview[item.benefitPlanId]
		const enrollments = und3484 ? getFlatSortedOverview(overviewData) : overviewData
		const latestEnrollment = enrollments?.[enrollments.length - 1]

		return latestEnrollment?.statusCode === EnrollmentStatus.CANCELED && item?.statusCode === EnrollmentStatus.NEW
	})
}
