import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PropsWithChildren, ReactNode } from 'react'
import './modal.scss'

interface GenericModalProps {
	actions?: ReactNode
	disableBackdropClick?: boolean
	disableEscapeKeyDown?: boolean
	hideTitle?: boolean
	onClose: () => void
	showModal: boolean
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const GenericModal = (props: PropsWithChildren<GenericModalProps>): JSX.Element => {
	const handleClose = (_: any, reason: string) => {
		if (props.disableBackdropClick && reason === 'backdropClick') {
			return
		}

		props.onClose()
	}

	return (
		<Dialog
			className='modal-container'
			disableEscapeKeyDown={props.disableEscapeKeyDown}
			onClose={handleClose}
			open={props.showModal}
			maxWidth={props.maxWidth}
		>
			{props.hideTitle ? null : (
				<DialogTitle className='modal-title'>
					<IconButton aria-label='close' className='modal-button-close' onClick={props.onClose} size='large'>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent className='modal-content'>{props.children}</DialogContent>
			{props.actions ? <DialogActions className='modal-actions'>{props.actions}</DialogActions> : null}
		</Dialog>
	)
}

GenericModal.defaultProps = {
	maxWidth: 'md',
}

export default GenericModal
