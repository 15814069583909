import { useHasVisitedProductType } from 'hooks/benefit-plans/product-type/use-has-visited-product-type'
import { useIsSingleProductOE } from 'hooks/oe'
import useRecentOECompletion from 'hooks/use-recent-oe-completion'
import { useEnrollmentData } from 'hooks/user-data'
import { match } from 'ts-pattern'

export enum EnrollmentJourneyState {
	Default, // Default
	PreviouslyEnrolled, // Previously Enrolled
	SingleProductNotCompleted, // Single Product and Wizard NOT Completed
	SingleProductCompleted, // Single Product and Wizard Completed
	WizardCompletedNothing, // Wizard Complete without any Enrollments/Visits
	WizardCompletedEnrollments, // Wizard Complete with Only Enrollments
	WizardCompletedVisits, // Wizard Complete with Only Visits to Non-Natives
	WizardCompletedAll, // Wizard Complete with Enrollments and Visits to Non-Natives
}

export const WizardCompleteJourneyStates = [
	EnrollmentJourneyState.WizardCompletedNothing,
	EnrollmentJourneyState.WizardCompletedEnrollments,
	EnrollmentJourneyState.WizardCompletedVisits,
	EnrollmentJourneyState.WizardCompletedAll,
]

export const useEnrollmentJourneyState = () => {
	const { enrollmentPlans } = useEnrollmentData()
	const hasCompletedOE = useRecentOECompletion()
	const hasEnrollments = enrollmentPlans.some((plan) => plan.isEnrolled)
	const hasNativeEnrollments = enrollmentPlans.some((plan) => plan.isEnrolled && plan.isNative)
	const hasVisits = useHasVisitedProductType(enrollmentPlans)
	const { result: isSingleProduct } = useIsSingleProductOE()

	return match({
		completedOe: hasCompletedOE,
		hasEnrollments: hasEnrollments,
		hasNativeEnrollments: hasNativeEnrollments,
		hasVisits: hasVisits,
		isSingleProduct: isSingleProduct,
	})
		.with({ completedOe: true, isSingleProduct: true }, () => {
			return EnrollmentJourneyState.SingleProductCompleted
		})
		.with({ isSingleProduct: true }, () => {
			return EnrollmentJourneyState.SingleProductNotCompleted
		})
		.with({ completedOe: true, hasNativeEnrollments: true, hasVisits: true }, () => {
			return EnrollmentJourneyState.WizardCompletedAll
		})
		.with({ completedOe: true, hasNativeEnrollments: true }, () => {
			return EnrollmentJourneyState.WizardCompletedEnrollments
		})
		.with({ completedOe: true, hasVisits: true }, () => {
			return EnrollmentJourneyState.WizardCompletedVisits
		})
		.with({ hasEnrollments: true }, () => {
			return EnrollmentJourneyState.PreviouslyEnrolled
		})
		.with({ completedOe: true }, () => {
			return EnrollmentJourneyState.WizardCompletedNothing
		})
		.otherwise(() => {
			return EnrollmentJourneyState.Default
		})
}

export const isWizardJourneyCompleted = (scenario: EnrollmentJourneyState) => {
	return WizardCompleteJourneyStates.includes(scenario)
}
