import { usePrefetchClickedOffers } from 'api/tenants/queries'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectFacetSearchStatus, selectOfferFetchStatus } from 'store/search/selectors'
import { getFacets, getOffers, getRecommendedOffers } from 'store/search/slice'
import {
	isLoadingUserSelector,
	isUserProfileLoadedSelector,
	userRecommendedOffersLoadingSelector,
	userSelector,
} from 'store/user/selectors'
import { fetchRecommendedUserOffers } from 'store/user/slice'
import { IDLE, SUCCESS } from 'types/status-types'
import { isSiteLoadingSelector, siteInfoSelector } from '../store/site/selectors'
import { SiteInfoResponse } from '../types/tenant-site-types'
import { StateMachineStatus } from './use-async-fetch'
import { useCallbackDispatch } from './use-callback-dispatch'

export const useSearchInit = (isLoggedIn: boolean) => {
	const { wrp1894, wrp2642 } = useFlags()
	const dispatch = useCallbackDispatch()
	const user = useSelector(userSelector)
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const siteInfoLoading = useSelector(isSiteLoadingSelector)
	const shouldShowDiscounts: boolean = Boolean(properties.hasDiscounts)
	const facetSearchStatus: StateMachineStatus = useSelector(selectFacetSearchStatus)
	const offerFetchStatus: StateMachineStatus = useSelector(selectOfferFetchStatus)
	const isUserProfileLoaded = useSelector(isUserProfileLoadedSelector)
	const isUserLoading = useSelector(isLoadingUserSelector)
	const userRecommendedOffersLoading = useSelector(userRecommendedOffersLoadingSelector)
	const prefetchClickedOffers = usePrefetchClickedOffers()

	useEffect(() => {
		if (offerFetchStatus === IDLE && !siteInfoLoading && (shouldShowDiscounts || wrp2642)) {
			dispatch(getOffers(wrp2642))
		}

		if (!isLoggedIn || !isUserProfileLoaded || isUserLoading) {
			return
		}

		if (facetSearchStatus === IDLE && shouldShowDiscounts) {
			dispatch(getFacets())
		}

		prefetchClickedOffers()
	}, [
		dispatch,
		facetSearchStatus,
		isLoggedIn,
		isUserLoading,
		isUserProfileLoaded,
		offerFetchStatus,
		prefetchClickedOffers,
		shouldShowDiscounts,
		siteInfoLoading,
		wrp2642,
	])

	useEffect(() => {
		const getRecommeded = async (): Promise<void> => {
			await dispatch(fetchRecommendedUserOffers())
		}
		if (isLoggedIn && user?.id_token && userRecommendedOffersLoading === IDLE && wrp1894.toLowerCase() !== 'false') {
			getRecommeded()
		}
	}, [dispatch, isLoggedIn, user, userRecommendedOffersLoading, wrp1894])

	useEffect((): void => {
		if (userRecommendedOffersLoading === SUCCESS && wrp1894.toLowerCase() !== 'false') {
			dispatch(getRecommendedOffers())
		}
	}, [dispatch, userRecommendedOffersLoading, wrp1894])

	return
}
