import { isBefore, isToday } from 'date-fns'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'
import { getSingleId } from 'store/utils'
import { match } from 'ts-pattern'
import { PET_PLAN_ID } from 'types/pet'
import { RecentElection } from 'types/recent-election'
import { SUCCESS } from 'types/status-types'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { EnrollmentsState, UserEnrollment } from './types'

export const enrollments = (state: RootState): EnrollmentsState => state.enrollments

export const hasCompletedOEOverviewSelector = createSelector(
	({ enrollments: { userEnrollmentOverview, userEnrollmentOverviewStatus } }: RootState) => ({
		userEnrollmentOverview,
		userEnrollmentOverviewStatus,
	}),
	({
		benefitPlans: {
			availablePlans,
			effectiveDates,
			isOE,
			oeWindow,
			requestStates: { oeWindowsFetchStatus, benefitPlansFetchStatus },
		},
	}: RootState) => ({
		availablePlans,
		benefitPlansFetchStatus,
		effectiveDates,
		isOE,
		oeWindow,
		oeWindowsFetchStatus,
	}),
	(enrollments, benefitPlans) => {
		const { availablePlans, benefitPlansFetchStatus, effectiveDates, isOE, oeWindow, oeWindowsFetchStatus } =
			benefitPlans
		const { userEnrollmentOverview, userEnrollmentOverviewStatus } = enrollments
		if (
			benefitPlansFetchStatus !== SUCCESS ||
			userEnrollmentOverviewStatus !== SUCCESS ||
			oeWindowsFetchStatus !== SUCCESS ||
			!isOE ||
			!oeWindow
		) {
			return false
		}

		const oeWindowPlans = availablePlans.filter((plan) => oeWindow?.oeWindowPlans[plan.benefitPlanId] != null)
		const matchedPlans = oeWindowPlans.filter((plan) => userEnrollmentOverview[plan.benefitPlanId] !== null)

		return matchedPlans.some((plan) => {
			const planArray = userEnrollmentOverview[plan.benefitPlanId]
			const latestPlanEffectiveDate = planArray ? planArray[planArray.length - 1].planEffectiveDate : null

			return oeWindow.oeWindowPlans[plan.benefitPlanId].useElectionsEffectiveDate
				? latestPlanEffectiveDate === oeWindow.electionsEffectiveDate
				: effectiveDates[plan.benefitPlanId] === latestPlanEffectiveDate
		})
	},
)

export const recentUserEnrollmentsStatusSelector = createSelector(
	enrollments,
	(enrollments: EnrollmentsState): StateMachineStatus => enrollments.recentUserEnrollmentStatus,
)

export const userEnrollmentOverviewStatusSelector = createSelector(
	enrollments,
	(enrollments: EnrollmentsState): StateMachineStatus => enrollments.userEnrollmentOverviewStatus,
)
// TODO: remove UserEnrollment type with removal of und3484
export const getRecentUserEnrollmentFromState = (enrollments: EnrollmentsState): UserEnrollment | RecentElection => {
	return enrollments.recentUserEnrollment
}
export const getUserEnrollmentOverviewFromState = (
	enrollments: EnrollmentsState,
): Record<number, UserEnrollmentRes[]> => {
	return enrollments.userEnrollmentOverview
}

export const allLatestUserEnrollmentsSelector = createSelector(
	enrollments,
	(state): Record<number, UserEnrollmentRes> => state.allLatestUserEnrollments,
)

export const latestUserEnrollmentByIdSelector = createSelector(
	enrollments,
	getSingleId,
	(state, id) => state.userEnrollmentOverview[id]?.[state.userEnrollmentOverview[id].length - 1],
)

export const overviewByIdSelector = createSelector(
	enrollments,
	getSingleId,
	(state, id) => state.userEnrollmentOverview[id] ?? [],
)

export const overviewByIdsSelector = createSelector(
	enrollments,
	(state: RootState, benefitPlanIds: number[]) => ({
		benefitPlanIds,
	}),
	(state, { benefitPlanIds }) => {
		return Object.keys(state.userEnrollmentOverview)
			.filter((key) => benefitPlanIds.includes(Number(key)))
			.reduce((overview, key) => {
				overview[key] = state.userEnrollmentOverview[key]

				return overview
			}, {})
	},
)

export const currentElectionsFromOverviewSelector = createSelector(enrollments, (state): UserEnrollmentRes[] => {
	const getIsEnrollmentCurrentlyEffective = (enr: UserEnrollmentRes) =>
		match({
			enrollmentStatus: enr.statusCode,
			hasCurrentEffectiveDate: getHasCurrentEffectiveDate(enr.planEffectiveDate),
		})
			.with(
				{ enrollmentStatus: EnrollmentStatus.ENROLLED, hasCurrentEffectiveDate: true },
				{ enrollmentStatus: EnrollmentStatus.CANCELED },
				() => true,
			)
			.otherwise(() => false)

	const getHasCurrentEffectiveDate = (dateString: string) => {
		const effectiveDate = new Date(dateString)
		const nowDate = new Date()

		return isBefore(effectiveDate, nowDate) || isToday(effectiveDate)
	}

	const getCurrentElectionFromHistory = (history: UserEnrollmentRes[]) => {
		const targetIndex = history.findIndex((enr) => getIsEnrollmentCurrentlyEffective(enr))

		return targetIndex > -1 ? history[targetIndex] : null
	}

	/**
	 * Finds the currently effective enrollment record based on
	 * status code and effective date. Filters out plans with a
	 * future effectivedate. If the plan is cancelled with a
	 * future effective date, we need to return the previous record
	 * @param enrs All enrollment records associated with current plan id
	 * @returns a single enrollment record or null
	 */
	const getCurrentElection = (enr: UserEnrollmentRes): UserEnrollmentRes | null =>
		match({
			enrollmentStatus: enr.statusCode,
			hasCurrentEffectiveDate: getHasCurrentEffectiveDate(enr.planEffectiveDate),
		})
			.with({ enrollmentStatus: EnrollmentStatus.ENROLLED, hasCurrentEffectiveDate: true }, () => enr)

			.with({ enrollmentStatus: EnrollmentStatus.CANCELED }, () => {
				const isCancelledInFuture = new Date(enr.planEffectiveDate) > new Date()

				return isCancelledInFuture ? getCurrentElectionFromHistory((enr.history as UserEnrollmentRes[]) ?? []) : enr
			})
			.otherwise(() => null)

	// reduce all plans for a specific plan id to single record
	return Object.values(state.userEnrollmentOverview)
		.flat()
		.flatMap((enrollment) => {
			/**
			 * We need to allow pet through this filter.
			 * This is because we need to show pets that are already enrolled
			 * on nationwide's side, which we have no control over. So a pet can
			 * be awaiting a push to nationwide and be cancelled or a user with
			 * pets already enrolled can decline coverage. But we still need to
			 * display the already enrolled pets. We will filter the individual
			 * pets based on data returned from the pets api in petEnrolledFilter.
			 */
			const currentElection = enrollment.petData ? enrollment : getCurrentElection(enrollment)

			return currentElection ?? []
		})
})

/**
 * @deprecated replaced with currentElectionsFromOverviewSelector
 *  under flag und3484(using benefit elections version of overview)
 */
//	Overview data contains declined/cancelled enrollments which need to be filtered out
export const currentEnrollmentsFromOverviewSelector = createSelector(enrollments, (state): UserEnrollmentRes[] => {
	const getIsCancelledInFuture = (e: UserEnrollmentRes) => {
		if (!e) return false

		return e.statusCode === EnrollmentStatus.CANCELED && new Date(e.planEffectiveDate) > new Date()
	}

	const getHasCurrentEffectiveDate = (dateString: string) => {
		const effectiveDate = new Date(dateString)
		const nowDate = new Date()

		return isBefore(effectiveDate, nowDate) || isToday(effectiveDate)
	}

	/**
	 * Finds the currently effective enrollment record based on
	 * status code and effective date. Filters out plans with a
	 * future effectivedate. If the plan is cancelled with a
	 * future effective date, we need to return the previous record
	 * @param enrs All enrollment records associated with current plan id
	 * @returns a single enrollment record or null
	 */
	const getCurrentEnrollment = (enrs: UserEnrollmentRes[]): UserEnrollmentRes | null => {
		// find index of currently effective plan
		const effectiveEnrollmentIndex = enrs.findIndex((e) => {
			return match({
				enrollmentStatus: e.statusCode,
				hasCurrentEffectiveDate: getHasCurrentEffectiveDate(e.planEffectiveDate),
			})
				.with(
					{ enrollmentStatus: EnrollmentStatus.ENROLLED, hasCurrentEffectiveDate: true },
					{ enrollmentStatus: EnrollmentStatus.DECLINED },
					{ enrollmentStatus: EnrollmentStatus.CANCELED, hasCurrentEffectiveDate: false },
					() => true,
				)
				.otherwise(() => false)
		})

		const effectiveEnrollment = enrs[effectiveEnrollmentIndex]
		const effectiveEnrollmentStatus = effectiveEnrollment?.statusCode ?? null

		return (
			match({
				effectiveEnrollmentIndex,
				effectiveEnrollmentStatus,
				isCancelledInFuture: getIsCancelledInFuture(effectiveEnrollment),
			})
				//An effective enrollment wasnt found or the latest record is declined so ignore this plan
				.with(
					{
						effectiveEnrollmentIndex: -1,
					},
					{
						effectiveEnrollmentStatus: EnrollmentStatus.DECLINED,
					},
					() => null,
				)
				/**
				 * A plan may be cancelled but the user can still be enrolled
				 * in a previous version of the plan that is effective until
				 * the cancelled plan's effective date.
				 */
				.with(
					{
						isCancelledInFuture: true,
					},
					() => {
						// find latest enrolled record with a current effective date
						const latestEnrolled = enrs.find(
							(e) => e.statusCode === EnrollmentStatus.ENROLLED && getHasCurrentEffectiveDate(e.planEffectiveDate),
						)

						return latestEnrolled ?? null
					},
				)
				.otherwise(() => effectiveEnrollment)
		)
	}

	// reduce all plans for a specific plan id to single record
	return Object.values(state.userEnrollmentOverview).reduce((acc, entry) => {
		const lastEnrollment = entry[entry.length - 1]
		/**
		 * We need to allow pet through this filter.
		 * This is because we need to show pets that are already enrolled
		 * on nationwide's side, which we have no control over. So a pet can
		 * be awaiting a push to nationwide and be cancelled or a user with
		 * pets already enrolled can decline coverage. But we still need to
		 * display the already enrolled pets. We will filter the individual
		 * pets based on data returned from the pets api in petEnrolledFilter.
		 */
		const isPet = lastEnrollment.programId === PET_PLAN_ID
		/**
		 * We need to iterate through the enrollments array in reverse order as
		 * overview data is sorted with the latest enrollment as last. But we need to
		 * iterate through the array looking for the most recent valid enrollment.
		 * Using array.findLastIndex() would be ideal but is not supported by typescript
		 * as of 12/20/22
		 */
		const currentEnrollment = isPet ? lastEnrollment : getCurrentEnrollment([...entry].reverse())

		if (currentEnrollment) acc.push(currentEnrollment)

		return acc
	}, [])
})

export const recentUserEnrollmentsSelector = createSelector(enrollments, getRecentUserEnrollmentFromState)

export const userEnrollmentOverviewSelector = createSelector(enrollments, getUserEnrollmentOverviewFromState)
